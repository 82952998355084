import { Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";

interface SubmitButtonProps {
  isLoading: boolean;
  children: any;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export default function SubmitButton({
  isLoading,
  children,
  disabled,
  onClick,
  className,
}: SubmitButtonProps) {
  return (
    <Button
      type={onClick ? "button" : "submit"}
      disabled={isLoading || disabled}
      onClick={onClick}
      className={className}
    >
      {isLoading && <Loader2 className="mr-2 h-8 w-8 animate-spin" />}
      {children}
    </Button>
  );
}
