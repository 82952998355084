import Hero from "@/components/landing/hero";
import Benefits from "@/components/landing/benefits";
import CTA from "@/components/landing/cta";
import Footer from "@/components/landing/footer";
import SlackJoin from "@/components/SlackJoin";

// How it works data
const howItWorksData = [
  {
    id: 1,
    title: "Data Processing",
    description:
      "We collect and refine biological datasets from diverse sources, applying quality control protocols and AI-assisted cleaning. This ensures reliable, structured, and analysis-ready data for researchers.",
    icon: "/icons/lab-flask.svg",
    comingSoon: false,
  },
  {
    id: 2,
    title: "Data Sharing Index",
    description:
      "Our platform evaluates datasets on openness, usability, and reproducibility, assigning a Data Sharing Score. This index empowers researchers to choose datasets aligned with open science principles.",
    icon: "/icons/chart-shield.svg",
    comingSoon: false,
  },
  {
    id: 3,
    title: "Curation & Metadata",
    description:
      "Expert-led curation enhances the clarity and context of each dataset. Comprehensive metadata tags ensure findability, interoperability, and adherence to FAIR principles.",
    icon: "/icons/folders.svg",
    comingSoon: false,
  },
  {
    id: 4,
    title: "Credits & Provenance",
    description:
      "We ensure proper attribution to original contributors and sources. Each dataset includes clear citation guidelines and links back to its origin, fostering ethical reuse and recognition.",
    icon: "/icons/graduation-cap.svg",
    comingSoon: false,
  },
];

const HowItWorks = () => {
  return (
    <div className="app">

      {/* Hero section with modified content */}
      <Hero
        title="How Alchemy Bio Works"
        subtitle="Understand how Alchemy Bio transforms biological data into actionable insights. From processing to sharing — here's how we make it happen."
        showSearch={false}
      />

      {/* How it works section using Benefits component */}
      <Benefits
        title="How It Works"
        subtitle="Our process for transforming biological data into actionable insights"
        data={howItWorksData}
      />

      {/* Final CTA section */}
      <CTA
        title="Join Our Slack Community"
        description="Connect with researchers, share insights, and be part of the open science movement."
        buttonText={<SlackJoin text="Join our Slack community" />}
        buttonLink=""
      />

      <Footer />
    </div>
  );
};

export default HowItWorks;
