import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth } from "@clerk/clerk-react";

// Store the base URL to avoid repeating import.meta.env
const API_BASE_URL = import.meta.env.VITE_BACKEND_URL;
axios.defaults.baseURL = `${API_BASE_URL}/api`;

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const apiClient = axios;

export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
  OPTIONS: "OPTIONS",
};

// Convert to a custom hook that can be called inside components
export function useAction(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { getToken } = useAuth();
  
  const mutation = useMutation({
    mutationFn: async (data) => {
      // Get the session token from Clerk
      const token = await getToken();
      
      // Create a config object with the Authorization header
      const config = {
        headers: token ? { Authorization: `Bearer ${token}` } : {}
      };
      
      // Make the API request with the token
      const response = await apiClient[props.method?.toLowerCase() || "post"](
        props.url, 
        data,
        config
      );
      return response.data;
    },
    onSuccess: (data) => {
      if (props.success) toast.success(props.success);
      if (props.callback) props.callback(data);
      if (props.redirectTo) navigate(props.redirectTo);
      if (props.refreshPage) navigate(location.pathname, { replace: true });
      return data;
    },
    onError: (error) => {
      toast.error(error.response.data.detail || props.error);
    },
  });

  return {
    action: (data) => mutation.mutate(data),
    isLoading: mutation.isPending,
    error: mutation.error,
    data: mutation.data,
  };
}

// Convert useStream to a proper React hook that returns a function
export function useStream() {
  const { getToken } = useAuth();
  
  const streamData = async (props) => {
    const { url, method = "POST", data, callBack, onFinish } = props;
    
    try {
      // Get the session token from Clerk
      const token = await getToken();
      
      // Create headers with Authorization if token exists
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };
      
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      
      const response = await fetch(`${API_BASE_URL}/api${url}`, {
        method,
        headers,
        body: JSON.stringify(data),
      });
      
      if (!response.ok) {
        toast.error(response.statusText);
        if (response.status === 401) window.location.pathname = "/login";
        throw new Error(response);
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      const processStream = async () => {
        while (!done) {
          const { value, done: doneReading } = await reader.read(); // Read a chunk
          done = doneReading;
          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            callBack(chunk);
          }
        }
        if (onFinish) onFinish(done);
      };
      processStream().catch((error) => {
        if (onFinish) onFinish(done);
        console.error("Error while processing the stream:", error);
      });
    } catch (error) {
      console.error("Error with fetch request:", error);
    }
  };
  
  return streamData;
}
