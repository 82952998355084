import { useParams } from "react-router-dom";
import GeneralForm from "@/components/projects/experiment/general-form";
import { StatusEnum } from "@/types/project-model";
import { ExperimentDetails, GeneralInfo } from "@/types/study-model";
import { useForm } from "react-hook-form";
import { useCreateExperiment } from "@/api/project/projects";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form";
import DetailsForm from "@/components/projects/experiment/details-form";
import SubmitButton from "@/components/shared/SubmitButton";

const initialGeneralInfo: GeneralInfo = {
  status: StatusEnum.NotStarted,
  description: "",
  title: "",
  is_public: false,
  collaborators: [],
};
const initialDetails: ExperimentDetails = {
  procedures: [],
  hypothesis: "",
  labels: [],
  categories: [],
};

const ExperimentCreate = () => {
  const { projectId, studyId } = useParams();

  const createExperiment = useCreateExperiment();

  const formSchema = z.object({
    title: z.string().min(1),
    description: z.string().min(1),
    status: z.nativeEnum(StatusEnum),
    is_public: z.boolean(),
    hypothesis: z.string().min(1),
    collaborators: z.number().array(),
    labels: z.number().array(),
    categories: z.number().array(),
    procedures: z
      .object({
        content: z.string(),
        position: z.number(),
      })
      .array(),
  });

  const form = useForm<GeneralInfo & ExperimentDetails>({
    resolver: zodResolver(formSchema),
    defaultValues: Object.assign(initialDetails, initialGeneralInfo),
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    createExperiment.mutate({
      datasets: [],
      ...data,
      study_id: Number(studyId),
    });
  };

  return (
    <div className={"container mx-auto p-4"}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className={"flex justify-between mb-12"}>
            <h1 className="text-3xl font-bold">New Experiment</h1>
            <SubmitButton
              type={"submit"}
              isLoading={createExperiment.isPending}
            >
              Save
            </SubmitButton>
          </div>

          <GeneralForm prefix={"Experiment"} form={form} />

          <div className={"mt-12"}>
            <DetailsForm form={form} />
          </div>
        </form>
      </Form>
    </div>
  );
};

export default ExperimentCreate;
