import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Study} from "@/types/study-model";
import {studiesQueryKeys} from "@/api/studies/studiesQueryKeys";
import {toast} from "react-toastify";
import {useNavigate} from 'react-router-dom';
import {apiClient} from "@/api/apiClient";


const fetchStudyById = async (id: number) => {
    const response = await apiClient.get(`/studies/${id}`);
    return response.data;
};
export function useStudyById(id: number) {
    return useQuery<Study>({
        queryKey: studiesQueryKeys.detail(id),
        queryFn: () => fetchStudyById(id),
        enabled: !!id,
    });
}
const updateStudyFn = async (data) => {
    const response = await apiClient.put(`/studies/${data.id}`, data)
    return response.data
}

export function useUpdateStudy() {
    const navigate = useNavigate();
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: updateStudyFn,
        onSuccess: (data) => {
            if (data === undefined) return

            toast.success('study successfully updated')

            void queryClient.invalidateQueries({queryKey: studiesQueryKeys.detail(data.id)})
            navigate(`/projects/${data.project_id}`);
        }

    })
}
