import { FilterX, X } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { MultiSelect } from "@/components/shared/MultiSelect";

export default function SearchFilter({
  dataType,
  species,
  institutions,
  filter,
  setFilter,
  resetFilter,
}) {
  function setFilterByProp(propName, value) {
    setFilter({
      ...filter,
      [propName]: value,
    });
  }
  function showReset() {
    let show = false;
    Object.keys(filter).forEach(function (key) {
      if (Array.isArray(filter[key]) && filter[key].length) show = true;
      if (!Array.isArray(filter[key]) && filter[key]) show = true;
    });
    return show;
  }

  return (
    <div className="flex gap-2 my-3 flex-wrap justify-items-start w-full">
      <div className="flex items-center border rounded-md pr-2 h-7">
        <Select
          onValueChange={(e) => setFilterByProp("access", e)}
          value={filter.access}
        >
          <SelectTrigger className="px-2 h-0 w-auto border-0 focus:outline-none focus:ring-0">
            Access
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="public">Public</SelectItem>
            <SelectItem value="quality-controlled">Private</SelectItem>
          </SelectContent>
        </Select>
        {filter.access && (
          <div className="flex items-center px-2 rounded-md border border-primary text-primary bg-blue-100 text-xs ml-2">
            {filter.access}
            <X
              size="16px"
              className="cursor-pointer hover:opacity-70"
              onClick={() => setFilterByProp("access", null)}
            />
          </div>
        )}
      </div>
      {/* <MultiSelect
        title="Author"
        value={filter.author}
        onChange={(e) => setFilterByProp("author", e)}
      />
      <MultiSelect
        title="Topic"
        value={filter.topic}
        onChange={(e) => setFilterByProp("topic", e)}
      />
      <MultiSelect
        title="Keyword"
        value={filter.keyword}
        onChange={(e) => setFilterByProp("keyword", e)}
      />
      <MultiSelect
        title="Type"
        value={filter.type}
        onChange={(e) => setFilterByProp("type", e)}
      />
      <MultiSelect
        title="Data type"
        items={dataType.map((item) => item.name)}
        value={filter.dataType}
        onChange={(e) => setFilterByProp("dataType", e)}
      />
      <MultiSelect
        title="Species"
        items={species.map((item) => item.name)}
        value={filter.specie}
        onChange={(e) => setFilterByProp("specie", e)}
      /> */}
      {showReset() && (
        <div
          className="text-primary flex items-center cursor-pointer hover:opacity-70 justify-self-end"
          onClick={resetFilter}
        >
          <FilterX size="16px" className="mr-1" />
          Reset all filters
        </div>
      )}
    </div>
  );
}
