import { useState } from "react";

const copilotQueries = [
  {
    id: 1,
    query: "What genes are most studied in rare disorders?",
    response:
      "Based on recent literature, the most studied genes in rare disorders include:\n\n• CFTR (Cystic Fibrosis)\n• DMD (Duchenne Muscular Dystrophy)\n• MECP2 (Rett Syndrome)\n\nThese genes appear in over 500+ curated datasets...",
    category: "Genomics",
  },
  {
    id: 2,
    query: "Show datasets using CRISPR in neurological disease.",
    response:
      "Found 127 datasets involving CRISPR studies in neurological conditions:\n\n• 45 studies on Alzheimer's models\n• 38 Parkinson's disease studies\n• 24 ALS-focused experiments...",
    category: "CRISPR",
  },
  {
    id: 3,
    query: "Summarize recent clinical trial findings in pediatric genomics",
    response:
      "Recent findings from 2023-2024 clinical trials show:\n\n• Novel gene therapy approaches for SMA\n• Breakthrough in rare pediatric disorders\n• Emerging biomarkers for early diagnosis...",
    category: "Clinical",
  },
];

const Copilot = () => {
  const [activeQuery, setActiveQuery] = useState(1);

  return (
    <section className="w-screen ml-[calc(-50vw+50%)] mr-[calc(-50vw+50%)] bg-gradient-to-b from-[#f8faff] to-white py-16 relative overflow-hidden before:content-[''] before:absolute before:inset-0 before:bg-radial-gradient-circle before:from-[rgba(86,158,253,0.08)] before:to-transparent before:to-70% before:opacity-100">
      <div className="max-w-[1200px] mx-auto px-[clamp(1.25rem,3vw,3rem)]">
        <h2 className="text-[clamp(1.5rem,4vw,2.5rem)] leading-tight mb-8 text-center font-semibold">Ask questions, get answers</h2>
        <p className="text-[clamp(1.1rem,2vw,1.25rem)] text-[#6B7280] text-center mb-12 max-w-[600px] mx-auto">
          Alchemy Copilot finds patterns, explains results, and shows sources.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-[1fr_1.5fr] gap-8 items-start">
          <div className="flex flex-col gap-4">
            {copilotQueries.map((item) => (
              <div
                key={item.id}
                className={`bg-white rounded-xl p-6 cursor-pointer transition-all duration-300 border border-[#E5E7EB] shadow-sm hover:translate-x-1 hover:border-[#569EFD] hover:shadow-md ${
                  activeQuery === item.id ? "bg-[#f0f7ff] translate-x-2 border-[#569EFD]" : ""
                }`}
                onClick={() => setActiveQuery(item.id)}
              >
                <div>
                  <span className={`text-sm uppercase tracking-wider mb-2 block ${
                    activeQuery === item.id ? "text-[#569EFD] opacity-100" : "opacity-80"
                  }`}>
                    {item.category}
                  </span>
                  <h3 className="text-[1.1rem] leading-relaxed font-medium">{item.query}</h3>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-white rounded-2xl shadow-md overflow-hidden border border-[#E5E7EB]">
            <div className="bg-white rounded-2xl overflow-hidden border border-[#E5E7EB]">
              <div className="bg-[#f8faff] p-4 flex items-center gap-4 border-b border-[#E5E7EB]">
                <div className="text-[#1F2937] text-[0.95rem] mx-auto font-medium">Alchemy Copilot</div>
              </div>
              <div className="p-6 text-[#1F2937] font-mono text-[0.95rem] leading-relaxed whitespace-pre-wrap bg-white">
                {copilotQueries.find((q) => q.id === activeQuery)?.response}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Copilot;
