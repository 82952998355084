import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { Button } from "@/components/ui/button";

interface BackButtonProps {
  text?: string;
  className?: string;
  path?: string;
}

const BackArrow = ({ text, className = "", path }: BackButtonProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(path || -1);
  };

  return (
    <Button
      type={"button"}
      onClick={handleBack}
      variant="ghost"
      className={`bg-transparent text-gray-500 hover:text-gray-700 transition-colors ${className}`}
    >
      <ChevronLeft className="w-4 h-4 inline-block mr-2" />
      {text}
    </Button>
  );
};

export default BackArrow;
