import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import FileInput from "@/components/shared/FileInput";

interface FileUploadsProps {
  formData: {
    dataFiles: File | null;
    methodsFile: File | null;
    createDOI: boolean;
    [key: string]: any;
  };
  handleFileChange: (name: string, file: File | null) => void;
  handleCheckboxChange: (name: string, checked: boolean) => void;
}

export function FileUploads({
  formData,
  handleFileChange,
  handleCheckboxChange,
}: FileUploadsProps) {
  return (
    <div className="space-y-2">
      <FileInput
        title="Data Files"
        subTitle="Upload data files"
        size={10}
        formData={formData}
        name="dataFiles"
        handleFileChange={handleFileChange}
      />
      {/* TODO: add methods file upload */}
      {/* <FileInput
        title="Methods/Summary File"
        subTitle="Upload methods file"
        size={5}
        formData={formData}
        name="methodsFile"
        handleFileChange={handleFileChange}
      /> */}
      <div className="flex items-center space-x-2 mt-4">
        <Checkbox
          id="createDOI"
          checked={formData.createDOI}
          onCheckedChange={(checked) =>
            handleCheckboxChange("createDOI", checked as boolean)
          }
        />
        <Label htmlFor="createDOI">Create DOI for this dataset</Label>
      </div>
    </div>
  );
}
