const CTA = ({
  title = "Your data. Your questions.",
  description = "Search across biological datasets with context, structure, and confidence",
  buttonText = "Try Alchemy Now",
  buttonLink = "/register",
  buttonIcon = null,
}) => {
  return (
    <section className="w-screen ml-[calc(-50vw+50%)] mr-[calc(-50vw+50%)] relative overflow-hidden">
      <div className="w-full max-w-[1200px] mx-auto px-[clamp(1.25rem,3vw,3rem)] text-center py-[clamp(3rem,10vh,6rem)] relative z-10">
        <h2 className="text-[clamp(1.5rem,4vw,2.5rem)] leading-tight mb-8 font-semibold">{title}</h2>
        <p className="mb-8 text-[#6B7280] text-[clamp(1rem,2vw,1.125rem)]">{description}</p>
        <div className="flex gap-4 justify-center flex-wrap">
          {typeof buttonText === 'string' ? (
            <a href={buttonLink}>
              <button className="bg-gradient-to-r from-[#569EFD] to-[#254cf6] text-white py-4 px-8 rounded-lg border-none font-medium cursor-pointer transition-all duration-200 relative overflow-hidden hover:transform hover:-translate-y-0.5 hover:shadow-[0_8px_24px_rgba(86,158,253,0.25),0_4px_12px_rgba(37,76,246,0.2)] flex items-center gap-2">
                {buttonIcon && buttonIcon}
                {buttonText}
              </button>
            </a>
          ) : (
            <div className="bg-gradient-to-r from-[#569EFD] to-[#254cf6] text-white py-4 px-8 rounded-lg border-none font-medium cursor-pointer transition-all duration-200 relative overflow-hidden hover:transform hover:-translate-y-0.5 hover:shadow-[0_8px_24px_rgba(86,158,253,0.25),0_4px_12px_rgba(37,76,246,0.2)]">
              {buttonText}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CTA;
