import { Card, CardContent } from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ChartColumnBig, Database, Award, Users } from "lucide-react";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { apiClient } from "@/api/apiClient";

export function MetricCards() {
  const [metrics, setMetrics] = useState({
    contributionScore: 0,
    totalDatasets: 0,
    dataQuality: 0,
    totalUsage: 0
  });
  
  const userData = useRouteLoaderData("userData") as { id: number };
  
  useEffect(() => {
    const fetchMetrics = async () => {
      if (!userData?.id) return;
      
      try {
        const response = await apiClient.get(`/users/${userData.id}/metrics`);
        setMetrics(response.data);
      } catch (error) {
        console.error('Error fetching metrics:', error);
      }
    };

    fetchMetrics();
  }, [userData?.id]);

  const metricCards = [
    {
      label: "Overall Contribution Score",
      value: metrics.contributionScore,
      icon: ChartColumnBig,
      tooltip: "Calculated based on dataset quality and usage",
      color: "bg-blue-100 text-blue-800",
    },
    {
      label: "Total Datasets",
      value: metrics.totalDatasets,
      icon: Database,
      tooltip: "Total number of datasets uploaded",
      color: "bg-green-100 text-green-800",
    },
    {
      label: "Average Data Quality",
      value: metrics.dataQuality,
      icon: Award,
      tooltip: "Mean quality score across all datasets",
      color: "bg-yellow-100 text-yellow-800",
    },
    {
      label: "Total Usage/Citations",
      value: metrics.totalUsage,
      icon: Users,
      tooltip: "Cumulative usage and citation count",
      color: "bg-purple-100 text-purple-800",
    },
  ];

  return (
    <section className="grid grid-cols-2 md:grid-cols-4 gap-2 mb-8">
      <TooltipProvider>
        {metricCards.map((metric, index) => (
          <Card
            key={index}
            className={` shadow-lg hover:shadow-xl transition-shadow duration-300`}
          >
            <CardContent className="flex justify-between p-5">
              <div>
                <p className="text-sm font-medium mb-2">{metric.label}</p>
                <p className="text-3xl font-bold">{metric.value}</p>
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <metric.icon
                      className={cn("w-10 h-10 p-2 rounded-full", metric.color)}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{metric.tooltip}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </CardContent>
          </Card>
        ))}
      </TooltipProvider>
    </section>
  );
}
