import { SignUp as ClerkSignUp } from "@clerk/clerk-react";
import { memo } from "react";

function SignUp() {
  return (
    <div className="min-h-screen grid justify-center">
      <div className="w-full min-w-[450px] max-w-[550px]">
        <ClerkSignUp
          routing="hash"
          signInUrl="/login"
          redirectUrl="/home"
          appearance={{
            layout: {
              logoPlacement: "inside",
              logoImageUrl: "/assets/logo.svg",
              socialButtonsVariant: "iconButton",
              logoImageSize: "128px",
            },
            elements: {
              rootBox: "w-full",
              card: "shadow-2xl rounded-lg",
              header: "gap-y-2",
              headerTitle: "text-2xl",
              headerSubtitle: "text-gray-500",
              formButtonPrimary: "h-14 text-base font-medium mt-4 mb-4 bg-primary hover:bg-primary/90",
              socialButtonsBlockButton: "border border-gray-300 hover:bg-gray-50",
              formFieldLabel: "text-base font-medium",
              formFieldInput: "h-12 text-base rounded-md border border-gray-300",
              footerAction: "text-sm text-gray-600 mt-4",
              footerActionLink: "text-primary hover:text-primary/90 font-medium",
            },
            variables: {
              headerTitle: "Sign Up",
              headerSubtitle: "Fill in your details below to create an account",
              footerActionText: "Already have an account?",
              footerActionLinkText: "Sign in",
            }
          }}
        />
      </div>
    </div>
  );
}

// Memoize the component to prevent unnecessary rerenders
export default memo(SignUp);
