const audienceData = [
  {
    id: 1,
    title: "Researchers",
    description: "Explore and validate faster",
    icon: "/icons/dna.svg",
  },
  {
    id: 2,
    title: "Bioinformaticians",
    description: "Structured pipelines, ready for modeling",
    icon: "/icons/computer.svg",
  },
  {
    id: 3,
    title: "Clinicians",
    description: "Surface insights from clinical & genomic overlap",
    icon: "/icons/hospital.svg",
  },
  {
    id: 4,
    title: "Data Scientists",
    description: "Leverage data for predictive analytics",
    icon: "/icons/chart.svg",
  },
];

const Audience = () => {
  return (
    <section className="w-screen ml-[calc(-50vw+50%)] mr-[calc(-50vw+50%)] py-16 bg-gradient-to-b from-white to-[#f8faff]">
      <div className="w-full max-w-[1200px] mx-auto px-[clamp(1.25rem,3vw,3rem)]">
        <h2 className="text-[clamp(1.5rem,4vw,2.5rem)] leading-tight mb-8 text-center font-semibold">
          Built for scientists working with data
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {audienceData.map((audience) => (
            <div 
              key={audience.id} 
              className="w-full bg-white p-8 rounded-[16px] shadow-sm border border-[#E5E7EB] transition-all duration-300 hover:transform hover:-translate-y-1 hover:shadow-lg hover:border-[#569EFD]"
            >
              <div className="w-12 h-12 flex items-center justify-center rounded-xl mb-4 transition-all duration-300">
                <img
                  src={audience.icon}
                  alt={audience.title}
                  className="w-6 h-6 object-contain transition-all duration-300 hover:scale-110"
                />
              </div>
              <h3 className="text-xl text-[#1F2937] mb-2 font-semibold tracking-tight">
                {audience.title}
              </h3>
              <p className="text-[#6B7280] leading-relaxed">
                {audience.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Audience;
