import { apiClient } from "@/api/apiClient";

const metrics = {
  contributionScore: "85",
  totalDatasets: "5",
  dataQuality: "92%",
  totalUsage: "15,234",
};

export const fetchDatasets = async () => {
  const response = await apiClient.get("/datasets");
  return response.data;
};

export const fetchDatasetMetrics = async () => {
  // TODO remove mocked data and uncomment API call when end-point available
  // const response = await apiClient.get("/datasets/metrics");
  return metrics;
};

export const fetchDataset = async ({ params }) => {
  const response = await apiClient.get(`/datasets/${params.id}`);
  return response.data;
};

const recentActivities = [
  {
    type: "comment",
    user: "User1234",
    title: "Comment on Human Genome Project",
    content: "User123: Great dataset! I found it very useful for my research.",
    timestamp: "2 hours ago",
  },
  {
    type: "question",
    user: "Researcher456",
    datasetName: "Drosophila Genome",
    title: "Question on Drosophila Genome",
    content:
      "Researcher456: Is there a newer version of this dataset available?",
    timestamp: "1 day ago",
  },
  {
    type: "update",
    user: "Researcher456",
    datasetName: "Drosophila Genome",
    title: "Edit on COVID-19 Clinical Trials",
    content: "Admin789 updated the dataset description and added new tags.",
    timestamp: "3 days ago",
  },
];

export const fetchDatasetsActivity = async () => {
  // TODO remove mocked data and uncomment API call when end-point available
  // const response = await apiClient.get("/datasets/activity");
  // return response.data;
  return recentActivities;
};
