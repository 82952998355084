// frontend/src/api/feedback.ts
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "@/api/apiClient";

interface FeedbackPayload {
  message: string;
  page_url?: string;
}

const submitFeedbackFn = async (data: FeedbackPayload) => {
  const response = await apiClient.post("/feedback", data);
  return response.data;
};

export function useSubmitFeedback() {
  const mutation = useMutation({
    mutationFn: submitFeedbackFn,
  });

  return {
    submitFeedback: mutation.mutate,
    isLoading: mutation.isPending,
    isSuccess: mutation.isSuccess,
    error: mutation.error,
  };
}