import { FC } from "react";

import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Calendar,
  Circle,
  Hexagon,
  LetterText,
  List,
  Loader2,
  Lock,
  MessageCircleMore,
  Users,
} from "lucide-react";
import { statusColors } from "@/components/projects/ProjectsList";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { useProjectById } from "@/api/project/projects";

const TABLE_HEADERS = [
  { key: "index", className: "font-bold !border-none", icon: List },
  {
    key: "name",
    label: "Name",
    className: "w-[200px] font-bold",
    icon: LetterText,
  },
  { key: "status", label: "Status", className: "font-bold", icon: Circle },
  { key: "type", label: "Type", className: "font-bold", icon: Hexagon },
  {
    key: "publicationDate",
    label: "Date of publication",
    className: "font-bold",
    icon: Calendar,
  },
  {
    key: "contributors",
    label: "Contributors",
    className: "font-bold",
    icon: Users,
  },
  {
    key: "comments",
    label: "Comments",
    className: "font-bold",
    icon: MessageCircleMore,
  },
  {
    key: "actions",
    label: "Actions",
    className: "font-bold !border-r-none",
    icon: MessageCircleMore,
  },
];

const StudyTableSkeleton = () => {
  return (
    <div className="rounded-md border shadow-sm overflow-hidden">
      <Table>
        <TableHeader>
          <TableRow className="bg-muted/80">
            {TABLE_HEADERS.map((header) => (
              <TableHead
                key={header.key}
                className={header.className + " border-b"}
              >
                <div className="flex items-center space-x-2">
                  {header.icon && <header.icon className="w-4 h-4 mr-2" />}
                  {header.label}
                </div>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {[1, 2, 3].map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Skeleton className="h-4 w-4" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-32" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-6 w-20" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-6 w-24" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-24" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-8" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-8" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-8 w-16" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const StudyTable: FC<any> = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: project, isLoading, isError } = useProjectById(Number(id));

  if (isLoading || !project) return <StudyTableSkeleton />;

  if (!project.studies || !Array.isArray(project.studies)) {
    return <div>No studies available</div>;
  }

  const handleRowClick = (id: number) => {
    navigate(`study/${id}`);
  };

  const editHandler = (id: number) => {
    navigate(`study/${id}/edit`);
  };

  return (
    <div className="rounded-md border shadow-sm overflow-hidden">
      <Table>
        <TableHeader>
          <TableRow className="bg-muted/80">
            {TABLE_HEADERS.map((header) => (
              <TableHead
                key={header.key}
                className={header.className + " border-b"}
              >
                <div className="flex  items-center space-x-2">
                  {header.icon && <header.icon className="w-4 h-4 mr-2" />}
                  {header.label}
                </div>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {project.studies.map((study, index) => (
            <TableRow
              key={study.id}
              className={`cursor-pointer hover:bg-muted/50 transition-colors ${
                index % 2 === 0 ? "bg-muted/20" : ""
              }`}
              onClick={() => handleRowClick(study.id)}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell className="font-medium">{study.title}</TableCell>
              <TableCell>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <div className="flex items-center space-x-2">
                        <div
                          className={`w-20 p-1 rounded-full text-xs ${
                            statusColors[study.status]
                          }`}
                        >
                          <span>{study.status}</span>
                        </div>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>study status</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
              <TableCell>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <div
                        className={`flex items-center space-x-2 p-1 rounded-md text-xs bg-black text-white`}
                      >
                        <Lock size={14} />
                        <span>{study.is_public ? "Public" : "Private"}</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>studys visibility</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
              <TableCell>
                <div className={`flex items-center space-x-2`}>
                  <Calendar size={14} className="mr-2" />
                  {new Date(study.created_at)
                    .toLocaleDateString("en-US")
                    .replace(/\//g, ".")}
                </div>
              </TableCell>
              <TableCell>{study.collaborators.length}</TableCell>
              <TableCell>{study.comments.length}</TableCell>
              <TableCell>
                <Button
                  type={"button"}
                  onClick={(e) => {
                    e.stopPropagation();
                    editHandler(study.id);
                  }}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default StudyTable;
