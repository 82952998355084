import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"

export function Header() {
  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-16 items-center justify-between">
        <div className="mb-4">
            <h1 className="text-2xl font-bold tracking-tight mb-1">Biology AI Models</h1>
            <p className="text-sm text-muted-foreground">
                Discover and explore cutting-edge AI models for biological research
            </p>
        </div>
        <Link to="/models/new">
            <Button>Upload Model</Button>
        </Link>
      </div>
    </header>
  )
}

