import { createContext, useContext, useState } from 'react';

interface FeedbackContextType {
  isVisible: boolean;
  toggleVisibility: () => void;
}

const FeedbackContext = createContext<FeedbackContextType | undefined>(undefined);

export function FeedbackProvider({ children }: { children: React.ReactNode }) {
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  return (
    <FeedbackContext.Provider value={{ isVisible, toggleVisibility }}>
      {children}
    </FeedbackContext.Provider>
  );
}

export function useFeedbackVisibility() {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error('useFeedbackVisibility must be used within a FeedbackProvider');
  }
  return context;
}