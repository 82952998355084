import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Search } from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

const cellTypes = [
  "Stem Cells",
  "Neurons",
  "Immune Cells",
  "Cancer Cells",
  "Epithelial Cells",
  "Fibroblasts"
]

const metrics = [
  "Accuracy",
  "F1 Score",
  "AUC-ROC",
  "Precision",
  "Recall"
]

const organisms = [
  "Human",
  "Mouse",
  "Rat",
  "Zebrafish",
  "Drosophila",
  "C. elegans",
  "E. coli",
  "Any"
]

interface FiltersProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  cellType: string;
  setCellType: (type: string) => void;
  metric: string;
  setMetric: (metric: string) => void;
  architecture: string;
  setArchitecture: (arch: string) => void;
  organism: string;
  setOrganism: (org: string) => void;
  onReset: () => void;
}

export function Filters({
  searchQuery,
  setSearchQuery,
  cellType,
  setCellType,
  metric,
  setMetric,
  architecture,
  setArchitecture,
  organism,
  setOrganism,
  onReset
}: FiltersProps) {
  return (
    <div className="flex flex-wrap items-center gap-2">
      <div className="relative flex-grow">
        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          type="search"
          placeholder="Search models..."
          className="pl-8 w-full h-9"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <Select value={organism} onValueChange={setOrganism}>
        <SelectTrigger className="w-[130px] h-9">
          <SelectValue placeholder="Organism" />
        </SelectTrigger>
        <SelectContent>
          {organisms.map((org) => (
            <SelectItem key={org} value={org.toLowerCase()}>
              {org}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Select value={cellType} onValueChange={setCellType}>
        <SelectTrigger className="w-[130px] h-9">
          <SelectValue placeholder="Cell Type" />
        </SelectTrigger>
        <SelectContent>
          {cellTypes.map((type) => (
            <SelectItem key={type} value={type.toLowerCase()}>
              {type}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Select value={architecture} onValueChange={setArchitecture}>
        <SelectTrigger className="w-[160px] h-9">
          <SelectValue placeholder="Architecture" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="transformer">Transformer</SelectItem>
          <SelectItem value="cnn">CNN</SelectItem>
          <SelectItem value="rnn">RNN</SelectItem>
          <SelectItem value="gan">GAN</SelectItem>
        </SelectContent>
      </Select>
      <Select value={metric} onValueChange={setMetric}>
        <SelectTrigger className="w-[160px] h-9">
          <SelectValue placeholder="Performance" />
        </SelectTrigger>
        <SelectContent>
          {metrics.map((metric) => (
            <SelectItem key={metric} value={metric.toLowerCase()}>
              {metric}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Button variant="secondary" className="h-9" onClick={onReset}>Reset</Button>
    </div>
  )
}

