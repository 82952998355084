"use client"

import { useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Progress } from "@/components/ui/progress"
import { Upload } from 'lucide-react'
import { cn } from "@/lib/utils"
import { apiClient } from "@/api/apiClient"
import { useNavigate } from "react-router-dom"

const MODEL_FILE_TYPES = {
  weights: ['.pt', '.pth', '.pb', '.h5', '.keras', '.bin', '.onnx'],
  config: ['.json', '.yaml', '.yml'],
  vocab: ['.txt', '.vocab', '.json'],
  other: ['.py', '.md'] // For additional files like custom modules or documentation
}

const formSchema = z.object({
  modelName: z.string().min(2, {
    message: "Model name must be at least 2 characters.",
  }),
  description: z.string().optional(),
  version: z.string().refine(
    (val) => /^\d+\.\d+\.\d+$/.test(val),
    {
      message: "Version must be in format X.X.X (e.g., 1.0.0)",
    }
  ),
  organism: z.string().min(1, {
    message: "Organism is required.",
  }),
  customOrganism: z.string().optional(),
  tissueType: z.string().optional(),
  customTissueType: z.string().optional(),
  modelArchitecture: z.string().optional(),
  customModelArchitecture: z.string().optional(),
  modelFiles: z.object({
    weights: z.array(z.instanceof(File)).min(1, "Model weights are required"),
    config: z.array(z.instanceof(File)).optional(),
    vocab: z.array(z.instanceof(File)).optional(),
    other: z.array(z.instanceof(File)).optional(),
  }),
})

export default function ModelUploadForm() {
  const navigate = useNavigate()
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [filesByType, setFilesByType] = useState<{
    weights: File[],
    config: File[],
    vocab: File[],
    other: File[]
  }>({
    weights: [],
    config: [],
    vocab: [],
    other: []
  })
  const [error, setError] = useState<string | null>(null)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
      version: "1.0.0",
    },
  })

  const categorizeFile = (file: File) => {
    const ext = '.' + file.name.split('.').pop()?.toLowerCase()
    if (MODEL_FILE_TYPES.weights.includes(ext)) return 'weights'
    if (MODEL_FILE_TYPES.config.includes(ext)) return 'config'
    if (MODEL_FILE_TYPES.vocab.includes(ext)) return 'vocab'
    return 'other'
  }

  const handleFileChange = (files: FileList | null, type: string) => {
    if (!files) return

    const newFiles = Array.from(files)
    if (type === 'auto') {
      // Automatically categorize files
      const categorized = {
        weights: [...filesByType.weights],
        config: [...filesByType.config],
        vocab: [...filesByType.vocab],
        other: [...filesByType.other]
      }
      
      newFiles.forEach(file => {
        const category = categorizeFile(file)
        categorized[category] = [...categorized[category], file]
      })
      
      setFilesByType(categorized)
      // Update form values for each category
      Object.entries(categorized).forEach(([category, files]) => {
        //@ts-ignore
        form.setValue(`modelFiles.${category}`, files)
      })
    } else {
      // Manual category selection
      const updatedFiles = [...filesByType[type], ...newFiles]
      setFilesByType(prev => ({
        ...prev,
        [type]: updatedFiles
      }))
      //@ts-ignore
      form.setValue(`modelFiles.${type}`, updatedFiles)
    }
  }

  const removeFile = (type: string, index: number) => {
    const updatedFiles = filesByType[type].filter((_, i) => i !== index)
    setFilesByType(prev => ({
      ...prev,
      [type]: updatedFiles
    }))
    //@ts-ignore
    form.setValue(`modelFiles.${type}`, updatedFiles)
  }

  // Replace the existing model file field with this new section
  const renderFileUploadSection = () => (
    <div className="grid gap-6 md:grid-cols-2">
      {/* Weights Section */}
      <FormField
        control={form.control}
        name="modelFiles.weights"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Model Weights *</FormLabel>
            <FormControl>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <Button
                    type="button"
                    variant="outline"
                    className="w-full"
                    onClick={() => {
                      const input = document.createElement('input')
                      input.type = 'file'
                      input.multiple = true
                      input.accept = MODEL_FILE_TYPES.weights.join(',')
                      input.onchange = (e) => handleFileChange((e.target as HTMLInputElement).files, 'weights')
                      input.click()
                    }}
                  >
                    <Upload className="w-4 h-4 mr-2" />
                    Upload Weights
                  </Button>
                </div>
                {filesByType.weights.length > 0 && (
                  <div className="space-y-2">
                    {filesByType.weights.map((file, index) => (
                      <div key={index} className="flex items-center justify-between bg-muted p-2 rounded-md">
                        <span className="text-sm truncate">{file.name}</span>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeFile('weights', index)}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </FormControl>
            <FormDescription>
              Supported: .pt, .pth, .pb, .h5, .keras, .bin, .onnx
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      {/* Config Section */}
      <FormField
        control={form.control}
        name="modelFiles.config"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Configuration Files</FormLabel>
            <FormControl>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <Button
                    type="button"
                    variant="outline"
                    className="w-full"
                    onClick={() => {
                      const input = document.createElement('input')
                      input.type = 'file'
                      input.multiple = true
                      input.accept = MODEL_FILE_TYPES.config.join(',')
                      input.onchange = (e) => handleFileChange((e.target as HTMLInputElement).files, 'config')
                      input.click()
                    }}
                  >
                    <Upload className="w-4 h-4 mr-2" />
                    Upload Config
                  </Button>
                </div>
                {filesByType.config.length > 0 && (
                  <div className="space-y-2">
                    {filesByType.config.map((file, index) => (
                      <div key={index} className="flex items-center justify-between bg-muted p-2 rounded-md">
                        <span className="text-sm truncate">{file.name}</span>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeFile('config', index)}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </FormControl>
            <FormDescription>
              Supported: .json, .yaml, .yml
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      {/* Vocabulary Section */}
      <FormField
        control={form.control}
        name="modelFiles.vocab"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Vocabulary Files</FormLabel>
            <FormControl>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <Button
                    type="button"
                    variant="outline"
                    className="w-full"
                    onClick={() => {
                      const input = document.createElement('input')
                      input.type = 'file'
                      input.multiple = true
                      input.accept = MODEL_FILE_TYPES.vocab.join(',')
                      input.onchange = (e) => handleFileChange((e.target as HTMLInputElement).files, 'vocab')
                      input.click()
                    }}
                  >
                    <Upload className="w-4 h-4 mr-2" />
                    Upload Vocabulary
                  </Button>
                </div>
                {filesByType.vocab.length > 0 && (
                  <div className="space-y-2">
                    {filesByType.vocab.map((file, index) => (
                      <div key={index} className="flex items-center justify-between bg-muted p-2 rounded-md">
                        <span className="text-sm truncate">{file.name}</span>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeFile('vocab', index)}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </FormControl>
            <FormDescription>
              Supported: .txt, .vocab, .json
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      {/* Other Files Section */}
      <FormField
        control={form.control}
        name="modelFiles.other"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Additional Files</FormLabel>
            <FormControl>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <Button
                    type="button"
                    variant="outline"
                    className="w-full"
                    onClick={() => {
                      const input = document.createElement('input')
                      input.type = 'file'
                      input.multiple = true
                      input.accept = MODEL_FILE_TYPES.other.join(',')
                      input.onchange = (e) => handleFileChange((e.target as HTMLInputElement).files, 'other')
                      input.click()
                    }}
                  >
                    <Upload className="w-4 h-4 mr-2" />
                    Upload Additional Files
                  </Button>
                </div>
                {filesByType.other.length > 0 && (
                  <div className="space-y-2">
                    {filesByType.other.map((file, index) => (
                      <div key={index} className="flex items-center justify-between bg-muted p-2 rounded-md">
                        <span className="text-sm truncate">{file.name}</span>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeFile('other', index)}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </FormControl>
            <FormDescription>
              Supported: .py, .md
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )

  // Update the onSubmit function to handle multiple files
  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setError(null) // Clear any previous errors
      setIsUploading(true)
      
      const formData = new FormData()
      // Append basic form fields
      formData.append('model_name', values.modelName)
      formData.append('description', values.description || '')
      formData.append('version', values.version)
      formData.append('organism', values.organism)
      formData.append('tissue_type', values.tissueType || '')
      formData.append('model_architecture', values.modelArchitecture || '')
      formData.append('publicly_accessible', 'true')
      
      // Append files by category
      Object.entries(filesByType).forEach(([type, files]) => {
        files.forEach(file => {
          formData.append(`${type}`, file)
        })
      })

      // Show upload progress
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => Math.min(prev + 5, 90))
      }, 500)

      try {
        const response = await apiClient.post('/models/upload', formData)
        
        // Since the upload was successful (201), we can proceed
        setUploadProgress(100)
        
        // Reset form and redirect
        form.reset()
        setFilesByType({
          weights: [],
          config: [],
          vocab: [],
          other: []
        })
        
        navigate('/models')
        
      } catch (error) {
        throw error
      } finally {
        clearInterval(progressInterval)
      }
      
    } catch (error) {
      console.error("Upload failed:", error)
      setError(error instanceof Error ? error.message : 'Failed to upload model. Please try again.')
      form.setError('root', { 
        type: 'manual',
        message: error instanceof Error ? error.message : 'Failed to upload model. Please try again.'
      })
    } finally {
      setIsUploading(false)
      setUploadProgress(0)
    }
  }

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>Upload AI Model</CardTitle>
        <CardDescription>
          Share your AI model with the community. Please provide accurate information to help others understand and use your model effectively.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="modelName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Model Name *</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter model name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Describe your model's purpose, capabilities, and any special requirements"
                      className="min-h-[100px]"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid gap-6 md:grid-cols-2">
              <FormField
                control={form.control}
                name="version"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Version *</FormLabel>
                    <FormControl>
                      <Input placeholder="e.g., 1.0.0" {...field} />
                    </FormControl>
                    <FormDescription>
                      Use semantic versioning format: X.X.X
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="organism"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Organism</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select organism" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="human">Human</SelectItem>
                        <SelectItem value="mouse">Mouse</SelectItem>
                        <SelectItem value="rat">Rat</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                    {field.value === "other" && (
                      <Input 
                        placeholder="Specify organism"
                        {...form.register("customOrganism")}
                        className="mt-2"
                      />
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tissueType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tissue Type</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select tissue type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="brain">Brain</SelectItem>
                        <SelectItem value="heart">Heart</SelectItem>
                        <SelectItem value="liver">Liver</SelectItem>
                        <SelectItem value="kidney">Kidney</SelectItem>
                        <SelectItem value="lung">Lung</SelectItem>
                        <SelectItem value="muscle">Muscle</SelectItem>
                        <SelectItem value="blood">Blood</SelectItem>
                        <SelectItem value="bone">Bone</SelectItem>
                        <SelectItem value="skin">Skin</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                    {field.value === "other" && (
                      <Input 
                        placeholder="Specify tissue type"
                        {...form.register("customTissueType")}
                        className="mt-2"
                      />
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="modelArchitecture"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Model Architecture</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select architecture" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="cnn">CNN</SelectItem>
                        <SelectItem value="rnn">RNN</SelectItem>
                        <SelectItem value="lstm">LSTM</SelectItem>
                        <SelectItem value="transformer">Transformer</SelectItem>
                        <SelectItem value="gan">GAN</SelectItem>
                        <SelectItem value="autoencoder">Autoencoder</SelectItem>
                        <SelectItem value="unet">U-Net</SelectItem>
                        <SelectItem value="resnet">ResNet</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                    {field.value === "other" && (
                      <Input 
                        placeholder="Specify model architecture"
                        {...form.register("customModelArchitecture")}
                        className="mt-2"
                      />
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {renderFileUploadSection()}

            {error && (
              <div className="bg-destructive/15 text-destructive px-4 py-2 rounded-md text-sm">
                {error}
              </div>
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={isUploading}
            >
              <Upload className={cn("w-4 h-4 mr-2", isUploading && "animate-pulse")} />
              {isUploading ? "Uploading..." : "Submit"}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}

