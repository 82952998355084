import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { apiClient } from "@/api/apiClient";
import { userQueryKeys } from "./userQueryKeys";

const forgotPasswordFn = async (data) => {
  const response = await apiClient.post("/auth/forgot-password", data);
  return response.data;
};

export function useForgotPassword() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: forgotPasswordFn,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: userQueryKeys.all });
    },
    onSuccess: (data) => {
      toast.success("Please check your email for reset password link");
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(userQueryKeys.all, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.all });
    },
  });
}

const resetPasswordFn = async (data) => {
  const response = await apiClient.post("/auth/reset-password", data);
  return response.data;
};

export function useResetPassword() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: resetPasswordFn,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: userQueryKeys.all });
    },
    onSuccess: (data) => {
      toast.success("New password excepted");
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(userQueryKeys.all, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.all });
    },
  });
}

const changePasswordFn = async (data) => {
  const response = await apiClient.post("/auth/change-password", data);
  return response.data;
};

export function useChangePassword() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: changePasswordFn,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: userQueryKeys.all });
    },
    onSuccess: (data) => {
      toast.success("Password changed");
      return data;
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(userQueryKeys.all, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.all });
    },
  });

  return {
    changePassword: mutation.mutate,
    isLoading: mutation.isPending,
    isSuccess: mutation.isSuccess,
    error: mutation.error,
  };
}
