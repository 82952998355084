import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Label } from "@/components/ui/label"
import { categories } from "@/lib/biology-categories"

interface BiologySelectionProps {
  formData: {
    category: string;
    subcategory: string;
    technique: string;
    [key: string]: any;
  };
  handleSelectChange: (name: string, value: string) => void;
}

export function BiologySelection({ formData, handleSelectChange }: BiologySelectionProps) {
  const subcategories = formData.category ? categories[formData.category].subcategories : [];
  const techniques = formData.subcategory && formData.category ? categories[formData.category].techniques[formData.subcategory] : [];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      <div>
        <Label htmlFor="category">Category</Label>
        <Select
          value={formData.category}
          onValueChange={(value) => handleSelectChange('category', value)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select category" />
          </SelectTrigger>
          <SelectContent>
            {Object.keys(categories).map((category) => (
              <SelectItem key={category} value={category}>
                {category}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {subcategories.length > 0 && (
        <div>
          <Label htmlFor="subcategory">Subcategory</Label>
          <Select
            value={formData.subcategory}
            onValueChange={(value) => handleSelectChange('subcategory', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select subcategory" />
            </SelectTrigger>
            <SelectContent>
              {subcategories.map((subcategory) => (
                <SelectItem key={subcategory} value={subcategory}>
                  {subcategory}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {techniques.length > 0 && (
        <div>
          <Label htmlFor="technique">Technique</Label>
          <Select
            value={formData.technique}
            onValueChange={(value) => handleSelectChange('technique', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select technique" />
            </SelectTrigger>
            <SelectContent>
              {techniques.map((technique) => (
                <SelectItem key={technique} value={technique}>
                  {technique}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
    </div>
  )
}
