import { Link, NavLink } from "react-router-dom";
import {
  House,
  Aperture,
  HardDrive,
  Layers,
  Cpu,
  MessageSquare,
} from "lucide-react";
import { cn } from "@/lib/utils";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "@/components/ui/sidebar";
import UserMenu from "./UserMenu";
import { useSidebar } from "@/components/ui/sidebar";
import { useFeedbackVisibility } from "@/context/FeedbackContext";

const menuItems = [
  {
    title: "Home",
    url: "/home",
    icon: House,
  },
  {
    title: "Library",
    url: "/library",
    icon: HardDrive,
  },
  {
    title: "Discover",
    url: "/discover",
    icon: Aperture,
  },
  {
    title: "Models",
    url: "/models",
    icon: Layers,
  },
  {
    title: "BDSI",
    url: "/bdsi",
    icon: Cpu,
  },
  // TODO: hiding for feb 10
  // {
  //   title: "Projects",
  //   url: "/projects",
  //   icon: FolderOpen,
  // },
  // {
  //   title: "Organization",
  //   url: "/organization",
  //   icon: Network,
  // },
];

export default function SideBar({ user }) {
  const { state } = useSidebar(); // "collapsed" or "expanded"
  const collapsed = state === "collapsed";
  const { isVisible, toggleVisibility } = useFeedbackVisibility();

  return (
    <Sidebar
      collapsible="icon"
      className={cn(!collapsed && "px-2 pt-3", "bg-white")}
    >
      <SidebarHeader className="overflow-x-hidden mb-3">
        <Link to="/home" className="block w-[160px]">
          {collapsed ? (
            <img
              src="/assets/logo-icon.svg"
              alt="Alchemy"
              className="w-[39px] h-[39px]"
            />
          ) : (
            <img src="/assets/logo.svg" alt="Alchemy" />
          )}
        </Link>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu className="space-y-2">
            {menuItems.map((item) => (
              <SidebarMenuItem key={item.title}>
                <SidebarMenuButton asChild>
                  <NavLink
                    to={item.url}
                    className="text-black text-lg [&.active]:bg-primary [&.active]:text-white flex items-center"
                  >
                    <item.icon className="w-6 h-6 mr-2" />
                    <span>{item.title}</span>
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <div className="flex flex-col gap-2">
          <button
            onClick={toggleVisibility}
            className="flex items-center text-sm px-3 py-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100"
          >
            <MessageSquare className="w-4 h-4 mr-2" />
            {!collapsed && (
              <span>{isVisible ? "Hide Feedback" : "Show Feedback"}</span>
            )}
            {!isVisible && !collapsed && (
              <span className="ml-2 w-2 h-2 rounded-full bg-blue-500"></span>
            )}
            {!isVisible && collapsed && (
              <span className="w-2 h-2 rounded-full bg-blue-500"></span>
            )}
          </button>
          <UserMenu user={user} />
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}
