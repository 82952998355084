import { useRouteError, Navigate } from "react-router-dom";
import { CircleX } from "lucide-react";
import { toast } from "react-toastify";
import NotFound from "./NotFound";

// Static variables outside component to persist across remounts
let hasShownToast = false;
let resetTimer: NodeJS.Timeout | null = null;

export default function LoadingError() {
  const error = useRouteError();

  if (error.status === 401 && !hasShownToast) {
    toast.error("Session expired");
    hasShownToast = true;
    
    // Reset the flag after a delay
    if (resetTimer) clearTimeout(resetTimer);
    resetTimer = setTimeout(() => {
      hasShownToast = false;
    }, 2000); 
  }

  switch (error.status) {
    case 401:
      return <Navigate to="/login" />;

    case 404:
      return <NotFound />;

    default:
      return (
        <div className="w-full py-10 flex flex-col justify-center items-center space-y-2 text-xl">
          <CircleX size="100px" className="text-red-700" />
          Something went wrong
        </div>
      );
  }
}
