import { useEffect, useState } from "react";
import { X } from "lucide-react";
import Cookies from "universal-cookie";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";

export default function SearchHistory({ setHistoryValue }) {
  const cookies = new Cookies(null, { path: "/" });
  const [historyItems, setHistoryItems] = useState([]);
  useEffect(() => {
    setHistoryItems(cookies.get("searchHistory") || []);
    cookies.addChangeListener(() => {
      setHistoryItems(cookies.get("searchHistory") || []);
    });
  }, []);
  function remove(item) {
    const array = historyItems;
    array.splice(array.indexOf(item), 1);
    setHistoryItems(array);
    cookies.set("searchHistory", array);
  }
  return (
    <Command className="h-auto">
      <CommandInput placeholder="Search" className="" />
      <CommandList>
        {historyItems.map((item, i) => (
          <CommandItem
            className="flex justify-between p-2 mt-2 bg-primary-foreground"
            key={i}
          >
            <div
              onClick={() => setHistoryValue(item)}
              className="hover:opacity-50 cursor-pointer"
            >
              {item}
            </div>
            <X
              className="cursor-pointer hover:opacity-50 w-5 h-5"
              onClick={() => remove(item)}
            />
          </CommandItem>
        ))}

        <CommandEmpty>No results found.</CommandEmpty>
      </CommandList>
    </Command>
  );
}
