import { FC } from 'react';
import { useAllUsers } from "@/api/user/users";
import { MultiSelect } from "@/components/ui/multi-select";
import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Control } from 'react-hook-form';
import { GeneralInfo } from "@/types/study-model";

interface CollaboratorSelectProps {
    control: Control<GeneralInfo>;
}

export const CollaboratorSelect: FC<CollaboratorSelectProps> = ({ control }) => {
    const { data: users, isLoading, isError } = useAllUsers();

    const userList = (isLoading || isError) ? [] : users.map((user) => ({
        value: user.id.toString(),
        label: user.username
    }));

    return (
        <FormField
            control={control}
            name="collaborators"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>Invite Collaborators</FormLabel>
                    <FormControl>
                        <MultiSelect
                            options={userList}
                            onValueChange={(data) => {
                                field.onChange(data.map(Number));
                            }}
                            value={field.value.map(String)}
                            placeholder="Enter username or Email"
                            variant="secondary"
                            animation={3}
                        />
                    </FormControl>
                </FormItem>
            )}
        />
    );
};