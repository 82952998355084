import {useQuery} from "@tanstack/react-query";
import {categoriesQueryKeys} from "@/api/categories/categoriesQueryKeys";
import { apiClient } from "@/api/apiClient";
import {Category} from "@/types/study-model";

const fetchAllCategoriesFn = async ()=>{
    const response = await apiClient.get('/categories')
    return response.data
}
export function useAllCategories() {
    return useQuery<Category[]>({
        queryFn: fetchAllCategoriesFn,
        networkMode: "online",
        queryKey: categoriesQueryKeys.all
    })
}