import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Slider } from "@/components/ui/slider"

export function TableFilters() {
  return (
    <div className="flex flex-wrap gap-4 mb-4 items-center">
      <Select>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Filter by Organism" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="homo-sapiens">Homo sapiens</SelectItem>
          <SelectItem value="drosophila-melanogaster">Drosophila melanogaster</SelectItem>
          <SelectItem value="sars-cov-2">SARS-CoV-2</SelectItem>
        </SelectContent>
      </Select>
      <Select>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Filter by Source" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="nih">NIH</SelectItem>
          <SelectItem value="flybase">FlyBase</SelectItem>
          <SelectItem value="who">WHO</SelectItem>
          <SelectItem value="noaa">NOAA</SelectItem>
          <SelectItem value="nci">NCI</SelectItem>
        </SelectContent>
      </Select>
      <div className="flex items-center space-x-2">
        <span className="text-sm font-medium">Quality Score:</span>
        <Slider defaultValue={[0, 100]} max={100} step={1} className="w-[200px]" />
      </div>
    </div>
  )
}