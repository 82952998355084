const VideoDemo = () => {
    return (
      <section className="w-screen ml-[calc(-50vw+50%)] mr-[calc(-50vw+50%)] py-16 bg-gradient-to-b from-white to-[#f8faff]">
        <div className="w-full max-w-[1200px] mx-auto px-[clamp(1.25rem,3vw,3rem)]">
          <h2 className="text-[clamp(1.5rem,4vw,2.5rem)] leading-tight mb-8 text-center font-semibold">
            Why we built Alchemy
          </h2>
          <p className="text-[clamp(1.1rem,2vw,1.25rem)] text-[#6B7280] text-center mb-12 max-w-[800px] mx-auto">
            See how Alchemy transforms the way researchers work with biological data
          </p>
          <div className="w-full max-w-[900px] mx-auto rounded-2xl overflow-hidden shadow-lg border border-[#E5E7EB]">
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
              <iframe
                src="https://www.loom.com/embed/59277c336db743498e28bf73ee4ed1f5"
                frameBorder="0"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                title="Why we built Alchemy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default VideoDemo;