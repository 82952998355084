import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "@/api/apiClient";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { ChevronDown, ExternalLink } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface Dataset {
  id: number;
  title: string;
  category: string;
  source: string;
  created_at: string;
  alchemy_score: number | null;
}

interface DiscoverResponse {
  datasets: Dataset[];
  total: number;
}

export default function DiscoverPage() {
  const [page, setPage] = useState(1);
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null);
  const [sourceFilter, setSourceFilter] = useState<string | null>(null);
  const [openFilter, setOpenFilter] = useState<string | null>(null);
  const [featuredTab, setFeaturedTab] = useState("images");
  const limit = 100;

  // Featured biological data repositories
  const featuredRepositories = {
    images: [
      {
        name: "Cell Image Library (CIL)",
        description: "10,000+ datasets and 20TB of cellular images, videos, and animations",
        link: "http://www.cellimagelibrary.org/"
      },
      {
        name: "The Cancer Imaging Archive (TCIA)",
        description: "CT, MRI, and PET images for cancer research",
        link: "https://www.cancerimagingarchive.net/"
      },
      {
        name: "Euro Bioimaging",
        description: "Biological and biomedical imaging data with analysis tools",
        link: "http://www.eurobioimaging.eu/"
      },
      {
        name: "OASIS",
        description: "MRI datasets focused on Alzheimer's Disease",
        link: "http://www.oasis-brains.org/"
      },
      {
        name: "COVID-19 Image Collection",
        description: "Chest X-ray/CT images for COVID-19 research",
        link: "https://github.com/ieee8023/covid-chestxray-dataset"
      }
    ],
    signals: [
      {
        name: "PhysioNet",
        description: "Comprehensive repository for physiological signals (ECG, EEG, etc.)",
        link: "https://physionet.org/"
      },
      {
        name: "Temple University EEG Corpus",
        description: "Large collection of EEG recordings",
        link: "https://isip.piconepress.com/projects/tuh_eeg/"
      },
      {
        name: "DEAP",
        description: "Database for Emotion Analysis using Physiological signals",
        link: "http://www.eecs.qmul.ac.uk/mmv/datasets/deap/"
      },
      {
        name: "NinaPro",
        description: "Non-invasive adaptive hand prosthetics EMG database",
        link: "https://ninaweb.hevs.ch/"
      },
      {
        name: "BCI Competition Datasets",
        description: "Benchmark datasets for brain-computer interface research",
        link: "http://www.bbci.de/competition/"
      }
    ],
    sequences: [
      {
        name: "Gene Expression Omnibus (GEO)",
        description: "Functional genomics data repository",
        link: "https://www.ncbi.nlm.nih.gov/geo/"
      },
      {
        name: "The Cancer Genome Atlas (TCGA)",
        description: "Comprehensive cancer genomics data",
        link: "https://www.cancer.gov/about-nci/organization/ccg/research/structural-genomics/tcga"
      },
      {
        name: "ENCODE",
        description: "Encyclopedia of DNA Elements",
        link: "https://www.encodeproject.org/"
      },
      {
        name: "PubChem",
        description: "Chemical structure and biological activity database",
        link: "https://pubchem.ncbi.nlm.nih.gov/"
      },
      {
        name: "Protein Data Bank (PDB)",
        description: "3D structural data of proteins and nucleic acids",
        link: "https://www.rcsb.org/"
      }
    ]
  };

  // First query to get unique categories and sources
  const { data: filterOptions } = useQuery({
    queryKey: ['datasets', 'filters'],
    queryFn: async () => {
      const { data } = await apiClient.get<{ categories: string[], sources: string[] }>('/datasets/filters');
      return data;
    }
  });

  const { data, isLoading } = useQuery({
    queryKey: ['datasets', 'discover', page, categoryFilter, sourceFilter],
    queryFn: async () => {
      const skip = (page - 1) * limit;
      const params = new URLSearchParams({
        skip: skip.toString(),
        limit: limit.toString(),
        ...(categoryFilter && categoryFilter !== 'all' && { category: categoryFilter }),
        ...(sourceFilter && sourceFilter !== 'all' && { source: sourceFilter }),
      });
      const { data } = await apiClient.get<DiscoverResponse>(`/datasets/discover?${params}`);
      return data;
    }
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleFilterClick = (filterName: string) => {
    setOpenFilter(openFilter === filterName ? null : filterName);
  };

  const FilterDropdown = ({ 
    name, 
    options, 
    value, 
    onChange 
  }: { 
    name: string;
    options: string[];
    value: string | null;
    onChange: (value: string | null) => void;
  }) => (
    <div className="relative">
      <button
        className={`
          min-w-[240px] p-2 text-left border rounded-md flex items-center justify-between
          ${openFilter === name ? 'border-black' : 'border-gray-200'}
          hover:border-gray-300
        `}
        onClick={() => handleFilterClick(name)}
      >
        <span className="text-sm">
          {value || `All ${name === 'Category' ? 'Categories' : `${name}s`}`}
        </span>
        <ChevronDown 
          className={`w-4 h-4 transition-transform ${openFilter === name ? 'transform rotate-180' : ''}`}
        />
      </button>
      
      {openFilter === name && (
        <div className="absolute z-50 w-full mt-1 bg-white border rounded-md shadow-lg max-h-[300px] overflow-y-auto">
          <div 
            className="p-2 text-sm hover:bg-gray-50 cursor-pointer"
            onClick={() => {
              onChange(null);
              setOpenFilter(null);
            }}
          >
            All {name}s
          </div>
          {options?.map((option) => (
            <div
              key={option}
              className="p-2 text-sm hover:bg-gray-50 cursor-pointer"
              onClick={() => {
                onChange(option);
                setOpenFilter(null);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-white">
      <main className="max-w-screen-xl mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-6">Discover Datasets</h1>
        
        {/* Featured Biological Data Repositories Section */}
        <div className="mb-10 p-6 bg-gray-50 rounded-lg border border-gray-100">
          <h2 className="text-xl font-semibold mb-4">Featured Biological Data Repositories</h2>
          <Tabs defaultValue="images" value={featuredTab} onValueChange={setFeaturedTab}>
            <TabsList className="mb-4">
              <TabsTrigger value="images">Images</TabsTrigger>
              <TabsTrigger value="signals">Signals</TabsTrigger>
              <TabsTrigger value="sequences">Sequences</TabsTrigger>
            </TabsList>
            
            {Object.entries(featuredRepositories).map(([category, repos]) => (
              <TabsContent key={category} value={category} className="mt-0">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {repos.map((repo, index) => (
                    <div key={index} className="p-4 bg-white rounded-md border border-gray-200 hover:shadow-md transition-shadow">
                      <h3 className="font-medium text-base mb-1">{repo.name}</h3>
                      <p className="text-sm text-gray-600 mb-2">{repo.description}</p>
                      <a 
                        href={repo.link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-sm text-blue-600 hover:underline inline-flex items-center"
                      >
                        <span>Visit Repository</span>
                        <ExternalLink className="w-3 h-3 ml-1" />
                      </a>
                    </div>
                  ))}
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </div>
        
        <div className="space-y-4">
          <div className="flex gap-4 mb-4">
            <FilterDropdown
              name="Category"
              options={filterOptions?.categories || []}
              value={categoryFilter}
              onChange={setCategoryFilter}
            />
            <FilterDropdown
              name="Source"
              options={filterOptions?.sources || []}
              value={sourceFilter}
              onChange={setSourceFilter}
            />
          </div>

          <div className="text-sm text-muted-foreground">
            Showing {((page - 1) * limit) + 1}-{Math.min(page * limit, data?.total || 0)} of {data?.total || 0} datasets
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Title</TableHead>
                <TableHead>Category</TableHead>
                <TableHead>Source</TableHead>
                <TableHead>Date Added</TableHead>
                <TableHead>Alchemy Score</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                // Show 15 skeleton rows while loading
                Array.from({ length: 15 }).map((_, index) => (
                  <TableRow key={`loading-${index}`}>
                    <TableCell><Skeleton className="h-4 w-[200px]" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-[80px]" /></TableCell>
                  </TableRow>
                ))
              ) : data?.datasets && data.datasets.length > 0 ? (
                data.datasets.map((dataset) => (
                  <TableRow key={dataset.id}>
                    <TableCell>{dataset.title}</TableCell>
                    <TableCell>{dataset.category}</TableCell>
                    <TableCell>{dataset.source}</TableCell>
                    <TableCell>
                      {new Date(dataset.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {dataset.alchemy_score ? 
                        `${Math.round(dataset.alchemy_score)}%` : 
                        'Not rated'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="text-center h-24">
                    No datasets available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div className="flex justify-center gap-2">
            <Button
              variant="outline"
              disabled={page === 1}
              onClick={() => handlePageChange(page - 1)}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              disabled={page * limit >= (data?.total || 0)}
              onClick={() => handlePageChange(page + 1)}
            >
              Next
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}
