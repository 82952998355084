import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useExperimentById, useUpdateExperiment } from "@/api/project/projects";
import { z } from "zod";
import { StatusEnum } from "@/types/project-model";
import { useForm } from "react-hook-form";
import { ExperimentDetailsUpdateDTO, GeneralInfo } from "@/types/study-model";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form";
import BackArrow from "@/components/shared/back-arrow";
import GeneralForm from "@/components/projects/experiment/general-form";
import DetailsForm from "@/components/projects/experiment/details-form";
import { Loader2 } from "lucide-react";
import SubmitButton from "@/components/shared/SubmitButton";

const initialGeneralInfo: GeneralInfo = {
  status: StatusEnum.NotStarted,
  description: "",
  title: "",
  is_public: false,
  collaborators: [],
};
const initialDetails: ExperimentDetailsUpdateDTO = {
  procedures: [],
  hypothesis: "",
  labels: [],
  categories: [],
  collaborators: [],
};

const ExperimentEdit = () => {
  const { projectId, studyId, experimentId } = useParams();

  const { data: experiment, isLoading } = useExperimentById(
    Number(experimentId)
  );

  const updateExperiment = useUpdateExperiment();

  const formSchema = z.object({
    title: z.string().min(1),
    description: z.string().min(1),
    status: z.nativeEnum(StatusEnum),
    is_public: z.boolean(),
    hypothesis: z.string().min(1),
    collaborators: z.number().array(),
    labels: z.number().array(),
    categories: z.number().array(),
    procedures: z
      .object({
        content: z.string(),
        position: z.number(),
      })
      .array(),
  });

  const form = useForm<GeneralInfo & ExperimentDetailsUpdateDTO>({
    resolver: zodResolver(formSchema),
    defaultValues: Object.assign(initialDetails, initialGeneralInfo),
  });

  useEffect(() => {
    if (experiment) {
      form.reset({
        ...experiment,
        collaborators: experiment.collaborators.map((el) => el.id),
        labels: experiment.labels.map((label) => label.id),
        categories: experiment.labels.map((label) => label.id),
      });
    }
  }, [experiment]);

  const onSubmit = (data) => {
    console.log(data);
    updateExperiment.mutate({
      ...data,
      study_id: Number(studyId),
      id: experimentId,
    });
  };
  if (isLoading)
    return (
      <Loader2
        className="relative top-36 m-auto h-20 w-20 animate-spin"
        color="black"
      />
    );

  return (
    <div className={"container mx-auto p-4"}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <BackArrow text={`projects/${projectId}/study/${studyId}`} />
          <div className={"flex justify-between mb-12"}>
            <h1 className="text-3xl font-bold">Edit Experiment</h1>
            <SubmitButton
              type={"submit"}
              isLoading={updateExperiment.isPending}
            >
              Save
            </SubmitButton>
          </div>

          <GeneralForm prefix={"Experiment"} form={form} />

          <div className={"mt-12"}>
            <DetailsForm form={form} />
          </div>
        </form>
      </Form>
    </div>
  );
};

export default ExperimentEdit;
