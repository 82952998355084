import { Check } from "lucide-react";

export const StepIndicator = ({ step, currentStep, label }) => (
  <div className={`flex flex-col items-center `}>
    <div
      className={`w-10 h-10 text-lg rounded-full border-2 flex items-center justify-center ${
        currentStep >= step
          ? "border-primary text-primary"
          : "border-muted-foreground text-muted-foreground"
      } ${currentStep > step && "bg-primary"}`}
    >
      {currentStep <= step ? step : <Check color="white" />}
    </div>
    <span className="mt-2 text-sm font-bold">{label}</span>
  </div>
);
