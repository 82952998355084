import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"

interface ClassificationOrganismProps {
  formData: {
    organism: string;
    otherOrganism: string;
    sampleDetails: string;
    [key: string]: any;
  };
  handleSelectChange: (name: string, value: string) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export function ClassificationOrganism({ formData, handleSelectChange, handleInputChange }: ClassificationOrganismProps) {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <Label htmlFor="organism">Organism</Label>
        <Select
          value={formData.organism}
          onValueChange={(value) => handleSelectChange('organism', value)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select organism" />
          </SelectTrigger>
          <SelectContent>
            {['Human', 'Mouse', 'Rat', 'Zebrafish', 'Fruit Fly', 'C. elegans', 'Other'].map((organism) => (
              <SelectItem key={organism} value={organism}>
                {organism}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      
      {formData.organism === 'Other' && (
        <div>
          <Label htmlFor="otherOrganism">Specify Other Organism</Label>
          <Input
            id="otherOrganism"
            name="otherOrganism"
            value={formData.otherOrganism}
            onChange={handleInputChange}
            placeholder="Enter organism name"
          />
        </div>
      )}
      
      <div>
        <Label htmlFor="sampleDetails">Sample Details</Label>
        <Textarea
          id="sampleDetails"
          name="sampleDetails"
          value={formData.sampleDetails}
          onChange={handleInputChange}
          placeholder="Enter sample details"
        />
      </div>
    </div>
  )
}
