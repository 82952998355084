import {Study, GeneralInfo} from "@/types/study-model";

export enum StatusEnum {
    NotStarted= "Not-Started",
    InProgress= "In-Progress",
    Done = "Done"
};
export interface ProjectModel extends GeneralInfo {
    comments: any[];
    hypothesis: string | null;
    id: number;
    owner_id: number;
    start_date: string;
    studies: Study[];
}

