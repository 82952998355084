export default function SearchTitle({ showResults }) {
  return !showResults ? (
    <div className="text-center mb-10">
      <h1 className="text-4xl font-extrabold text-primary mb-5">ALCHEMY</h1>
      <h2 className="flex items-center space-x-2 justify-center">
        <span className="font-medium text-3xl">
          Search all the world's biology data
        </span>
      </h2>
    </div>
  ) : (
    <h1 className="font-semibold text-xl pt-7 mb-6">Search results</h1>
  );
}
