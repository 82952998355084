import { useMetrics } from '@/api/metrics';
import { ArrowUp, ArrowDown } from 'lucide-react';
import { useState } from 'react';

interface SearchMetricsProps {
  datasetId: number;
}

interface VoteState {
  [key: string]: 'up' | 'down' | null;
}

export default function SearchMetrics({ datasetId }: SearchMetricsProps) {
  const { metrics, isLoading, voteMetric } = useMetrics(datasetId);
  const [votes, setVotes] = useState<VoteState>({});

  const handleVote = (metricType: string, metricField: string, direction: 'up' | 'down') => {
    const voteKey = `${metricType}_${metricField}`;
    
    // If clicking the same direction that's already voted, do nothing
    if (votes[voteKey] === direction) return;

    voteMetric.mutate({
      dataset_id: datasetId,
      metric_type: metricType,
      metric_field: metricField,
      vote_value: direction === 'up' ? 1 : -1
    }, {
      onSuccess: () => {
        // If clicking opposite direction, clear the vote, otherwise set it
        setVotes(prev => ({
          ...prev,
          [voteKey]: prev[voteKey] === direction ? null : direction
        }));
      }
    });
  };

  if (isLoading) {
    return <div className="p-4">Loading metrics...</div>;
  }

  return (
    <div className="p-4 space-y-6">
      {Object.entries(metrics).map(([metricType, fields]) => (
        <div key={metricType} className="space-y-2">
          <h3 className="font-medium text-lg capitalize">
            {metricType.split('_').join(' ')}
          </h3>
          <div className="space-y-2">
            {Object.entries(fields)
              .filter(([field]) => !field.toLowerCase().includes('dataset') && !field.toLowerCase().includes('id'))
              .map(([field, value]) => {
              const voteKey = `${metricType}_${field}`;
              return (
                <div key={field} className="flex items-center justify-between">
                  <div>
                    <span className="text-sm font-bold capitalize">
                      {field.split('_').join(' ')}
                    </span>
                    <span className="ml-2 text-sm text-gray-500">
                      {value !== null ? value : 'N/A'}
                    </span>
                  </div>
                  <div className="flex gap-1">
                    <button
                      onClick={() => handleVote(metricType, field, 'up')}
                      disabled={votes[voteKey] === 'up'}
                      className={`p-1 rounded ${
                        votes[voteKey] === 'up'
                          ? 'bg-green-200 cursor-not-allowed'
                          : 'bg-green-100 hover:bg-green-200'
                      }`}
                    >
                      <ArrowUp size={16} className={`${
                        votes[voteKey] === 'up' ? 'text-green-500' : 'text-green-700'
                      }`} />
                    </button>
                    <button
                      onClick={() => handleVote(metricType, field, 'down')}
                      disabled={votes[voteKey] === 'down'}
                      className={`p-1 rounded ${
                        votes[voteKey] === 'down'
                          ? 'bg-red-200 cursor-not-allowed'
                          : 'bg-red-100 hover:bg-red-200'
                      }`}
                    >
                      <ArrowDown size={16} className={`${
                        votes[voteKey] === 'down' ? 'text-red-500' : 'text-red-700'
                      }`} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}