import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList } from "@/components/ui/tabs";
import { ChevronRight, ChevronLeft, HelpCircle } from "lucide-react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  BasicInfo,
  basicInfoSchema,
  BasicInfoFormData,
} from "@/components/datasets/upload/basic-info";
import { BiologySelection } from "@/components/datasets/upload/biology-selection";
import { ClassificationOrganism } from "@/components/datasets/upload/classification-organism";
import { FileUploads } from "@/components/datasets/upload/file-uploads";
import { StepIndicator } from "@/components/datasets/upload/step-indicator";
import { Confirmation } from "@/components/datasets/upload/confirmation";
import { useAction } from "@/api/apiClient";

export default function DataUploadPage() {
  const [currentStep, setCurrentStep] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { action, isLoading, error } = useAction({
    url: "/datasets",
    success: "Dataset successfully created",
    redirectTo: "/datasets",
    error: "Failed to upload dataset",
  });

  const basicInfoForm = useForm<BasicInfoFormData>({
    resolver: zodResolver(basicInfoSchema),
    defaultValues: {
      datasetName: "",
      investigator: "",
      institution: "",
      description: "",
      email: "",
    },
  });

  const [formData, setFormData] = useState({
    datasetName: "",
    investigator: "",
    institution: "",
    description: "",
    email: "",
    category: "",
    subcategory: "",
    technique: "",
    organism: "",
    otherOrganism: "",
    sampleDetails: "",
    dataFiles: null as File | null,
    methodsFile: null as File | null,
    createDOI: false,
  });

  const handleSelectChange = (name: string, value: string) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (name: string, file: File | null) => {
    setFormData((prev) => ({ ...prev, [name]: file }));
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const nextStep = async () => {
    if (currentStep === 1) {
      const isValid = await basicInfoForm.trigger();
      if (!isValid) return;

      const basicInfo = basicInfoForm.getValues();
      setFormData((prev) => ({
        ...prev,
        datasetName: basicInfo.datasetName,
        investigator: basicInfo.investigator,
        institution: basicInfo.institution,
        description: basicInfo.description,
        email: basicInfo.email,
      }));
    }

    if (currentStep < 4) {
      setCurrentStep((prev) => prev + 1);
    } else {
      setShowConfirmation(true);
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const submitForm = async () => {
    const form = new FormData();

    form.append("title", formData.datasetName);
    form.append("investigator", formData.investigator);
    form.append("institution", formData.institution);
    form.append("description", formData.description);
    form.append("email", formData.email);

    if (formData.category) form.append("category", formData.category);
    if (formData.subcategory) form.append("subcategory", formData.subcategory);
    if (formData.technique) form.append("technique", formData.technique);

    if (formData.organism) form.append("organism", formData.organism);
    if (formData.otherOrganism)
      form.append("other_organism", formData.otherOrganism);
    if (formData.sampleDetails)
      form.append("sample_details", formData.sampleDetails);

    if (formData.dataFiles) {
      form.append("data_files", formData.dataFiles);
    }
    if (formData.methodsFile) {
      form.append("methods_file", formData.methodsFile);
    }

    form.append("createDOI", formData.createDOI.toString());

    action(form);
  };

  return (
    <div
      className="container py-8"
      style={{ minWidth: "400px", maxWidth: "600px" }}
    >
      {!showConfirmation && (
        <h1 className="text-3xl font-bold mb-12 text-center">
          Upload New Dataset
        </h1>
      )}

      {showConfirmation ? (
        <Confirmation
          formData={{ ...basicInfoForm, ...formData }}
          setShowConfirmation={setShowConfirmation}
          submitForm={submitForm}
          isLoading={isLoading}
        />
      ) : (
        <>
          <Tabs value={`step${currentStep}`}>
            <TabsList className="flex justify-between bg-transparent mb-8">
              <StepIndicator
                step={1}
                currentStep={currentStep}
                label="Basic Info"
              />
              <div className="h-1 w-10 border-t border-t-black -mt-5" />
              <StepIndicator
                step={2}
                currentStep={currentStep}
                label="Classification"
              />
              <div className="h-1 w-10 border-t border-t-black -mt-5" />
              <StepIndicator
                step={3}
                currentStep={currentStep}
                label="Organism & Sample"
              />
              <div className="h-1 w-10 border-t border-t-black -mt-5" />
              <StepIndicator
                step={4}
                currentStep={currentStep}
                label="File Upload"
              />
            </TabsList>

            <TabsContent value="step1">
              <BasicInfo form={basicInfoForm} />
            </TabsContent>

            <TabsContent value="step2">
              <BiologySelection
                formData={formData}
                handleSelectChange={handleSelectChange}
              />
            </TabsContent>

            <TabsContent value="step3">
              <ClassificationOrganism
                formData={formData}
                handleSelectChange={handleSelectChange}
                handleInputChange={(e) =>
                  handleSelectChange(e.target.name, e.target.value)
                }
              />
            </TabsContent>

            <TabsContent value="step4">
              <FileUploads
                formData={formData}
                handleFileChange={handleFileChange}
                handleCheckboxChange={handleCheckboxChange}
              />
            </TabsContent>
          </Tabs>

          <div className="mt-6 flex justify-between">
            <Button
              onClick={prevStep}
              disabled={currentStep === 1}
              variant="outline"
            >
              <ChevronLeft className="mr-2 h-4 w-4" /> Previous
            </Button>
            <Button onClick={nextStep}>
              {currentStep === 4 ? (
                <>
                  Confirm <ChevronRight className="ml-2 h-4 w-4" />
                </>
              ) : (
                <>
                  Next <ChevronRight className="ml-2 h-4 w-4" />
                </>
              )}
            </Button>
          </div>

          {/* TODO: add help link. Removed for feb 10 launch */}
          {/* <div className="mt-4 flex items-center justify-center">
            <HelpCircle className="mr-2 h-4 w-4 text-muted-foreground" />
            <span className="text-sm text-muted-foreground">
              Need help? Check our{" "}
              <a href="#" className="underline">
                documentation
              </a>{" "}
              or{" "}
              <a href="#" className="underline">
                contact support
              </a>
              .
            </span>
          </div> */}
        </>
      )}
    </div>
  );
}
