import { useForm } from 'react-hook-form';
import { useComments } from '@/api/comments/comments';

interface SearchCommentsProps {
  datasetId: number;
}

export default function SearchComments({ datasetId }: SearchCommentsProps) {
  const { comments, isLoading, addComment } = useComments(datasetId);
  const { register, handleSubmit, reset } = useForm<{ content: string }>();

  const onSubmit = async (data: { content: string }) => {
    addComment.mutate(
      { content: data.content, dataset_id: datasetId },
      { onSuccess: () => reset() }
    );
  };

  if (isLoading) {
    return <div className="p-4">Loading comments...</div>;
  }

  return (
    <div className="p-4">
      <div className="space-y-4">
        {comments.map((comment) => (
          <div key={comment.id} className="border-b pb-2">
            <p className="text-sm text-gray-600">{comment.user.name}</p>
            <p>{comment.content}</p>
            <p className="text-xs text-gray-400">
              {new Date(comment.created_at).toLocaleString()}
            </p>
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <textarea
          {...register('content', { required: true })}
          className="w-full border rounded p-2"
          placeholder="Add a comment..."
        />
        <button 
          type="submit" 
          disabled={addComment.isPending}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
        >
          {addComment.isPending ? 'Sending...' : 'Add Comment'}
        </button>
      </form>
    </div>
  );
}
