import { Button } from "@/components/ui/button"
import { Upload } from 'lucide-react'
import { Link } from "react-router-dom"

export function Header() {
  return (
    <header className="flex justify-between items-center mb-8">
      <h1 className="text-3xl font-bold">Dataset Analytics Dashboard</h1>
      <Link to="/datasets/upload">
        <Button className="bg-primary text-white px-4 py-2 hover:bg-primary/90 transition-colors">
          <Upload className="w-4 h-4 mr-2" />
          Upload New Dataset
        </Button>
      </Link>
    </header>
  )
}