import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "./apiClient";

interface MetricVote {
  dataset_id: number;
  metric_type: string;
  metric_field: string;
  vote_value: number;
}

interface MetricData {
  data_quality_metrics: Record<string, number>;
  method_validation: Record<string, number>;
  metadata_completeness: Record<string, number>;
  impact_metrics: Record<string, number>;
  data_format_standards: Record<string, number>;
  quality_control: Record<string, number>;
}

export const useMetrics = (datasetId: number | null) => {
  const queryClient = useQueryClient();

  const { data: metrics = {}, isLoading } = useQuery({
    queryKey: ['metrics', datasetId],
    queryFn: async () => {
      if (!datasetId) return {};
      const { data } = await apiClient.get<MetricData>(`/datasets/${datasetId}`);
      return {
        data_quality_metrics: data.dataQualityMetrics || {},
        method_validation: data.methodValidation || {},
        metadata_completeness: data.metadataCompleteness || {},
        impact_metrics: data.impactMetrics || {},
        data_format_standards: data.dataFormatStandards || {},
        quality_control: data.qualityControl || {}
      };
    },
    enabled: !!datasetId
  });

  const voteMetric = useMutation({
    mutationFn: async (vote: MetricVote) => {
      const { data } = await apiClient.post(`/datasets/${vote.dataset_id}/vote`, vote);
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['metrics', datasetId] });
    }
  });

  return {
    metrics,
    isLoading,
    voteMetric
  };
};