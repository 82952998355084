import Balancer from "react-wrap-balancer";

const ProblemCard = ({
  text,
  position,
  delay = 0,
  rotation
}: {
  text: string;
  position: { top: string; left: string };
  delay?: number;
  rotation: string;
}) => {
  return (
    <div
      className="absolute p-5 py-5 bg-white rounded-xl shadow-[0_8px_24px_rgba(255,77,77,0.12)] border border-[rgba(255,77,77,0.15)] text-lg text-[#ff4d4d] transition-all duration-300 cursor-default z-[2] transform-style-preserve-3d will-change-transform flex items-center gap-3 hover:scale-105 hover:translate-z-[20px] hover:shadow-[0_12px_32px_rgba(255,77,77,0.2)] hover:border-[#ff4d4d]"
      style={{
        top: position.top,
        left: position.left,
        "--rotation": rotation,
        animation: `float 12s ease-in-out infinite ${delay ? `-${delay}s` : ''}`
      } as React.CSSProperties}
    >
      {text}
    </div>
  );
};

const Problem = () => {
  const problemCards = [
    { text: "😫 Fragmented spreadsheets", position: { top: "15%", left: "20%" }, delay: 0, rotation: "-8deg" },
    { text: "😤 Inconsistent formats", position: { top: "75%", left: "60%" }, delay: 2.5, rotation: "12deg" },
    { text: "😩 Missing metadata", position: { top: "45%", left: "10%" }, delay: 5, rotation: "-15deg" },
    { text: "😰 Manual curation", position: { top: "85%", left: "25%" }, delay: 7.5, rotation: "10deg" },
    { text: "😵 Complex queries", position: { top: "30%", left: "80%" }, delay: 10, rotation: "-5deg" },
    { text: "😢 Data silos", position: { top: "65%", left: "40%" }, delay: 12.5, rotation: "7deg" },
  ];

  return (
    <section className="w-screen ml-[calc(-50vw+50%)] mr-[calc(-50vw+50%)] py-16">
      <div className="w-full max-w-[1200px] mx-auto px-[clamp(1.25rem,3vw,3rem)]">
        <div className="flex flex-col items-center gap-8 py-4 relative">
          <div className="text-center max-w-[800px]">
            <h2 className="text-[clamp(1.5rem,4vw,2.5rem)] leading-tight mb-8 font-semibold">
              Biological data is messy
            </h2>
            <Balancer>
              <p className="text-[clamp(1.25rem,2.5vw,1.5rem)] text-[#6B7280] leading-relaxed mx-auto">
                Exploring research shouldn't mean chasing spreadsheets and
                stitching formats.
              </p>
            </Balancer>
          </div>
          <div className="w-full max-w-[900px] min-h-[400px] bg-[rgba(255,227,227,0.5)] rounded-3xl overflow-hidden shadow-[0_8px_32px_rgba(255,77,77,0.08)] relative">
            <div className="absolute inset-0 w-full h-full perspective-[1000px]">
              {problemCards.map((card, index) => (
                <ProblemCard
                  key={index}
                  text={card.text}
                  position={card.position}
                  delay={card.delay}
                  rotation={card.rotation}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        @keyframes float {
          0% {
            transform: translateY(0px) rotate(var(--rotation));
          }
          50% {
            transform: translateY(-15px) rotate(var(--rotation));
          }
          100% {
            transform: translateY(0px) rotate(var(--rotation));
          }
        }
      `}</style>
    </section>
  );
};

export default Problem;
