import { Upload } from "lucide-react";
import { Label } from "@/components/ui/label";
import { useState } from "react";

interface FileInputProps {
  className?: string;
  title?: string;
  subTitle?: string;
  formData: any;
  name: string;
  handleFileChange: (name, files) => void;
  size?: number;
}

export default function FileInput({
  className,
  title,
  subTitle,
  formData,
  name,
  handleFileChange,
  size,
}: FileInputProps) {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileChange(name, files[0]);
    }
  };

  return (
    <div className={className}>
      {title && <Label htmlFor={name}>{title}</Label>}
      <label
        htmlFor={name}
        className={`
          cursor-pointer mt-2 flex justify-center px-6 pt-5 pb-6 
          border-2 border-dashed rounded-md transition-colors
          ${isDragging 
            ? 'border-secondary bg-blue-50' 
            : 'border-gray-300 hover:border-secondary'
          }
        `}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <div className="space-y-1 text-center">
          <Upload className="mx-auto mb-3 h-10 w-10" />
          <div className="flex text-sm">
            <span className=" bg-white rounded-md font-medium hover:text-primary-dark focus-within:outline-none focus-within:ring-0 focus-within:ring-offset-0">
              <span className="font-bold">{subTitle || "Upload file"}</span>
              <input
                id={name}
                name={name}
                type="file"
                className="sr-only"
                onChange={(e) =>
                  handleFileChange(
                    name,
                    e.target.files ? e.target.files[0] : null
                  )
                }
                multiple
              />
            </span>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs">
            {formData.dataFiles
              ? `Selected: ${formData.dataFiles.name}`
              : `Any file type up to ${size || 10}MB`}
          </p>
        </div>
      </label>
    </div>
  );
}
