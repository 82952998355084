import Header from "@/components/landing/header";
import Hero from "@/components/landing/hero";
import Features from "@/components/landing/features";
import CTA from "@/components/landing/cta";
import Footer from "@/components/landing/footer";
import SlackJoin from "@/components/SlackJoin";

// Mission section data
const missionData = [
  {
    id: 1,
    text: "Democratizing science",
    image: "/icons/globe.svg",
    description: "Making scientific data accessible to researchers worldwide",
  },
  {
    id: 2,
    text: "Data integration",
    image: "/icons/puzzle.svg",
    description: "Bridging gaps between different data formats and sources",
  },
  {
    id: 3,
    text: "AI innovation",
    image: "/icons/ai-chip.svg",
    description: "Leveraging cutting-edge AI to accelerate discoveries",
  },
  {
    id: 4,
    text: "Research impact",
    image: "/icons/chart-up.svg",
    description: "Amplifying the impact of scientific research globally",
  },
  {
    id: 5,
    text: "Community focus",
    image: "/icons/network.svg",
    description: "Building a collaborative research ecosystem",
  },
  {
    id: 6,
    text: "Future ready",
    image: "/icons/rocket.svg",
    description: "Preparing for the next generation of scientific challenges",
  },
];

// Open Science commitment data
const openScienceData = [
  {
    id: 1,
    text: "Open access",
    image: "/icons/open-book.svg",
    description: "Making research accessible to everyone, everywhere",
  },
  {
    id: 2,
    text: "Reproducibility",
    image: "/icons/clipboard-check.svg",
    description: "Ensuring research can be validated and built upon",
  },
  {
    id: 3,
    text: "Transparency",
    image: "/icons/eye.svg",
    description: "Clear documentation and methodologies",
  },
  {
    id: 4,
    text: "Collaboration",
    image: "/icons/handshake.svg",
    comingSoon: true,
    description: "Fostering global scientific partnerships",
  },
  {
    id: 5,
    text: "Data sharing",
    image: "/icons/share.svg",
    description: "Promoting FAIR data principles",
  },
  {
    id: 6,
    text: "Innovation",
    image: "/icons/lightbulb.svg",
    description: "Driving scientific progress through openness",
  },
];

const AboutUs = () => {
  return (
    <div className="app">
      {/* Hero section with modified content */}
      <Hero
        title="Transforming biological research"
        subtitle="We're on a mission to make scientific data more accessible, 
                 understandable, and actionable for researchers worldwide."
        showSearch={false}
      />

      {/* Mission section using Features component */}
      <Features
        title="Our Mission"
        subtitle="Empowering researchers with next-generation tools for biological data analysis"
        data={missionData}
      />

      {/* Open Science section using Features component */}
      <Features
        title="Our commitment to Open-science"
        subtitle="Building a more transparent and collaborative future for research"
        data={openScienceData}
      />

      {/* Join Us section using CTA component */}
      <CTA
        title="Join Our Slack Community"
        description="Connect with researchers, share insights, and be part of the open science movement."
        buttonText={<SlackJoin text="Join our Slack community" />}
        buttonLink=""
      />

      <Footer />
    </div>
  );
};

export default AboutUs;
