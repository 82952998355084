import { apiClient } from "@/api/apiClient";
import {
  dataType,
  species,
  suggestions,
  institutions,
  trendingDatasets,
} from "@/components/search/mockedData";
const getDataTypes = async () => {
  // TODO uncomment when API available
  // const response = await apiClient.get("/trending-datasets");
  const response = { data: dataType };
  return response.data;
};
const getSpecies = async () => {
  // TODO uncomment when API available
  // const response = await apiClient.get("/trending-datasets");
  const response = { data: species };
  return response.data;
};
const getSuggestions = async () => {
  // TODO uncomment when API available
  // const response = await apiClient.get("/trending-datasets");
  const response = { data: suggestions };
  return response.data;
};
const getInstitutions = async () => {
  // TODO uncomment when API available
  // const response = await apiClient.get("/trending-datasets");
  const response = { data: institutions };
  return response.data;
};
const getTrendingDatasets = async () => {
  // TODO uncomment when API available
  // const response = await apiClient.get("/trending-datasets");
  const response = { data: trendingDatasets };
  return response.data;
};

export const getSearchData = async () => {
  const dataType = await getDataTypes();
  const species = await getSpecies();
  const suggestions = await getSuggestions();
  const institutions = await getInstitutions();
  const trendingDatasets = await getTrendingDatasets();
  return {
    dataType,
    species,
    suggestions,
    institutions,
    trendingDatasets,
  };
};
