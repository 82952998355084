import { useLoaderData } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import PlaceholderComponent from "@/components/PlaceholderComponent";
import ProjectsList from "@/components/projects/ProjectsList";
import ProjectCreate from "@/components/projects/ProjectForm";

export default function ProjectsPage() {
  const projects = useLoaderData() as any;

  return (
    <div className="min-h-screen">
      <div className="flex justify-between mb-5">
        <h1 className="text-3xl font-bold tracking-tight">Projects</h1>
        <ProjectCreate>
          <Button>
            <Plus className="mr-2 h-4 w-4" /> New Project
          </Button>
        </ProjectCreate>
      </div>
      {projects.length ? (
        <ProjectsList projects={projects} />
      ) : (
        <PlaceholderComponent
          title="No projects"
          subTitle="Click the button below to add your first project"
        >
          <ProjectCreate>
            <Button>New Project</Button>
          </ProjectCreate>
        </PlaceholderComponent>
      )}
    </div>
  );
}
