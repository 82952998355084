import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRight, LogOut, User } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import AccountModal from "./AccountModal";
import { useClerk } from "@clerk/clerk-react";

export default function UserMenu({ user }) {
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);

  function logOut() {
    signOut()
  }

  const openAccountModal = () => {
    setIsAccountModalOpen(true);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="space-x-2 flex justify-between items-center overflow-hidden cursor-pointer">
            <Avatar>
              <AvatarImage src={user?.userImg} alt={user?.username} />
              <AvatarFallback>{user?.username?.substring(0, 1)}</AvatarFallback>
            </Avatar>
            <div className="w-[120px]">
              <div className="truncate w-full text-sm">{user?.username}</div>
              <div className="truncate w-full text-[10px] text-gray-400">
                {user?.email}
              </div>
            </div>
            <ChevronRight />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-48 [&_a]:text-black">
          <DropdownMenuLabel>My Account</DropdownMenuLabel>
          <DropdownMenuItem disabled className="block">
            <div className="truncate w-full text-sm">{user?.username}</div>
            <div className="truncate w-full text-[10px]">{user?.email}</div>
          </DropdownMenuItem>
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={openAccountModal}>
              <User className="mr-2 h-4 w-4" />
              Account
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuItem onClick={logOut}>
            <LogOut className="mr-2 h-4 w-4" />
            <span>Log out</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      
      <AccountModal 
        user={user} 
        isOpen={isAccountModalOpen} 
        onClose={() => setIsAccountModalOpen(false)} 
      />
    </>
  );
}
