import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { StatusEnum } from "@/types/project-model";
import { useForm } from "react-hook-form";
import { GeneralInfo } from "@/types/study-model";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form";
import BackArrow from "@/components/shared/back-arrow";
import GeneralForm from "@/components/projects/experiment/general-form";
import { useStudyById, useUpdateStudy } from "@/api/studies/studies";
import { Loader2 } from "lucide-react";
import SubmitButton from "@/components/shared/SubmitButton";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

const StudyEdit = () => {
  const { studyId, id } = useParams();

  const { data: study, isLoading, isError } = useStudyById(Number(studyId));

  const updateStudy = useUpdateStudy();

  const formSchema = z.object({
    title: z.string().min(1),
    description: z.string().min(1),
    status: z.nativeEnum(StatusEnum),
    is_public: z.boolean(),
    collaborators: z.number().array(),
  });

  const form = useForm<GeneralInfo>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      status: StatusEnum.NotStarted,
      collaborators: [],
      description: "",
      title: "",
      is_public: false,
    },
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (study) {
      form.reset({
        status: study.status,
        collaborators: study.collaborators.map((el) => el.id),
        description: study.description,
        title: study.title,
        is_public: study.is_public,
      });
    }
  }, [study]);

  const saveHandler = (data) => {
    updateStudy.mutate({
      ...data,
      id: studyId,
      project_id: id,
    });
  };

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
    window.history.back();
  };

  if (isLoading)
    return (
      <Loader2
        className="relative top-36 m-auto h-20 w-20 animate-spin"
        color="black"
      />
    );
  return (
    <div className="max-w-3xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(saveHandler)} className="space-y-8">
          <div className="flex justify-between items-center w-full mb-8">
            <h1 className="text-3xl font-semibold tracking-tight">
              Edit Study
            </h1>
            <Button type="submit" disabled={updateStudy.isPending}>
              {updateStudy.isPending ? "Saving..." : "Save"}
            </Button>
          </div>

          <Card className="p-8 w-full space-y-6">
            <GeneralForm form={form} prefix={"Study"} />
          </Card>
        </form>
      </Form>
    </div>
  );
};

export default StudyEdit;
