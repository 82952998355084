import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";

interface PlaceholderComponentProps {
    className?: string;
    title?: string;
    subTitle?: string;
    buttonText?: string;
    link?: string;
    onClick?: () => void;
    children?: any;
}

export default function PlaceholderComponent({
    className,
    title,
    subTitle,
    buttonText,
    link,
    onClick,
    children,
}: PlaceholderComponentProps) {

    // Conditionally render button based on props
    let buttonDisplay: JSX.Element;
    if (children) { // if rendering a pop-up
        buttonDisplay = children
    } else if (link) { // if rendering a separate page
        buttonDisplay = (
            <Button asChild>
                <Link to={link} className="hover:text-secondary">{buttonText}</Link>
            </Button>
        )
    } else { // if none of the above, render a button using the onClick prop function
        buttonDisplay = (
            <Button onClick={onClick}>{buttonText}</Button>
        )
    }
    
    return (
        <div
        className={cn(
            "flex flex-col items-center justify-center pt-10 text-center",
            className
        )}
        >
            <img
                src="/Illustrations.png"
                alt="Illustrations"
                className="max-w-full h-auto"
            />
            <div className="text-xl font-bold mt-4">{title}</div>
            <div className="mt-2 mb-4 max-w-64 text-center text-sm">{subTitle}</div>
            {buttonDisplay}
        </div>
    );
}