import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const basicInfoSchema = z.object({
  datasetName: z.string().min(1, "Dataset name is required"),
  investigator: z.string().min(1, "Principal Investigator is required"),
  institution: z.string().min(1, "Lab/Institution is required"),
  description: z
    .string()
    .min(10, "Description must be at least 10 characters long"),
  email: z.string().email("Invalid email address"),
});

export type BasicInfoFormData = z.infer<typeof basicInfoSchema>;

const ErrorMessage = ({ error }: { error: any }) => {
  if (!error) return null;
  return <p className="text-red-500">{error.message || "Invalid input"}</p>;
};

export function BasicInfo({
  form,
}: {
  form: UseFormReturn<BasicInfoFormData>;
}) {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div className="flex flex-col gap-4">
      <div>
        <Label htmlFor="datasetName">Dataset Name</Label>
        <Input
          id="datasetName"
          {...register("datasetName")}
          placeholder="Enter dataset name"
        />
        <ErrorMessage error={errors.datasetName} />
      </div>
      <div>
        <Label htmlFor="investigator">Principal Investigator</Label>
        <Input
          id="investigator"
          {...register("investigator")}
          placeholder="Enter investigator name"
        />
        <ErrorMessage error={errors.investigator} />
      </div>
      <div>
        <Label htmlFor="institution">Lab/Institution</Label>
        <Input
          id="institution"
          {...register("institution")}
          placeholder="Enter institution name"
        />
        <ErrorMessage error={errors.institution} />
      </div>
      <div>
        <Label htmlFor="description">Dataset Description</Label>
        <Textarea
          id="description"
          {...register("description")}
          placeholder="Enter dataset description"
        />
        <ErrorMessage error={errors.description} />
      </div>
      <div>
        <Label htmlFor="email">Contact Email</Label>
        <Input
          id="email"
          {...register("email")}
          placeholder="Enter contact email"
        />
        <ErrorMessage error={errors.email} />
      </div>
    </div>
  );
}
