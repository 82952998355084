import Hero from "@/components/landing/hero";
import Problem from "@/components/landing/problem";
import Features from "@/components/landing/features";
import Copilot from "@/components/landing/copilot";
import Benefits from "@/components/landing/benefits";
import Audience from "@/components/landing/audience";
import CTA from "@/components/landing/cta";
import Footer from "@/components/landing/footer";
import MailchimpForm from "./mailchimp-form";
import VideoDemo from "@/components/landing/video-demo";

const LandingPage = () => {
  return (
    <div className="flex min-h-screen w-full flex-col">
      <Hero />
      <VideoDemo />
      <Problem />
      <div className="w-full max-w-[600px] mx-auto -mt-4 mb-12">
        <MailchimpForm />
      </div>
      <Features />
      <Copilot />
      <Benefits />
      <Audience />
      <div className="w-full max-w-[600px] mx-auto my-12">
        <MailchimpForm />
      </div>
      <CTA />
      <Footer />
    </div>
  );
};

export default LandingPage;