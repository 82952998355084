import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 w-full py-3.5 z-50 transition-all duration-300 ${
        isScrolled
          ? "bg-white backdrop-blur border-b border-gray-200/50 shadow-sm"
          : "bg-transparent"
      }`}
    >
      <div className="container flex justify-between items-center w-full max-w-[1200px] mx-auto px-5 py-1.5">
        <Link to="/" className="flex items-center z-50">
          <img src="/assets/logo.svg" alt="Alchemy" />
        </Link>

        <div className="flex items-center gap-8">
          <nav
            className={`
            md:static md:flex md:items-center md:gap-8 md:opacity-100 md:visible md:translate-y-0
            ${
              isMobileMenuOpen
                ? "fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-90 max-w-[400px] bg-white backdrop-blur flex flex-col justify-center p-10 rounded-xl shadow-lg border border-gray-200 z-50 opacity-100 visible items-center gap-8"
                : "fixed opacity-0 invisible"
            }
          `}
          >
            <div
              className={`
              flex md:flex-row md:gap-8
              ${
                isMobileMenuOpen
                  ? "flex-col items-center w-full gap-6"
                  : "gap-8"
              }
            `}
            >
              <Link
                to="/about"
                className={`text-gray-800 hover:text-blue-500 transition-colors duration-200 font-medium text-base py-1 md:py-0 md:px-0 md:bg-transparent md:w-auto md:text-left md:hover:bg-transparent
                  ${
                    isMobileMenuOpen
                      ? "text-center py-3 px-6 w-full rounded-xl hover:bg-blue-50 text-lg"
                      : ""
                  }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                About
              </Link>
              <Link
                to="/how-it-works"
                className={`text-gray-800 hover:text-blue-500 transition-colors duration-200 font-medium text-base py-1 md:py-0 md:px-0 md:bg-transparent md:w-auto md:text-left md:hover:bg-transparent
                  ${
                    isMobileMenuOpen
                      ? "text-center py-3 px-6 w-full rounded-xl hover:bg-blue-50 text-lg"
                      : ""
                  }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                How It Works
              </Link>
            </div>

            <Link
              to="/login"
              className={`
                font-medium bg-blue-50 text-gray-800 border border-blue-100 transition-all duration-200 hover:bg-blue-100 hover:border-blue-200
                md:ml-0 md:mt-0 md:w-auto md:text-left md:py-2 md:px-5 md:rounded-lg
                ${
                  isMobileMenuOpen
                    ? "mt-6 w-full text-center py-3 px-6 rounded-xl text-lg"
                    : ""
                }
              `}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Sign In
            </Link>
          </nav>

          <Link
            to="/register"
            className="hidden md:block bg-gradient-to-r from-blue-400 to-blue-600 text-white py-3 px-6 rounded-lg font-medium cursor-pointer transition-all duration-200 relative overflow-hidden hover:-translate-y-1 hover:shadow-lg"
          >
            <span className="relative z-10">Try Copilot</span>
            <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white/20 to-transparent transition-all duration-500 opacity-0 hover:opacity-100"></span>
          </Link>

          <button
            className={`md:hidden bg-transparent border-none cursor-pointer p-2 ml-auto z-50 ${
              isMobileMenuOpen ? "open" : ""
            }`}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle mobile menu"
          >
            <span
              className={`block w-6 h-0.5 bg-gray-800 relative transition-all duration-300 ${
                isMobileMenuOpen ? "bg-transparent" : ""
              }`}
            >
              <span
                className={`absolute w-6 h-0.5 bg-gray-800 transition-all duration-300 ${
                  isMobileMenuOpen ? "rotate-45 top-0" : "top-[-8px]"
                }`}
              ></span>
              <span
                className={`absolute w-6 h-0.5 bg-gray-800 transition-all duration-300 ${
                  isMobileMenuOpen ? "-rotate-45 bottom-0" : "bottom-[-8px]"
                }`}
              ></span>
            </span>
          </button>
        </div>
      </div>

      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-40"
          onClick={() => setIsMobileMenuOpen(false)}
        ></div>
      )}
    </header>
  );
};

export default Header;
