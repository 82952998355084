import { motion, AnimatePresence } from 'framer-motion'
import { useState, useEffect, useCallback, useMemo, memo, ChangeEvent } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Loader2, Plus, Search, Share2, Download, Beaker } from "lucide-react";
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Slider } from "@/components/ui/slider"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { cn } from "@/lib/utils";
import { 
    LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, 
    Tooltip, ResponsiveContainer 
} from 'recharts';

// Add this mock data near the top of the file, before the components
const mockExperiments = [
  {
    id: 1,
    title: "Photosynthesis Rate in Varying Light Conditions",
    status: "in_progress",
    progress: 75,
    description: "Measuring photosynthesis rates under different light intensities",
    createdAt: "2024-01-20",
    updatedAt: "2024-01-20",
    dataPoints: [
      { lightIntensity: 100, primaryRate: 2.1, secondaryRate: 2.0 },
      { lightIntensity: 200, primaryRate: 3.5, secondaryRate: 3.4 },
      { lightIntensity: 300, primaryRate: 4.2, secondaryRate: 4.1 },
      { lightIntensity: 400, primaryRate: 4.8, secondaryRate: 4.7 },
      { lightIntensity: 500, primaryRate: 5.0, secondaryRate: 5.0 }
    ],
    timeline: [
      { type: 'start', label: 'Experiment Started', date: '2024-01-20' },
      { type: 'midpoint', label: 'Midpoint Check', date: '2024-01-25' },
      { type: 'end', label: 'Final Analysis', date: '2024-01-30' }
    ]
  },
  {
    id: 2,
    title: "Gene Expression Analysis",
    status: "completed",
    description: "Quantitative analysis of gene expression patterns",
    createdAt: "2024-01-15",
    updatedAt: "2024-01-15",
    dataPoints: [
      { time: 0, geneA: 1.0, geneB: 0.5, geneC: 0.2 },
      { time: 6, geneA: 1.5, geneB: 0.8, geneC: 0.4 },
      { time: 12, geneA: 2.2, geneB: 1.2, geneC: 0.7 },
      { time: 18, geneA: 2.8, geneB: 1.7, geneC: 1.1 },
      { time: 24, geneA: 3.5, geneB: 2.3, geneC: 1.6 },
    ],
    timeline: [
        { type: 'start', label: 'Experiment Started', date: '2024-01-20' },
        { type: 'midpoint', label: 'Midpoint Check', date: '2024-01-25' },
        { type: 'end', label: 'Final Analysis', date: '2024-01-30' }
      ]
  },
  {
    id: 3,
    title: "Protein Folding Dynamics",
    status: "planned",
    description: "Investigation of protein folding mechanisms",
    createdAt: "2024-01-22",
    updatedAt: "2024-01-22",
    dataPoints: [],
    timeline: [
        { type: 'start', label: 'Experiment Started', date: '2024-01-20' },
        { type: 'midpoint', label: 'Midpoint Check', date: '2024-01-25' },
        { type: 'end', label: 'Final Analysis', date: '2024-01-30' }
      ]
  },
]

// Update interfaces
interface Experiment {
    id: number;
    title: string;
    description?: string;
    status: "planned" | "in_progress" | "completed";
    progress: number;
    createdAt: string;
    updatedAt: string;
    dataPoints: DataPoint[];
    timeline: TimelineEvent[];
}

interface DataPoint {
    lightIntensity: number;
    primaryRate: number;
    secondaryRate: number;
}

interface TimelineEvent {
    type: 'start' | 'midpoint' | 'end';
    label: string;
    date: string;
}

interface ExperimentDetailProps {
    experiment: Experiment;
    timePoint: number;
    setTimePoint: (value: number) => void;
    notes: {
        hypothesis: string;
        observations: string;
        conclusions: string;
    };
    setNotes: (notes: {
        hypothesis: string;
        observations: string;
        conclusions: string;
    }) => void;
}

interface ExperimentCardProps {
    experiment: Experiment;
    onClick: () => void;
}

interface ExperimentGridProps {
    experiments: Experiment[];
    onExperimentSelect: (experiment: Experiment) => void;
}

// Add this component before StudyGet
const ExperimentCard = memo(({ 
    experiment, 
    onClick 
}: ExperimentCardProps) => {
    return (
        <Card 
            className="w-[300px] cursor-pointer hover:shadow-lg transition-shadow"
            onClick={onClick}
        >
            <CardHeader>
                <CardTitle className="flex items-center gap-2">
                    <Beaker className="h-5 w-5 text-muted-foreground" />
                    {experiment.title}
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="space-y-4">
                    {/* Status indicator */}
                    <div className="flex items-center gap-2">
                        <div 
                            className={cn(
                                "h-2 w-2 rounded-full",
                                {
                                    "bg-green-500": experiment.status === "completed",
                                    "bg-yellow-500": experiment.status === "in_progress",
                                    "bg-gray-500": experiment.status === "planned"
                                }
                            )}
                        />
                        <span className="text-sm text-muted-foreground capitalize">
                            {experiment.status?.replace("_", " ")}
                        </span>
                    </div>

                    {/* Description */}
                    {experiment.description && (
                        <p className="text-sm text-muted-foreground line-clamp-2">
                            {experiment.description}
                        </p>
                    )}

                    {/* Metadata */}
                    <div className="grid grid-cols-2 gap-2 text-sm">
                        <div>
                            <p className="text-muted-foreground">Created</p>
                            <p>{new Date(experiment.createdAt).toLocaleDateString()}</p>
                        </div>
                        <div>
                            <p className="text-muted-foreground">Last Updated</p>
                            <p>{new Date(experiment.updatedAt).toLocaleDateString()}</p>
                        </div>
                    </div>

                    {/* Data points indicator */}
                    {experiment.dataPoints && (
                        <div className="text-sm">
                            <span className="text-muted-foreground">Data points: </span>
                            <span>{experiment.dataPoints.length}</span>
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
});

// Update the mock study data to include experiments
const mockStudy = {
    id: 1,
    title: "Biochemistry Research Study",
    description: "Investigation of various biochemical processes",
    experiments: mockExperiments  // Using the existing mockExperiments
}

const StudyGet = () => {
    // 1. All hooks first
    const { studyId, projectId } = useParams()
    const navigate = useNavigate()

    // Replace the actual API data with mock data for testing
    const mockProject = {
        id: Number(projectId),
        title: "Research Project",
        studies: [{
            id: Number(studyId),
            title: "Biochemistry Research Study",
            description: "Investigation of various biochemical processes",
            experiments: mockExperiments
        }]
    }

    // Comment out the actual API call and use mock data instead
    // const { data: project, isLoading, isError } = useProjectById(Number(projectId))
    const project = mockProject
    const isLoading = false
    const isError = false

    const [selectedExperiment, setSelectedExperiment] = useState<Experiment | null>(null)
    const [timePoint, setTimePoint] = useState(0)
    const [searchTerm, setSearchTerm] = useState("")
    const [notes, setNotes] = useState({
        hypothesis: "",
        observations: "",
        conclusions: ""
    });

    // 2. useEffect hooks
    useEffect(() => {
        if (project?.studies) {
            const currentStudy = project.studies.find(val => val.id === Number(studyId))
            if (currentStudy?.experiments?.length > 0 && !selectedExperiment) {
                setSelectedExperiment(currentStudy.experiments[0])
            }
        }
    }, [project, studyId, selectedExperiment])

    // Add animation interval effect
    useEffect(() => {
        if (!selectedExperiment?.dataPoints?.length) return;

        const interval = setInterval(() => {
            setTimePoint(prevTime => 
                prevTime < (selectedExperiment.dataPoints.length - 1) * 10 ? prevTime + 10 : 0
            )
        }, 1000)
        
        return () => clearInterval(interval)
    }, [selectedExperiment])

    // 3. Memoized handlers
    const newExperimentHandler = useCallback(() => {
        navigate('experiment/create')
    }, [navigate])

    const handleExperimentSelect = useCallback((experiment: Experiment) => {
        setSelectedExperiment(experiment)
        setTimePoint(0)
    }, [])

    const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }, [])

    // 4. Early returns for loading states
    if (isLoading) {
        return <Loader2 className="relative top-36 m-auto h-20 w-20 animate-spin" color="black" />
    }

    if (isError) {
        return <>something went wrong</>
    }

    const study = project.studies.find(val => val.id === Number(studyId))
    if (!study) {
        return <>Study not found</>
    }

    // 5. Memoized filtered experiments
    const filteredExperiments = useMemo(() => {
        return study.experiments.filter(exp => 
            exp.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
    }, [study.experiments, searchTerm])

    return (
        <div className="flex h-screen overflow-hidden">
            {/* Left Sidebar */}
            <div className="w-[300px] min-w-[300px] bg-white dark:bg-gray-800 p-4 border-r overflow-y-auto">
                <Button 
                    onClick={newExperimentHandler}
                    className="w-full my-4"
                >
                    <Plus className="mr-2"/>
                    New Experiment
                </Button>
                <div className="relative mb-4">
                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                        placeholder="Search experiments..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="pl-8"
                    />
                </div>
                <ScrollArea className="h-[calc(100vh-200px)]">
                    <div className="space-y-2">
                        {filteredExperiments.map((experiment) => (
                            <Button
                                key={experiment.id}
                                variant={selectedExperiment?.id === experiment.id ? "default" : "outline"}
                                className={cn(
                                    "w-full justify-start",
                                    selectedExperiment?.id === experiment.id 
                                        ? "bg-blue-100 hover:bg-blue-200 text-black hover:text-black" 
                                        : "hover:bg-blue-50"
                                )}
                                onClick={() => handleExperimentSelect(experiment)}
                            >
                                <div className="flex flex-col items-start">
                                    <span className="font-medium">{experiment.title}</span>
                                    <span className="text-xs text-muted-foreground capitalize">
                                        {experiment.status.replace("_", " ")}
                                    </span>
                                </div>
                            </Button>
                        ))}
                    </div>
                </ScrollArea>
            </div>

            {/* Main Content */}
            <div className="flex-1 p-8 overflow-y-auto">
                <div className="max-w-[calc(100vw-300px)] mx-auto">
                    <div className="mb-6">
                        <h1 className="text-3xl font-bold">{study.title}</h1>
                        <p className="text-muted-foreground mt-2">{study.description}</p>
                    </div>

                    {selectedExperiment ? (
                        <ExperimentDetail 
                            experiment={selectedExperiment}
                            timePoint={timePoint}
                            setTimePoint={setTimePoint}
                            notes={notes}
                            setNotes={setNotes}
                        />
                    ) : (
                        <ExperimentGrid 
                            experiments={study.experiments}
                            onExperimentSelect={handleExperimentSelect}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

// Separate components for better organization and performance
const ExperimentDetail = memo(({ 
    experiment, 
    timePoint, 
    setTimePoint, 
    notes, 
    setNotes 
}: ExperimentDetailProps) => {
    return (
        <Tabs defaultValue="visualization" className="w-full">
            <TabsList className="grid w-full grid-cols-4">
                <TabsTrigger value="visualization">Visualization</TabsTrigger>
                <TabsTrigger value="data">Raw Data</TabsTrigger>
                <TabsTrigger value="analysis">Analysis</TabsTrigger>
                <TabsTrigger value="notes">Notes</TabsTrigger>
            </TabsList>

            <TabsContent value="visualization" className="mt-4">
                <Card className="w-full">
                    <CardHeader>
                        <div className="flex justify-between items-center">
                            <CardTitle>{experiment.title}</CardTitle>
                            <div className="text-sm text-muted-foreground">
                                Status: Active | Progress: {experiment.progress}% | 
                                Last Updated: {new Date(experiment.updatedAt).toLocaleDateString()}
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent className="space-y-8">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            {/* Primary Metric Chart */}
                            <Card>
                                <CardHeader>
                                    <CardTitle className="text-lg">Primary Metric</CardTitle>
                                </CardHeader>
                                <CardContent className="h-[300px]">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart 
                                            data={experiment.dataPoints}
                                            margin={{ top: 10, right: 30, left: 20, bottom: 30 }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis 
                                                dataKey="lightIntensity" 
                                                label={{ 
                                                    value: 'Light Intensity', 
                                                    position: 'bottom',
                                                    offset: 0
                                                }} 
                                            />
                                            <YAxis 
                                                label={{ 
                                                    value: 'Primary Rate', 
                                                    angle: -90, 
                                                    position: 'insideLeft',
                                                    offset: -5
                                                }}
                                            />
                                            <Tooltip />
                                            <Line 
                                                type="monotone" 
                                                dataKey="primaryRate" 
                                                stroke="#8884d8" 
                                                dot={{ r: 4 }} 
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </CardContent>
                            </Card>

                            {/* Secondary Metric Chart */}
                            <Card>
                                <CardHeader>
                                    <CardTitle className="text-lg">Secondary Metric</CardTitle>
                                </CardHeader>
                                <CardContent className="h-[300px]">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart 
                                            data={experiment.dataPoints}
                                            margin={{ top: 10, right: 30, left: 20, bottom: 30 }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis 
                                                dataKey="lightIntensity" 
                                                label={{ 
                                                    value: 'Light Intensity', 
                                                    position: 'bottom',
                                                    offset: 0
                                                }} 
                                            />
                                            <YAxis 
                                                label={{ 
                                                    value: 'Secondary Rate', 
                                                    angle: -90, 
                                                    position: 'insideLeft',
                                                    offset: -5
                                                }}
                                            />
                                            <Tooltip />
                                            <Bar 
                                                dataKey="secondaryRate" 
                                                fill="#8884d8" 
                                            />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </CardContent>
                            </Card>
                        </div>

                        {/* Timeline Card */}
                        <Card>
                            <CardHeader>
                                <CardTitle className="text-lg">Experiment Timeline</CardTitle>
                            </CardHeader>
                            <CardContent className="py-8 px-12">
                                <div className="relative">
                                    {/* Timeline line */}
                                    <div className="absolute left-0 right-0 top-[6px] h-[2px] bg-gray-200" />
                                    
                                    {/* Timeline points and labels */}
                                    <div className="flex justify-between relative">
                                        {experiment.timeline.map((event, index) => (
                                            <div key={index} className="flex flex-col items-center">
                                                <div 
                                                    className={cn(
                                                        "w-3 h-3 rounded-full mb-4",
                                                        {
                                                            'bg-green-500': event.type === 'start',
                                                            'bg-orange-500': event.type === 'midpoint',
                                                            'bg-red-500': event.type === 'end'
                                                        }
                                                    )} 
                                                />
                                                <span className="text-sm font-medium text-gray-900 whitespace-nowrap">
                                                    {event.label}
                                                </span>
                                                <span className="text-xs text-gray-500 mt-1">
                                                    {new Date(event.date).toLocaleDateString()}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </TabsContent>

            <TabsContent value="data">
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between">
                        <CardTitle>Raw Data</CardTitle>
                        <div className="flex gap-2">
                            <Button variant="outline" size="sm">
                                <Download className="mr-2 h-4 w-4" />
                                Export CSV
                            </Button>
                            <Button variant="outline" size="sm">
                                <Share2 className="mr-2 h-4 w-4" />
                                Share Data
                            </Button>
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className="rounded-md border">
                            <table className="w-full">
                                <thead>
                                    <tr className="border-b bg-muted/50">
                                        <th className="p-4 text-left font-medium">lightIntensity</th>
                                        <th className="p-4 text-left font-medium">photosynthesisRate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {experiment.dataPoints.map((point, index) => (
                                        <tr 
                                            key={index} 
                                            className={cn(
                                                "border-b",
                                                index === experiment.dataPoints.length - 1 ? "last:border-0" : ""
                                            )}
                                        >
                                            <td className="p-4">{point.lightIntensity}</td>
                                            <td className="p-4">{point.primaryRate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </CardContent>
                </Card>
            </TabsContent>

            <TabsContent value="analysis">
                <div className="grid grid-cols-2 gap-4">
                    <Card>
                        <CardHeader>
                            <CardTitle>Statistical Summary</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-2">
                                <div className="flex justify-between items-center">
                                    <span>Mean:</span>
                                    <span className="font-medium">3.94</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span>Median:</span>
                                    <span className="font-medium">4.2</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span>Standard Deviation:</span>
                                    <span className="font-medium">1.15</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span>Variance:</span>
                                    <span className="font-medium">1.32</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader>
                            <CardTitle>Correlation Analysis</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-2">
                                <div className="flex justify-between items-center">
                                    <span>Pearson Correlation:</span>
                                    <span className="font-medium">0.97</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span>P-value:</span>
                                    <span className="font-medium">0.005</span>
                                </div>
                                <p className="text-sm text-muted-foreground mt-4">
                                    Strong positive correlation between light intensity and
                                    photosynthesis rate.
                                </p>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </TabsContent>

            <TabsContent value="notes">
                <Card>
                    <CardHeader>
                        <CardTitle>Experiment Notes</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-6">
                            {/* Hypothesis Section */}
                            <div className="space-y-2">
                                <Label htmlFor="hypothesis" className="text-base font-medium">
                                    Hypothesis
                                </Label>
                                <Input 
                                    id="hypothesis"
                                    value={notes.hypothesis}
                                    onChange={(e) => setNotes({...notes, hypothesis: e.target.value})}
                                    placeholder="Increasing light intensity will lead to higher photosynthesis rates up to a saturation point."
                                    className="w-full"
                                />
                            </div>

                            {/* Observations Section */}
                            <div className="space-y-2">
                                <Label htmlFor="observations" className="text-base font-medium">
                                    Observations
                                </Label>
                                <textarea
                                    id="observations"
                                    value={notes.observations}
                                    onChange={(e) => setNotes({...notes, observations: e.target.value})}
                                    placeholder="Enter your observations here..."
                                    className="w-full min-h-[100px] p-3 rounded-md border border-input bg-transparent text-sm resize-none focus:outline-none focus:ring-2 focus:ring-ring"
                                />
                            </div>

                            {/* Conclusions Section */}
                            <div className="space-y-2">
                                <Label htmlFor="conclusions" className="text-base font-medium">
                                    Conclusions
                                </Label>
                                <textarea
                                    id="conclusions"
                                    value={notes.conclusions}
                                    onChange={(e) => setNotes({...notes, conclusions: e.target.value})}
                                    placeholder="Enter your conclusions here..."
                                    className="w-full min-h-[100px] p-3 rounded-md border border-input bg-transparent text-sm resize-none focus:outline-none focus:ring-2 focus:ring-ring"
                                />
                            </div>

                            {/* Attachments Section */}
                            <div className="space-y-2">
                                <Label className="text-base font-medium">
                                    Attachments
                                </Label>
                                <div className="flex items-center gap-4">
                                    <Button variant="outline" size="sm">
                                        <Plus className="h-4 w-4 mr-2" />
                                        Add File
                                    </Button>
                                    <span className="text-sm text-muted-foreground">
                                        protocol.pdf, results.xlsx
                                    </span>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </TabsContent>
        </Tabs>
    );
});

const ExperimentGrid = memo(({ 
    experiments, 
    onExperimentSelect 
}: ExperimentGridProps) => {
    return (
        <div className="flex flex-wrap gap-12">
            {experiments.map((experiment) => (
                <ExperimentCard 
                    key={experiment.id} 
                    experiment={experiment}
                    onClick={() => onExperimentSelect(experiment)}
                />
            ))}
        </div>
    )
})

export default StudyGet;