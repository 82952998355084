import { useState, useCallback, useEffect, memo } from "react";
import {
  Outlet,
  Navigate,
  useNavigation,
  useLocation,
} from "react-router-dom";
import SideBar from "@/components/SideBar";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import Loader from "@/components/Loader";
import FeedbackButton from "@/components/FeedbackButton";
import { FeedbackProvider } from "@/context/FeedbackContext";
import { useUser } from "@clerk/clerk-react";

const SIDEBAR_WIDTH = "13rem";
const MemoizedSideBar = memo(SideBar);

const MainContent = memo(function MainContent({
  isNavigating,
  closeSidebar,
  showTrigger,
}: {
  isNavigating: boolean;
  closeSidebar: () => void;
  showTrigger: boolean;
}) {
  return (
    <>
      {showTrigger && <SidebarTrigger className="sticky left-0 top-0" />}
      <main className="flex-1 h-screen relative">
        {isNavigating ? (
          <Loader variant="fullScreen" />
        ) : (
          <Outlet context={[closeSidebar]} />
        )}
      </main>
    </>
  );
});

export default function AuthenticatedLayout() {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const navigation = useNavigation();
  const isNavigating = navigation.state === "loading";
  const { isLoaded, isSignedIn, user } = useUser();

  const closeSidebar = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSidebarHover = useCallback(() => {
    if (!open) {
      setOpen(true);
    }
  }, [open]);

  if (!isLoaded) {
    return <Loader variant="fullScreen" />;
  }

  if (!isSignedIn) {
    return <Navigate to="/login" />;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <FeedbackProvider>
      <div className="flex flex-col min-h-screen">
        <SidebarProvider
          style={{ "--sidebar-width": SIDEBAR_WIDTH } as React.CSSProperties}
          defaultOpen={false}
          open={open}
          onOpenChange={setOpen}
          className="[&_[data-sidebar=sidebar]]:bg-white"
        >
          <div className="flex flex-1 overflow-auto">
            <div onMouseEnter={handleSidebarHover}>
              <MemoizedSideBar user={user} />
            </div>
            <MainContent
              isNavigating={isNavigating}
              closeSidebar={closeSidebar}
              showTrigger={open}
            />
          </div>
        </SidebarProvider>
        <FeedbackButton />
      </div>
    </FeedbackProvider>
  );
}
