import { DashboardHeader } from "@/components/bdsi/dashboard-header"
import { OverallScoreCard } from "@/components/bdsi/overall-score-card"
import { ScoreBreakdown } from "@/components/bdsi/score-breakdown"
import { DatasetPerformanceTable } from "@/components/bdsi/dataset-performance-table"
import { NotificationsArea } from "@/components/bdsi/notifications-area"
import { HistoricalTrends } from "@/components/bdsi/historical-trends"

export default function BDSIPage() {
  return (
    <div className="container mx-auto p-4 space-y-6">
      <DashboardHeader />
      <div className="grid gap-6 md:grid-cols-3">
        <div className="md:col-span-2">
          <OverallScoreCard />
        </div>
        <div>
          <NotificationsArea />
        </div>
      </div>
      <ScoreBreakdown />
      <HistoricalTrends />
      <DatasetPerformanceTable />
    </div>
  )
}