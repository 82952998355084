import { useLoaderData, Link } from "react-router-dom";
import { Plus, Calendar, Users, Lock } from "lucide-react";
import { Button } from "@/components/ui/button";
import StudyTable from "@/components/projects/study/study-table";
import { Badge } from "@/components/ui/badge";
import { statusColors } from "@/components/projects/ProjectsList";

export default function ProjectGet() {
  const project = useLoaderData() as any;

  return (
    <div className="container mx-auto p-4 space-y-4">
      <header className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">{project.title}</h1>
        <div className="flex gap-3">
          <Link to="study/create">
            <Button>
              <Plus className="w-4 h-4 mr-2" />
              Add Study
            </Button>
          </Link>
        </div>
      </header>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
        <div className="p-4 border rounded-lg">
          <p className="text-sm text-muted-foreground">Status</p>
          <Badge
            variant="secondary"
            className={`${statusColors[project.status]} mt-1`}
          >
            {project.status}
          </Badge>
        </div>
        <div className="p-4 border rounded-lg">
          <p className="text-sm text-muted-foreground">Visibility</p>
          <div className="flex items-center gap-2 mt-1">
            <Lock className="h-4 w-4" />
            <span>{project.is_public ? "Public" : "Private"}</span>
          </div>
        </div>
        <div className="p-4 border rounded-lg">
          <p className="text-sm text-muted-foreground">Created</p>
          <div className="flex items-center gap-2 mt-1">
            <Calendar className="h-4 w-4" />
            <span>{new Date(project.created_at).toLocaleDateString()}</span>
          </div>
        </div>
        <div className="p-4 border rounded-lg">
          <p className="text-sm text-muted-foreground">Collaborators</p>
          <div className="flex items-center gap-2 mt-1">
            <Users className="h-4 w-4" />
            <span>{project.collaborators.length}</span>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2">Description</h2>
        <p className="text-muted-foreground">{project.description}</p>
      </div>

      {project.studies.length === 0 ? (
        <div className="rounded-md border border-dashed p-8 text-center mt-6">
          <h3 className="text-lg font-semibold">No Studies Yet</h3>
          <p className="text-sm text-muted-foreground mt-1">
            Add your first study to get started with your research
          </p>
          <Link to="study/create" className="mt-4 inline-block">
            <Button variant="outline" className="mt-2">
              <Plus className="w-4 h-4 mr-2" />
              Add Your First Study
            </Button>
          </Link>
        </div>
      ) : (
        <StudyTable />
      )}
    </div>
  );
}
