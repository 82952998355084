import { useEffect, useMemo } from "react"
import { Outlet, useLocation } from "react-router-dom"

import Footer from "@/components/landing/footer"
import Header from "@/components/landing/header"

export default function LandingLayout() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Memoize the Outlet to prevent unnecessary rerenders
  const memoizedOutlet = useMemo(() => <Outlet />, []);

  return (
    <div className="min-h-screen bg-background text-foreground font-sans">
      <Header />
      {memoizedOutlet}
    </div>
  )
}