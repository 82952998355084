import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useLoginUser } from "@/api/user/loginUser";
import ForgotPassword from "@/components/ForgotPassword";
import SubmitButton from "@/components/shared/SubmitButton";
import SlackJoin from "@/components/SlackJoin";
import { SignIn } from "@clerk/clerk-react";

import { getCurrentUser } from "@/api/user";

export default function Login() {
  const navigate = useNavigate();

  return (
    <div className="h-[100vh] grid justify-center content-center">
      <div className="w-full min-w-[450px] max-w-[550px]">
        <SignIn 
          routing="hash" 
          signUpUrl="/register"
          redirectUrl="/home"
          appearance={{
            layout: {
              logoPlacement: "inside",
              logoImageUrl: "/assets/logo.svg",
              socialButtonsVariant: "iconButton",
              logoImageSize: "128px",
            },
            elements: {
              rootBox: "w-full",
              card: "shadow-none",
              header: "gap-y-2",
              headerTitle: "text-2xl",
              headerSubtitle: "text-gray-500",
              formButtonPrimary: "h-14 text-base font-medium mt-4 mb-4 bg-primary hover:bg-primary/90",
              socialButtonsBlockButton: "border border-gray-300 hover:bg-gray-50",
              formFieldLabel: "text-base font-medium",
              formFieldInput: "h-12 text-base rounded-md border border-gray-300",
              footerAction: "text-sm text-gray-600 mt-4",
              footerActionLink: "text-primary hover:text-primary/90 font-medium",
            },
            variables: {
              headerTitle: "Login",
              headerSubtitle: "Sign in to your account to continue",
              footerActionText: "Don't have an account?",
              footerActionLinkText: "Sign up",
            }
          }}
        />
      </div>
    </div>
  );
}
