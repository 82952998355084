import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import Loader from "@/components/Loader";
import { useStream } from "@/api/apiClient";
import { StreamStepsEnum } from "@/components/copilot/SearchCopilot";

export function RenderChart({ plotData }) {
  return (
    <Plot
      data={plotData.data}
      layout={plotData.layout}
      style={{ width: "100%", height: "400px" }}
    />
  );
}

export default function CopilotAnalysis({ sessionId, query, datasetIds }) {
  const [chart, setChart] = useState(null);
  const streamData = useStream(); // Get the streamData function from the hook
  
  useEffect(() => {
    if (datasetIds && datasetIds.length > 0) {
      streamData({
        url: "/copilot/send",
        data: {
          session_id: sessionId,
          query: query,
          step: StreamStepsEnum.analyze,
          dataset_ids: datasetIds,
        },
        callBack: (message) => {
          const chart = JSON.parse(message);
          setChart(chart?.plot_specs);
        },
      });
    }
  }, [sessionId, query, datasetIds, streamData]);

  return (
    <div>
      {chart ? (
        <RenderChart plotData={chart} />
      ) : (
        <Loader color="gray-300" />
      )}
    </div>
  );
}
