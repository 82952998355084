"use client"

import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Tooltip, ResponsiveContainer } from "recharts"
import { Dna, Microscope, FileSpreadsheet, Info, AlertCircle, Star, ChevronDown, ChevronUp } from "lucide-react"
import { useParams } from "react-router-dom"

import { Card, CardContent } from "@/components/ui/card"
import { Progress } from "@/components/ui/progress"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"

import { apiClient } from "@/api/apiClient"

type FileMetrics = {
  total_cells: number
  total_genes: number
  median_genes_per_cell: number
  median_counts_per_cell: number
  percent_mito: number
  percent_ribo: number
  mean_reads_per_cell: number
}

type QualityChecks = {
  qc_metrics: {
    pct_counts_mt_scaled: number
    pct_counts_ribo_scaled: number
    n_genes_by_counts_scaled: number
    total_counts_scaled: number
    shannon_entropy_scaled: number
    complexity_scaled: number
    scaled_gini_coefficient_scaled: number
    sig2noise_ratio_scaled: number
    total_umi_scaled: number
    genes_detected_scaled: number
    dropout_rate_scaled: number
    doublet_score_scaled: number
  }
  thresholds: {
    min_genes_per_cell: number
    max_genes_per_cell: number
    min_counts_per_cell: number
    max_counts_per_cell: number
    max_mito_pct: number
  }
}

type DatasetMetrics = {
  dataset_id: number
  alchemy_score: number
  completeness_score: number
  consistency_score: number
  accuracy_score: number
  reproducibility_score: number
  file_level_metrics: FileMetrics
  validation_results: {
    passed_validation: boolean
    validation_date: string
    validation_criteria: Record<string, any>
  }
  quality_checks: QualityChecks
  processing_history: Array<{
    processor: string
    version: string
    timestamp: string
    status: string
    parameters: Record<string, any>
  }>
  last_validated: string
  created_at: string
  updated_at: string
}

type Dataset = {
  id: number
  title: string
  publicationDate: string | null
  experimentId: number | null
  qualityControlled: boolean
  peerReviewed: boolean
  processingState: string | null
  citationCount: number
  source: string
  userId: number
  category: string
  subcategory: string
  technique: string
  s3Key: string
  labels: string[]
  categories: string[]
  presignedUrl: string | null
  organism: string
  recordMetadata: Record<string, any>
  attributes: {
    investigator: string
    institution: string
    description: string
    email: string
    sample_details: {
      age: string
      sex: string
      strain: string
      tissue_preparation: string
      dissociation_method: string
      sorting_method: string
      preservation_method: string
      isolation_protocol: string
    }
    other_organism: string | null
    tissue: string
    sample_count: number
    sequencing_platform: string
    protocol: string
  }
  doi: string
  metrics: {
    dataset_id: number
    alchemy_score: number
    completeness_score: number
    consistency_score: number
    accuracy_score: number
    reproducibility_score: number
    file_level_metrics: {
      total_cells: number
      total_genes: number
      median_genes_per_cell: number
      median_counts_per_cell: number
      percent_mito: number
      percent_ribo: number
      mean_reads_per_cell: number
    }
    validation_results: {
      passed_validation: boolean
      validation_date: string
      validation_criteria: {
        min_cells: number
        min_genes: number
        max_mito: number
        min_reads_per_cell: number
      }
    }
    quality_checks: {
      qc_metrics: {
        pct_counts_mt_scaled: number
        pct_counts_ribo_scaled: number
        n_genes_by_counts_scaled: number
        total_counts_scaled: number
        shannon_entropy_scaled: number
        complexity_scaled: number
        scaled_gini_coefficient_scaled: number
        sig2noise_ratio_scaled: number
        total_umi_scaled: number
        genes_detected_scaled: number
        dropout_rate_scaled: number
        doublet_score_scaled: number
      }
      thresholds: {
        min_genes_per_cell: number
        max_genes_per_cell: number
        min_counts_per_cell: number
        max_counts_per_cell: number
        max_mito_pct: number
      }
    }
    processing_history: Array<{
      processor: string
      version: string
      timestamp: string
      status: string
      parameters: Record<string, any>
    }>
    last_validated: string
    created_at: string
    updated_at: string
  }
}

const MetricTooltip = ({ title, description }: { title: string; description: string }) => (
  <Popover>
    <PopoverTrigger>
      <Info className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer ml-1" />
    </PopoverTrigger>
    <PopoverContent>
      <h3 className="font-semibold mb-1">{title}</h3>
      <p className="text-sm text-gray-600">{description}</p>
    </PopoverContent>
  </Popover>
)

const CircularGauge = ({ value, max = 100, size = 120, strokeWidth = 8, color = "#4f46e5" }) => {
  const radius = (size - strokeWidth) / 2
  const circumference = radius * 2 * Math.PI
  const strokeDashoffset = circumference - (value / max) * circumference

  return (
    <div className="relative" style={{ width: size, height: size }}>
      <svg className="w-full h-full" viewBox={`0 0 ${size} ${size}`}>
        <circle
          className="text-gray-200"
          strokeWidth={strokeWidth}
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          className="text-primary"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          stroke={color}
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
          style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-2xl font-bold" style={{ color }}>
          {value}
        </span>
      </div>
    </div>
  )
}

export default function DatasetReportCard() {
  const { id } = useParams()
  const [dataset, setDataset] = useState<Dataset | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await apiClient(`/datasets/${id}`)

        if (response.status !== 200) {
          throw new Error('Failed to fetch dataset')
        }
        const { data }= response
        setDataset(data)
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred')
      } finally {
        setIsLoading(false)
      }
    }

    if (id) {
      fetchData()
    }
  }, [id])

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>
  if (!dataset) return <div>No data available</div>

  const metrics = dataset.metrics

  const coreMetrics = [
    { name: "Completeness", value: metrics.completeness_score, color: "#4f46e5" },
    { name: "Consistency", value: metrics.consistency_score, color: "#7c3aed" },
    { name: "Accuracy", value: metrics.accuracy_score, color: "#a855f7" },
    { name: "Reproducibility", value: metrics.reproducibility_score, color: "#d946ef" },
  ]

  const singleCellMetrics = [
    { name: "MT%", value: metrics.file_level_metrics.percent_mito },
    { name: "Ribo%", value: metrics.file_level_metrics.percent_ribo },
    { name: "Genes", value: metrics.file_level_metrics.median_genes_per_cell / 100 },
    { name: "Cells", value: metrics.file_level_metrics.total_cells / 1000 },
    { name: "Total Genes", value: metrics.file_level_metrics.total_genes / 1000 },
    { name: "Reads/Cell", value: metrics.file_level_metrics.mean_reads_per_cell / 10000 },
  ]

  const qualityMetrics = [
    { 
      name: "Gini Coeff", 
      value: metrics.quality_checks.qc_metrics.scaled_gini_coefficient_scaled / 100,
      description: "Measures the uniformity of gene expression distribution"
    },
    { 
      name: "S/N Ratio", 
      value: metrics.quality_checks.qc_metrics.sig2noise_ratio_scaled / 100,
      description: "Signal-to-noise ratio indicating data quality"
    },
    { 
      name: "Dropout Rate", 
      value: metrics.quality_checks.qc_metrics.dropout_rate_scaled / 100,
      description: "Rate at which genes are detected"
    },
    { 
      name: "Doublet Score", 
      value: metrics.quality_checks.qc_metrics.doublet_score_scaled / 100,
      description: "Confidence score for single cell identification"
    },
  ]

  const roundedAlchemyScore = Math.round(metrics.alchemy_score)
  const potentialDoublets = Math.round(metrics.file_level_metrics.total_cells * 
    (100 - metrics.quality_checks.qc_metrics.doublet_score_scaled) / 100)
  const doubletRate = Math.round((potentialDoublets / metrics.file_level_metrics.total_cells) * 100)

  return (
    <div className="min-h-screen p-4 sm:p-8">
      <div className="max-w-7xl mx-auto">
        <header className="mb-6">
          <h1 className="text-2xl font-semibold text-gray-900 mb-2">{dataset.title}</h1>
          <div className="flex items-center gap-2 text-sm text-gray-500">
            <span>Last validated: {new Date(metrics.last_validated).toLocaleString()}</span>
          </div>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100">
            <div className="flex items-center text-green-800 font-semibold mb-2">
              <Dna className="mr-2 h-4 w-4" />
              Quality Assessment
            </div>
            <p className="text-sm text-gray-600">
              Alchemy score of {roundedAlchemyScore} indicates {getQualityLabel(roundedAlchemyScore)} quality.
            </p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100">
            <div className="flex items-center text-yellow-800 font-semibold mb-2">
              <AlertCircle className="mr-2 h-4 w-4" />
              Potential Doublets
            </div>
            <p className="text-sm text-gray-600">
              {potentialDoublets} potential doublets ({doubletRate}%)
            </p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100">
            <div className="flex items-center text-blue-800 font-semibold mb-2">
              <Microscope className="mr-2 h-4 w-4" />
              Gene Detection
            </div>
            <p className="text-sm text-gray-600">
              {metrics.file_level_metrics.total_genes.toLocaleString()} genes detected
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-2">
          <div className="md:col-span-3">
            <Card>
              <CardContent className="p-6">
                <div className="flex items-center gap-2 mb-4">
                  <FileSpreadsheet className="h-5 w-5 text-gray-600" />
                  <h2 className="text-lg font-semibold text-gray-900">Dataset Details</h2>
                </div>
                
                <div className="grid grid-cols-3 gap-x-8 gap-y-3">
                  <div>
                    <p className="text-sm font-medium text-gray-500">Category</p>
                    <p className="text-sm text-gray-900">{dataset.category}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Subcategory</p>
                    <p className="text-sm text-gray-900">{dataset.subcategory}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Technique</p>
                    <p className="text-sm text-gray-900">{dataset.technique}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Organism</p>
                    <p className="text-sm text-gray-900">{dataset.organism}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Source</p>
                    <p className="text-sm text-gray-900">{dataset.source}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Publication Date</p>
                    <p className="text-sm text-gray-900">{dataset.publicationDate}</p>
                  </div>
                </div>

                <div className="mt-4">
                  <p className="text-sm font-medium text-gray-500 mb-1">Labels</p>
                  <div className="flex flex-wrap gap-2">
                    {dataset.labels.map((label, index) => (
                      <span key={index} className="px-2 py-1 bg-indigo-50 text-indigo-700 rounded-full text-xs">
                        {label}
                      </span>
                    ))}
                  </div>
                </div>

                <div className={`mt-4 ${!isExpanded ? "hidden" : ""}`}>
                  <div className="space-y-4">
                    <div>
                      <p className="text-sm font-medium text-gray-500 mb-1">Sample Details</p>
                      <div className="grid grid-cols-2 gap-x-4 gap-y-1">
                        {Object.entries(dataset.attributes.sample_details).map(([key, value]) => (
                          <div key={key} className="text-sm">
                            <span className="text-gray-500">{key.replace(/_/g, ' ')}:</span>{' '}
                            <span className="text-gray-900">{value}</span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div>
                      <p className="text-sm font-medium text-gray-500 mb-1">Additional Attributes</p>
                      <div className="grid grid-cols-2 gap-x-4 gap-y-1">
                        {Object.entries(dataset.attributes)
                          .filter(([key]) => !['sample_details', 'description'].includes(key))
                          .map(([key, value]) => (
                            <div key={key} className="text-sm">
                              <span className="text-gray-500">{key.replace(/_/g, ' ')}:</span>{' '}
                              <span className="text-gray-900">
                                {typeof value === 'object' ? JSON.stringify(value) : value?.toString()}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div>
                      <p className="text-sm font-medium text-gray-500 mb-1">Description</p>
                      <p className="text-sm text-gray-900">{dataset.attributes.description}</p>
                    </div>
                  </div>
                </div>

                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="text-indigo-600 hover:text-indigo-800 text-sm font-medium flex items-center gap-1 mt-4"
                >
                  {isExpanded ? (
                    <>
                      Show less <ChevronUp className="h-4 w-4" />
                    </>
                  ) : (
                    <>
                      Read more <ChevronDown className="h-4 w-4" />
                    </>
                  )}
                </button>
              </CardContent>
            </Card>
          </div>

          <div className="md:col-span-1">
            <Card className="h-full">
              <CardContent className="p-6 flex flex-col items-center justify-center h-full">
                <div className="text-lg font-semibold text-gray-900 mb-2">Alchemy Score</div>
                <CircularGauge 
                  value={roundedAlchemyScore} 
                  size={160} 
                  strokeWidth={12} 
                  color="#6366f1"
                />
                <div className="mt-2 text-gray-500">{roundedAlchemyScore}/100</div>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Card className="col-span-full">
              <CardContent>
                <h2 className="text-xl font-semibold text-indigo-900 mb-6 flex items-center pt-2">
                  <Dna className="mr-2" />
                  Core Quality Dimensions
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {coreMetrics.map((metric) => (
                    <div key={metric.name} className="flex flex-col items-center">
                      <CircularGauge value={metric.value} color={metric.color} />
                      <p className="mt-2 text-sm font-medium text-gray-600">{metric.name}</p>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <div className="flex items-center mb-4 p-1">
                  <h2 className="text-xl font-semibold text-indigo-900">Single Cell RNA Metrics</h2>
                </div>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <RadarChart data={singleCellMetrics}>
                      <PolarGrid stroke="#e2e8f0" />
                      <PolarAngleAxis dataKey="name" stroke="#4a5568" tick={{ fontSize: 10 }} />
                      <PolarRadiusAxis angle={30} domain={[0, 100]} />
                      <Radar name="Value" dataKey="value" stroke="#6366f1" fill="#818cf8" fillOpacity={0.6} />
                      <Tooltip />
                    </RadarChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <div className="flex items-center mb-4 p-1">
                  <h2 className="text-xl font-semibold text-indigo-900">Quality Metrics</h2>
                </div>
                <div className="space-y-4">
                  {qualityMetrics.map((metric) => (
                    <div key={metric.name} className="space-y-2">
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-600">
                          {metric.name}
                          <MetricTooltip
                            title={metric.name}
                            description={metric.description}
                          />
                        </span>
                        <span className="text-sm font-medium text-gray-900">{(metric.value * 100).toFixed(1)}%</span>
                      </div>
                      <Progress value={metric.value * 100} className="h-2" />
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

// Helper function to determine quality label
function getQualityLabel(score: number): string {
  if (score >= 90) return "excellent"
  if (score >= 80) return "very good"
  if (score >= 70) return "good"
  if (score >= 60) return "fair"
  return "needs improvement"
}

