import { FC } from 'react';
import { HelpCircle } from 'lucide-react';
import { StatusEnum } from "@/types/project-model";
import { GeneralInfo } from "@/types/study-model";
import { useForm } from "react-hook-form";
import { useCreateStudy } from "@/api/project/projects";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { CollaboratorSelect } from '@/components/projects/collaborator-select';
import { Badge } from "@/components/ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useParams } from "react-router-dom";

const formSchema = z.object({
    title: z.string().min(1, "Title is required"),
    description: z.string().min(1, "Description is required"),
    status: z.nativeEnum(StatusEnum),
    is_public: z.boolean(),
    collaborators: z.number().array(),
});

const initialValues: GeneralInfo = {
    status: StatusEnum.NotStarted,
    description: '',
    title: '',
    is_public: false,
    collaborators: []
};

const StudyCreate: FC = () => {
    const { id } = useParams();
    const createStudy = useCreateStudy();
    const form = useForm<GeneralInfo>({
        resolver: zodResolver(formSchema),
        defaultValues: initialValues,
        reValidateMode: 'onChange'
    });

    const handleBack = (e: React.MouseEvent) => {
        e.preventDefault();
        window.history.back();
    };

    const onSubmit = (data: GeneralInfo) => {
        createStudy.mutate({
            ...data,
            project_id: Number(id)
        });
    };

    const getStatusBadgeClass = (status: StatusEnum) => {
        switch (status) {
            case StatusEnum.NotStarted:
                return "bg-yellow-100 text-yellow-800 hover:bg-yellow-100/80";
            case StatusEnum.InProgress:
                return "bg-blue-100 text-blue-800 hover:bg-blue-100/80";
            case StatusEnum.Done:
                return "bg-green-100 text-green-800 hover:bg-green-100/80";
            default:
                return "bg-gray-100 text-gray-800 hover:bg-gray-100/80";
        }
    };

    return (
        <div className="max-w-3xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                    <div className="flex justify-between items-center w-full mb-8">
                        <Button 
                            variant="ghost" 
                            size="sm" 
                            className="gap-2"
                            type="button"
                            onClick={handleBack}
                        >
                        </Button>
                        <h1 className="text-3xl font-semibold tracking-tight">New Study</h1>
                        <Button 
                            type="submit" 
                            disabled={createStudy.isPending}
                        >
                            {createStudy.isPending ? "Saving..." : "Save"}
                        </Button>
                    </div>

                    <Card className="p-8 w-full space-y-6">
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-sm font-medium text-gray-700">Study Title</FormLabel>
                                    <FormControl>
                                        <Input {...field} className="w-full" placeholder="Enter study title" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        
                        <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-sm font-medium text-gray-700">Study Description</FormLabel>
                                    <FormControl>
                                        <Textarea {...field} className="w-full resize-none" placeholder="Enter study description" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <FormField
                                control={form.control}
                                name="status"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel className="text-sm font-medium text-gray-700">Study Status</FormLabel>
                                        <Select
                                            value={field.value}
                                            onValueChange={field.onChange}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select status">
                                                    {field.value && (
                                                        <Badge variant="secondary" className={getStatusBadgeClass(field.value as StatusEnum)}>
                                                            {field.value.replace(/([A-Z])/g, ' $1').trim()}
                                                        </Badge>
                                                    )}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {Object.values(StatusEnum).map((status) => (
                                                        <SelectItem key={status} value={status}>
                                                            <Badge variant="secondary" className={getStatusBadgeClass(status)}>
                                                                {status.replace(/([A-Z])/g, ' $1').trim()}
                                                            </Badge>
                                                        </SelectItem>
                                                    ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="is_public"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel className="text-sm font-medium text-gray-700 flex items-center gap-2">
                                            Visibility
                                            <TooltipProvider delayDuration={0}>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <span className="cursor-help">
                                                            <HelpCircle className="h-4 w-4 text-gray-500" />
                                                        </span>
                                                    </TooltipTrigger>
                                                    <TooltipContent className="max-w-xs">
                                                        <p>Private studies can only be seen by collaborators.</p>
                                                        <p>Public studies can be viewed and commented on by anyone.</p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </FormLabel>
                                        <Select
                                            value={field.value ? "Public" : "Private"}
                                            onValueChange={(value) => field.onChange(value === "Public")}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select visibility">
                                                    <Badge variant="outline" className="font-normal">
                                                        {field.value ? "Public" : "Private"}
                                                    </Badge>
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectItem value="Private">
                                                        <Badge variant="outline" className="font-normal">Private</Badge>
                                                    </SelectItem>
                                                    <SelectItem value="Public">
                                                        <Badge variant="outline" className="font-normal">Public</Badge>
                                                    </SelectItem>
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <div className="w-full">
                            <CollaboratorSelect control={form.control} />
                        </div>
                    </Card>
                </form>
            </Form>
        </div>
    );
};

export default StudyCreate;