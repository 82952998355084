import { Link } from "react-router-dom";
import {
  User,
  Calendar,
  MoveUpRight,
  Bookmark,
  Download,
  MessageSquare,
  Check,
  MessageCircle,
  Dna,
  CalendarFold,
  IdCard,
  File,
} from "lucide-react";
import { cn, trimText, formatBytes } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import Tag from "@/components/shared/Tag";

interface DatasetCardProps {
  dataset: any;
  onCommentClick?: (dataset: any) => void;
  onRankingClick?: (dataset: any) => void;
}

const DefaultCard = ({ dataset }: DatasetCardProps) => {
  const {
    title,
    source,
    rankingScore,
    s3Key,
    peerReviewed,
    qualityControlled,
    publicationDate,
    attributes,
  } = dataset;

  return (
    <Card className="hover:bg-gray-50 hover:shadow-md transition-all w-full">
      <CardHeader>
        <CardTitle className="text-xl font-semibold text-left">
          {trimText(title, 80)}
        </CardTitle>
        <div className="flex flex-wrap gap-2 items-center">
          <Tag className="bg-blue-50 text-primary border-primary">{source}</Tag>
          {source === "alchemy" && (
            <Tag className="bg-green-100 border-green-600 text-green-600">
              Alchemy native
            </Tag>
          )}
          {(source === "alchemy" || source == "quality-controlled") && (
            <Tag>
              <div className="bg-violet-600 rounded-full w-2 h-2 mr-1" />
              Alchemy score: <b>{rankingScore}</b>
            </Tag>
          )}
          <Tag>
            <div
              className={cn(
                "rounded-full w-2 h-2 mr-1",
                s3Key ? "bg-green-600" : "bg-red-600"
              )}
            />
            Dataset {s3Key ? "available" : "not-available"}
          </Tag>
          {peerReviewed && (
            <Tag>
              <div className="rounded-full w-2 h-2 bg-green-600 mr-1" />
              Peer reviewed
            </Tag>
          )}
          {qualityControlled && (
            <Tag>
              <div className="rounded-full w-2 h-2 bg-green-600 mr-1" />
              Quality Controlled
            </Tag>
          )}
        </div>
      </CardHeader>
      <CardContent className="space-y-4 text-sm">
        <div className="flex items-center">
          <CalendarFold size="16px" className="mr-2" />{" "}
          {new Date(publicationDate).toLocaleDateString()}
        </div>
        {attributes?.ftp_files && (
          <div className="flex items-center">
            <File size="16px" className="mr-2" /> {attributes?.ftp_files.length}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const UniprotCard = ({
  dataset,
  onAddToCollection,
  isInCollection,
  onCommentClick,
}: DatasetCardProps) => {
  const { title, organism, publicationDate, attributes } = dataset;

  const totalEvidences = attributes?.context?.reduce((sum, item) => {
    return sum + (item.texts?.[0]?.evidences?.length || 0);
  }, 0);

  const locations =
    attributes?.context
      ?.find((c) => c.commentType === "SUBCELLULAR LOCATION")
      ?.subcellularLocations?.map((l) => l.location.value) || [];

  const isoforms =
    attributes?.context?.find((c) => c.commentType === "ALTERNATIVE PRODUCTS")
      ?.isoforms || [];

  const synopsis = attributes?.context?.find(
    (c) => c.commentType === "FUNCTION"
  )?.texts?.[0]?.value;

  return (
    <Card className="hover:bg-gray-50 hover:shadow-md transition-all cursor-pointer">
      <CardHeader className="flex-row justify-between space-x-5 space-y-0 pb-2">
        <div className="space-y-2">
          <Link
            to={`https://www.uniprot.org/uniprotkb/${attributes?.accession}/entry`}
            target="_blank"
          >
            <CardTitle className="text-sm font-normal">{title}</CardTitle>
          </Link>
          <div className="flex gap-2 items-center">
            <Badge className="bg-blue-100 text-blue-800">UniProt</Badge>
            <Badge className="bg-red-100 text-white-200">
              Non-Dataset Record
            </Badge>
          </div>
        </div>
        <div className="flex gap-2">
          {!isInCollection ? (
            <Button
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                onAddToCollection(dataset);
              }}
            >
              <Bookmark /> Add to Collection
            </Button>
          ) : (
            <Button
              variant="outline"
              className="bg-green-100 text-green-800 hover:bg-green-200"
              onClick={(e) => {
                e.stopPropagation();
                onAddToCollection(dataset);
              }}
            >
              <Check className="mr-2" /> In Collection
            </Button>
          )}
          <Button
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
              onCommentClick(dataset);
            }}
            className={dataset.isCommentsOpen ? "bg-gray-100 border-black" : ""}
          >
            <MessageSquare className="mr-2" /> Comments
          </Button>
        </div>
      </CardHeader>

      <CardContent className="space-y-2">
        <div className="flex flex-wrap gap-x-6 gap-y-1 text-sm">
          {organism && (
            <div>
              <strong>Organism:</strong> {organism}
            </div>
          )}
          {attributes?.genes?.length > 0 && (
            <div>
              <strong>Genes:</strong> {attributes.genes.join(", ")}
            </div>
          )}
          {publicationDate && (
            <div>
              <strong>Publication Date:</strong>{" "}
              {new Date(publicationDate).toLocaleDateString()}
            </div>
          )}
          {totalEvidences && (
            <div>
              <strong>Evidence Count:</strong> {totalEvidences}
            </div>
          )}
          {locations.length > 0 && (
            <div>
              <strong>Locations:</strong> {locations.join(", ")}
            </div>
          )}
          {isoforms.length > 0 && (
            <div>
              <strong>Isoforms:</strong> {isoforms.length}
            </div>
          )}
        </div>

        {synopsis && (
          <div className="mt-2">
            <h4 className="font-medium text-sm mb-1">Synopsis</h4>
            <CardDescription className="text-sm">{synopsis}</CardDescription>
          </div>
        )}

        <Collapsible className="mt-2">
          <CollapsibleTrigger
            className="text-sm font-medium"
            onClick={(e) => e.stopPropagation()}
          >
            View Additional Details
          </CollapsibleTrigger>
          <CollapsibleContent className="space-y-2">
            {attributes?.context?.map(
              (item, idx) =>
                item.texts?.[0]?.value &&
                item.commentType !== "FUNCTION" && (
                  <div key={idx}>
                    <h4 className="font-medium text-sm mb-1">
                      {item.commentType}
                    </h4>
                    <CardDescription className="text-sm">
                      {item.texts[0].value}
                    </CardDescription>
                  </div>
                )
            )}
          </CollapsibleContent>
        </Collapsible>
      </CardContent>
    </Card>
  );
};

const PubmedCard = ({
  dataset,
  onAddToCollection,
  isInCollection,
  onCommentClick,
}: DatasetCardProps) => {
  const { title, publicationDate, attributes } = dataset;

  return (
    <Card className="hover:bg-gray-50 hover:shadow-md transition-all cursor-pointer">
      <CardHeader className="flex-row justify-between space-x-5 space-y-0">
        <div className="space-y-2">
          <Link to={`/datasets/${dataset.id}`}>
            <CardTitle className="text-sm font-normal hover:text-blue-600 transition-colors">
              {title}
            </CardTitle>
          </Link>
          <div className="flex flex-wrap gap-2 items-center">
            <Badge className="bg-green-100 text-green-800">PubMed</Badge>
            <Badge className="bg-red-100 text-red-800">
              Non-Dataset Record
            </Badge>
            <Badge variant="outline">Ranking: {dataset.rankingScore}</Badge>
            <Badge variant="outline">Quality: {dataset.qualityScore}</Badge>
            <Badge variant="outline">Method: {dataset.methodScore}</Badge>
            <Badge variant="outline">
              Metadata: {dataset.metadataCompletenessScore}
            </Badge>
            <Badge variant="outline">Citations: {dataset.citationCount}</Badge>
            <Badge variant="outline" className="flex items-center gap-1">
              Peer Reviewed
              <div
                className={`w-2 h-2 rounded-full ${
                  dataset.peerReviewed ? "bg-green-500" : "bg-red-500"
                }`}
              />
            </Badge>
            <Badge variant="outline" className="flex items-center gap-1">
              Quality Controlled
              <div
                className={`w-2 h-2 rounded-full ${
                  dataset.qualityControlled ? "bg-green-500" : "bg-red-500"
                }`}
              />
            </Badge>
          </div>
        </div>
        <div className="flex gap-2">
          {!isInCollection ? (
            <Button
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                onAddToCollection(dataset);
              }}
            >
              <Bookmark />
            </Button>
          ) : (
            <Button
              variant="outline"
              className="bg-green-100 text-green-800 hover:bg-green-200"
              onClick={(e) => {
                e.stopPropagation();
                onAddToCollection(dataset);
              }}
            >
              <Check className="mr-2" /> In Collection
            </Button>
          )}
          <Button
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
              onCommentClick(dataset);
            }}
          >
            <MessageSquare className="mr-2" /> Comments
          </Button>
        </div>
      </CardHeader>
      <CardContent className="space-y-4 text-sm">
        <div className="flex items-center gap-2">
          <Calendar size={16} />
          {new Date(publicationDate).toLocaleDateString()}
        </div>

        {attributes?.authors?.length > 0 && (
          <div className="flex items-center gap-2">
            <User size={16} />
            {attributes.authors.join(", ")}
          </div>
        )}

        {attributes?.abstract && (
          <CardDescription>{attributes.abstract}</CardDescription>
        )}

        {attributes?.full_article_link && (
          <Button
            variant="outline"
            onClick={() => window.open(attributes.full_article_link, "_blank")}
          >
            View Full Article <MoveUpRight className="ml-2" />
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

const GeoCard = ({
  dataset,
  onCommentClick,
  onRankingClick,
}: DatasetCardProps) => {
  const { title, publicationDate, attributes } = dataset;

  // Helper function to format bytes
  const formatBytes = (bytes: number) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <Card className="hover:bg-gray-50 hover:shadow-md transition-all relative">
      {/* Blue Ribbon */}
      {/* <div className="absolute top-0 right-0 w-20 h-20 overflow-hidden">
        <div className="absolute top-0 right-0 -mt-3 -mr-8 transform rotate-45 bg-blue-500 text-white text-xs py-1 px-10">
          Data
        </div>
      </div> */}

      <CardHeader className="flex-row justify-between space-x-5 space-y-0">
        <div className="space-y-2">
          <CardTitle className="text-sm font-normal">{title}</CardTitle>
          <div className="flex flex-wrap gap-2 items-center">
            <Tag className="bg-purple-100 text-purple-800">GEO</Tag>
            <Tag className="bg-blue-500 text-white">Dataset Available</Tag>
            <Tag>Ranking: {dataset.rankingScore}</Tag>
          </div>
        </div>
        <div className="flex gap-2">
          <Button
            className="px-2 py-1 h-8"
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
              onCommentClick(dataset);
            }}
          >
            <MessageCircle />
          </Button>
          {/* <Button
            variant="default"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRankingClick(dataset);
            }}
          >
            Rank Dataset
          </Button> */}
        </div>
      </CardHeader>
      <CardContent className="space-y-4 text-sm">
        <div className="flex flex-wrap text-sm space-x-5">
          {dataset.organism && (
            <div className="flex items-center">
              <IdCard size="16px" className="mr-2" />
              {dataset.organism}
            </div>
          )}
          {attributes?.gds_type && (
            <div className="flex items-center">
              <Dna size="16px" className="mr-2" />
              {attributes.gds_type}
            </div>
          )}
          <div className="flex items-center">
            <CalendarFold size="16px" className="mr-2" />{" "}
            {new Date(publicationDate).toLocaleDateString()}
          </div>
        </div>

        {attributes?.summary && (
          <div className="mt-2">
            <h4 className="font-medium text-base mb-1">Synopsis</h4>
            <CardDescription className="text-sm max-h-10 overflow-hidden">
              {attributes.summary}
            </CardDescription>
          </div>
        )}

        {attributes?.ftp_files?.length > 0 && (
          <div className="space-y-4">
            <div className="space-y-2">
              <h4 className="font-medium text-base mb-1">Raw Files</h4>
              <div className="flex flex-wrap gap-2">
                {attributes.ftp_files.map((file, index) => (
                  <Tag
                    key={index}
                    className="gap-2 cursor-pointer hover:bg-gray-100"
                    onClick={() =>
                      window.open(
                        `${attributes.ftp_link}${file.remote_path}`,
                        "_blank"
                      )
                    }
                  >
                    <Download size={16} className="text-gray-600" />
                    <span className="font-medium">{file.file_type}</span>
                    <span className="text-sm text-gray-500">
                      ({formatBytes(file.size)})
                    </span>
                  </Tag>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <h4 className="font-medium text-base mb-1">Processed Files</h4>
              <div className="text-sm text-gray-500 italic">None</div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export const DatasetCard = (props: DatasetCardProps) => {
  const { dataset } = props;

  switch (dataset.source?.toLowerCase()) {
    // case "uniprot":
    //   return <UniprotCard {...props} />;
    // case "pubmed":
    //   return <PubmedCard {...props} />;
    // case "geo":
    //   return <GeoCard {...props} />;
    default:
      return <DefaultCard {...props} />;
  }
};
export const DatasetDetails = ({ dataset }: DatasetCardProps) => {
  const {
    title,
    source,
    rankingScore,
    s3Key,
    peerReviewed,
    qualityControlled,
    publicationDate,
    attributes,
    qualityScore,
    methodScore,
    metadataCompletenessScore,
  } = dataset;

  return (
    <div className="py-5 h-full">
      <div className="h-full overflow-auto">
        <div className="text-sm font-normal mb-2">{title}</div>
        <div className="flex flex-wrap gap-2 items-center mb-8 text-sm">
          <Tag className="bg-blue-50 text-primary border-primary">{source}</Tag>
          <div className="flex items-center">
            <CalendarFold size="16px" className="mr-2" /> Published on{" "}
            {new Date(publicationDate).toLocaleDateString()}
          </div>
        </div>
        <div className="flex flex-wrap gap-2 items-center mb-8 text-sm">
          {source === "alchemy" && (
            <Tag className="bg-green-100 border-green-600 text-green-600">
              Alchemy native
            </Tag>
          )}
          <Tag>
            <div
              className={cn(
                "rounded-full w-2 h-2 mr-1",
                s3Key ? "bg-green-600" : "bg-red-600"
              )}
            />
            Dataset {s3Key ? "available" : "not-available"}
          </Tag>
          {peerReviewed && (
            <Tag>
              <div className="rounded-full w-2 h-2 bg-green-600 mr-1" />
              Peer reviewed
            </Tag>
          )}
          {qualityControlled && (
            <Tag>
              <div className="rounded-full w-2 h-2 bg-green-600 mr-1" />
              Quality Controlled
            </Tag>
          )}
        </div>
        {(source === "alchemy" || source == "quality-controlled") && (
          <>
            <div className="font-semibold mb-1">Alchemy quality scoring</div>
            <div className="flex gap-2 mb-8">
              <Tag className="bg-violet-200 border-violet-600">
                Total score: <b>{rankingScore || "N/A"}</b>
              </Tag>
              <Tag>
                Quality: <b>{qualityScore}</b>
              </Tag>
              <Tag>
                Method: <b>{methodScore}</b>
              </Tag>
              <Tag>
                MetaData: <b>{metadataCompletenessScore}</b>
              </Tag>
              <Tag>
                Impact: <b></b>
              </Tag>
            </div>
          </>
        )}
        <div className="font-semibold mb-2">Synopsis</div>
        <p className="text-xs mb-8">{attributes.abstract}</p>
        {attributes?.ftp_files?.length > 0 && (
          <div className="space-y-4">
            <div className="font-semibold mb-1">Raw Files</div>
            <div className="flex flex-wrap gap-2">
              {attributes.ftp_files.map((file, index) => (
                <Tag
                  key={index}
                  className="gap-2 cursor-pointer hover:bg-gray-100"
                  onClick={() =>
                    window.open(
                      `${attributes.ftp_link}${file.remote_path}`,
                      "_blank"
                    )
                  }
                >
                  <Download size={16} className="text-gray-600" />
                  <span className="font-medium">{file.file_type}</span>
                  <span className="text-sm text-gray-500">
                    ({formatBytes(file.size)})
                  </span>
                </Tag>
              ))}
            </div>
            <h4 className="font-semibold mb-1">Processed Files</h4>
            <div className="space-y-2">
              <div className="text-sm text-gray-500 italic">None</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
