import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface ColoredSelectProps {
  items: any[];
  value?: string;
  placeholder?: string;
  onChange: () => void;
  disabled?: boolean;
}

export default function ColoredSelect({
  items,
  value,
  onChange,
  placeholder,
  disabled,
}: ColoredSelectProps) {
  const selected = items.find((item) => item.key === value);
  return (
    <Select defaultValue={value} onValueChange={onChange} disabled={disabled}>
      <SelectTrigger
        className={`rounded-full py-0 h-6 w-fit text-xs bg-${selected.bgColor} text-${selected.textColor}`}
      >
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {items.map((item) => (
            <SelectItem key={item.key} value={item.key}>
              {item.value}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
