import { useQuery } from "@tanstack/react-query";
import { userQueryKeys } from "@/api/user/userQueryKeys";
import { apiClient } from "@/api/apiClient";
import { UserModel } from "@/types/user-model";

const getAllUserFn = async () =>{
    const response = await apiClient.get('/users')
    return response.data
}

export function useAllUsers() {
    return useQuery<UserModel[]>({
        queryKey: userQueryKeys.all,
        queryFn: getAllUserFn,
        networkMode: 'online'
    })
}

const getUsersFormMultiselect = async () =>{
    const response = await apiClient.get('/users/multiselect-options')
    return response.data
}

export function useAllUsersForMultiselect() {
    return useQuery<UserModel[]>({
        queryKey: userQueryKeys.all,
        queryFn: getUsersFormMultiselect,
        networkMode: 'online'
    })
}
