import { Link } from "react-router-dom";

export default function SlackJoin({ text = "Join our Slack community" }) {
  return (
    <Link
      to="https://join.slack.com/t/alchemypublic/shared_invite/zt-31wtzqn4n-WGKDGdSRj4f_tjnWhSvCLw"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 "
    >
      <img src="/slack.svg" className="w-5 h-5" />
      {text}
    </Link>
  );
}
