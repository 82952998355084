import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../apiClient";

interface Comment {
  id: number;
  content: string;
  created_at: string;
  user: {
    name: string;
    email: string;
  };
}

interface CommentCreate {
  content: string;
  dataset_id: number;
}

export const useComments = (datasetId: number | null) => {
  const queryClient = useQueryClient();
  
  const { data: comments = [], isLoading } = useQuery({
    queryKey: ['comments', datasetId],
    queryFn: async () => {
      if (!datasetId) return [];
      const { data } = await apiClient.get<Comment[]>(`/comments/dataset/${datasetId}`);
      return data;
    },
    enabled: !!datasetId
  });

  const addComment = useMutation({
    mutationFn: async (comment: CommentCreate) => {
      const { data } = await apiClient.post<Comment>('/comments', comment);
      return data;
    },
    onSuccess: () => {
      // Invalidate and refetch comments for this dataset
      queryClient.invalidateQueries({ queryKey: ['comments', datasetId] });
    }
  });

  return {
    comments,
    isLoading,
    addComment
  };
}; 