export const categories = {
    "Molecular/Omics Data": {
      subcategories: ["Genomics (DNA)", "Transcriptomics (RNA)", "Proteomics", "Metabolomics", "Epigenomics", "Lipidomics", "Glycomics", "Interactomics"],
      techniques: {
        "Genomics (DNA)": [
          "Whole genome sequencing",
          "Targeted sequencing",
          "ChIP-seq",
          "ATAC-seq",
          "Metagenomics",
          "SNP analysis",
          "Copy number variation",
          "Hi-C sequencing",
          "4C-seq",
          "DNA methylation sequencing",
          "Third-generation sequencing",
          "Nanopore sequencing",
          "Single-molecule real-time sequencing"
        ],
        "Transcriptomics (RNA)": [
          "RNA-seq",
          "Single-cell RNA-seq",
          "Small RNA sequencing",
          "Long non-coding RNA",
          "Ribosome profiling",
          "Spatial transcriptomics",
          "Splice variants",
          "CAGE-seq",
          "PAR-CLIP",
          "RNA structure mapping",
          "Alternative splicing analysis",
          "Bulk RNA sequencing",
          "RNA velocity analysis"
        ],
        "Proteomics": [
          "Mass spectrometry",
          "Protein-protein interactions",
          "Post-translational modifications",
          "Protein structure",
          "Protein localization",
          "Proteome quantification",
          "Phosphoproteomics",
          "Glycoproteomics",
          "SILAC",
          "TMT labeling",
          "Label-free quantification",
          "Protein arrays",
          "Cross-linking mass spectrometry",
          "Top-down proteomics",
          "Bottom-up proteomics"
        ],
        "Metabolomics": [
          "Primary metabolites",
          "Secondary metabolites",
          "Lipids",
          "Small molecules",
          "Metabolic flux analysis",
          "Metabolic networks",
          "Pathway analysis",
          "Untargeted metabolomics",
          "Targeted metabolomics",
          "NMR-based metabolomics",
          "MS-based metabolomics",
          "Fluxomics",
          "Metabolic modeling"
        ],
        "Epigenomics": [
          "ChIP-seq",
          "ATAC-seq",
          "Bisulfite sequencing",
          "CUT&Tag",
          "CUT&Run",
          "Hi-C",
          "4C-seq",
          "Chromosome conformation capture",
          "DNA accessibility analysis",
          "Histone modification analysis"
        ],
        "Lipidomics": [
          "Targeted lipidomics",
          "Untargeted lipidomics",
          "Lipid imaging",
          "Lipid quantification",
          "Membrane lipid analysis",
          "Sphingolipidomics"
        ],
        "Glycomics": [
          "Glycan analysis",
          "Glycoproteomics",
          "Glycan imaging",
          "Structural glycomics",
          "Functional glycomics"
        ],
        "Interactomics": [
          "Protein-protein interaction mapping",
          "Genetic interaction screening",
          "Yeast two-hybrid",
          "Co-immunoprecipitation",
          "Proximity labeling"
        ]
      }
    },
    "Cellular/Microscopy Data": {
      subcategories: ["Light Microscopy", "Electron Microscopy", "Flow Cytometry/Cell Analysis", "Advanced Imaging", "Live Cell Imaging"],
      techniques: {
        "Light Microscopy": [
          "Brightfield",
          "Phase contrast",
          "DIC",
          "Fluorescence",
          "Confocal",
          "Two-photon",
          "Light sheet",
          "Super-resolution",
          "TIRF microscopy",
          "FRET microscopy",
          "FLIM",
          "Structured illumination microscopy"
        ],
        "Electron Microscopy": [
          "TEM",
          "SEM",
          "Cryo-EM",
          "Tomography",
          "FIB-SEM",
          "Serial block-face SEM",
          "Correlative light-electron microscopy",
          "Energy-filtered TEM",
          "Immune-electron microscopy"
        ],
        "Flow Cytometry/Cell Analysis": [
          "Cell sorting",
          "Multi-parameter analysis",
          "Single-cell analysis",
          "Spectral cytometry",
          "Mass cytometry (CyTOF)",
          "Imaging flow cytometry",
          "High-throughput screening",
          "Real-time cell analysis"
        ],
        "Advanced Imaging": [
          "Single-molecule imaging",
          "Multi-photon microscopy",
          "Label-free imaging",
          "Hyperspectral imaging",
          "Raman microscopy",
          "Atomic force microscopy"
        ],
        "Live Cell Imaging": [
          "Time-lapse microscopy",
          "Intravital microscopy",
          "Optogenetics",
          "Calcium imaging",
          "Voltage imaging",
          "Particle tracking"
        ]
      }
    },
    "Physiological/Functional Data": {
      subcategories: ["Electrophysiology", "Biochemical Assays", "Cell Signaling", "Tissue Analysis"],
      techniques: {
        "Electrophysiology": [
          "Patch clamp",
          "Field potentials",
          "Multi-electrode arrays",
          "Voltage sensing",
          "Calcium imaging",
          "Optogenetics",
          "Voltage-sensitive dyes",
          "Current clamp",
          "Dynamic clamp"
        ],
        "Biochemical Assays": [
          "Enzyme kinetics",
          "Binding assays",
          "Activity assays",
          "Structural studies",
          "Protein-protein interaction",
          "Reporter assays",
          "High-throughput screening",
          "Thermal shift assays"
        ],
        "Cell Signaling": [
          "Signal transduction assays",
          "Second messenger analysis",
          "Receptor binding studies",
          "Pathway analysis",
          "Phosphorylation studies",
          "Calcium signaling"
        ],
        "Tissue Analysis": [
          "Histology",
          "Immunohistochemistry",
          "In situ hybridization",
          "Tissue clearing",
          "3D tissue imaging",
          "Spatial transcriptomics"
        ]
      }
    },
    "Pharmacology/Toxicology": {
      subcategories: ["Pharmacokinetics", "Toxicology", "Drug Discovery", "Safety Assessment"],
      techniques: {
        "Pharmacokinetics": [
          "Absorption",
          "Distribution",
          "Metabolism",
          "Excretion",
          "Drug-drug interactions",
          "Bioavailability",
          "Half-life studies",
          "Drug transport studies",
          "Metabolite identification",
          "Tissue distribution"
        ],
        "Toxicology": [
          "Acute toxicity",
          "Chronic toxicity",
          "Genotoxicity",
          "Cytotoxicity",
          "Developmental toxicity",
          "Environmental toxicology",
          "Bioaccumulation",
          "Reproductive toxicity",
          "Neurotoxicity",
          "Immunotoxicity",
          "High-throughput toxicity screening"
        ],
        "Drug Discovery": [
          "High-throughput screening",
          "Structure-activity relationships",
          "Lead optimization",
          "Target identification",
          "Virtual screening",
          "Fragment-based drug design"
        ],
        "Safety Assessment": [
          "Safety pharmacology",
          "Carcinogenicity studies",
          "Immunogenicity testing",
          "Reproductive toxicity",
          "Developmental toxicity",
          "Long-term safety studies"
        ]
      }
    },
}