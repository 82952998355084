import { apiClient } from "@/api/apiClient";

export interface AIModel {
  id: number;
  model_name: string;
  description: string | null;
  version: string;
  organism?: string;
  tissue_type?: string;
  model_architecture?: string;
  publicly_accessible: boolean;
  storage_key?: string[];
  dataset_id?: number;
  user_id?: number;
  download_count: number;
  like_count: number;
  citation_count: number;
  metrics: {
    accuracy: number;
    f1_score: number;
    auc_roc: number;
  };
  created_at: string;
  updated_at: string;
}

export async function fetchAllModels() {
  const response = await apiClient.get<AIModel[]>("/models");
  return response.data;
}