import { useRouteLoaderData, Link } from "react-router-dom";
import { Users, MessageCircle, Lock, LockOpen, Pencil } from "lucide-react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import ColoredSelect from "@/components/shared/ColoredSelect";
import ProjectForm from "@/components/projects/ProjectForm";
import { StatusEnum } from "@/types/project-model";
import { formatProjectToRender } from "@/lib/utils";

export const statusColors = {
  [StatusEnum.NotStarted]:
    "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100",
  [StatusEnum.InProgress]:
    "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100",
  [StatusEnum.Done]:
    "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
};

const statuses = [
  {
    key: StatusEnum.NotStarted,
    value: StatusEnum.NotStarted,
    bgColor: "yellow-100",
    textColor: "yellow-800",
  },
  {
    key: StatusEnum.InProgress,
    value: StatusEnum.InProgress,
    bgColor: "blue-100",
    textColor: "blue-800",
  },
  {
    key: StatusEnum.Done,
    value: StatusEnum.Done,
    bgColor: "green-100",
    textColor: "green-800",
  },
];

export default function ProjectsList({ projects }) {
  const user = useRouteLoaderData("userData") as any;

  return (
    <div className="grid grid-cols-3 gap-5">
      {projects.map(formatProjectToRender).map((project, i) => (
        <Card key={i} className="flex flex-col justify-between">
          <CardHeader className="">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {project.isPublic ? (
                  <LockOpen size="20px" className="mr-2" />
                ) : (
                  <Lock size="20px" className="mr-2" />
                )}
                <Link
                  className="text-black hover:text-gray-600 text-xl font-semibold"
                  to={`/projects/${project.id}`}
                >
                  {project.title}
                </Link>
              </div>
              {user?.id === project.ownerId && (
                <ProjectForm project={project}>
                  <span className="text-black hover:text-gray-600 border p-2 rounded-sm cursor-pointer">
                    <Pencil size="16px" />
                  </span>
                </ProjectForm>
              )}
            </div>
            {project.studies.map((item, i) => (
              <div key={i}>{item}</div>
            ))}
            <p className="mb-5">{project.description}</p>
            <ColoredSelect
              items={statuses}
              value={project.status}
              onChange={() => {}}
            />
            <div className="flex my-5">
              <div className="w-1/2">
                <p className="text-muted-foreground font-semibold">
                  Created Date
                </p>
                {new Date(project.createdAt).toLocaleDateString()}
              </div>
              <div className="w-1/2">
                <p className="text-muted-foreground font-semibold">
                  Last Update
                </p>
                {new Date(project.createdAt).toLocaleDateString()}
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex justify-between border-t pt-5">
              <div className="flex items-center">
                <Avatar className="mr-2">
                  <AvatarImage src={"avatar"} alt={"user"} />
                  <AvatarFallback>{"User".slice(0, 1)}</AvatarFallback>
                </Avatar>
                User
              </div>
              <div className="flex items-center">
                <Users size="18px" className="mr-1" />
                {project.collaborators.length}
                <MessageCircle size="18px" className="ml-5 mr-1" />
                {project.comments.length}
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
