import { useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";
import { Header } from "@/components/models/header"
import { Filters } from "@/components/models/filters"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Download, Heart, Star, ChevronDown, ChevronRight, ExternalLink, ArrowUpDown } from 'lucide-react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import type { AIModel } from "@/api/models/models"
import { useState, useMemo } from 'react'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination"

function getPerformanceColor(score: number): string {
  if (score >= 0.95) return "bg-green-100 text-green-800";
  if (score >= 0.90) return "bg-blue-100 text-blue-800";
  if (score >= 0.85) return "bg-yellow-100 text-yellow-800";
  return "bg-red-100 text-red-800";
}

export default function ModelsPage() {
  const allModels = useLoaderData() as AIModel[];
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});
  
  const [searchQuery, setSearchQuery] = useState("");
  const [cellType, setCellType] = useState("");
  const [metric, setMetric] = useState("");
  const [architecture, setArchitecture] = useState("");
  const [organism, setOrganism] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [sortField, setSortField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const resetFilters = () => {
    setSearchQuery("");
    setCellType("");
    setMetric("");
    setArchitecture("");
    setOrganism("");
    setSortField(null);
    setSortDirection('desc');
    setCurrentPage(1);
  };

  const filteredModels = useMemo(() => {
    return allModels.filter(model => {
      const matchesSearch = searchQuery === "" || 
        model.model_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        model.description.toLowerCase().includes(searchQuery.toLowerCase());
      
      const matchesCellType = cellType === "" || 
        model.cell_type?.toLowerCase() === cellType.toLowerCase();
      
      const matchesArchitecture = architecture === "" || 
        model.model_architecture?.toLowerCase() === architecture.toLowerCase();

      const matchesOrganism = organism === "" || 
        model.organism?.toLowerCase() === organism.toLowerCase();

      const matchesMetric = metric === "" || true;

      return matchesSearch && matchesCellType && matchesArchitecture && matchesOrganism && matchesMetric;
    });
  }, [allModels, searchQuery, cellType, metric, architecture, organism]);

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const sortedAndFilteredModels = useMemo(() => {
    let result = [...filteredModels];
    
    if (sortField) {
      result.sort((a, b) => {
        let valueA: number;
        let valueB: number;
        
        switch (sortField) {
          case 'accuracy':
            valueA = a.metrics.accuracy;
            valueB = b.metrics.accuracy;
            break;
          case 'f1_score':
            valueA = a.metrics.f1_score;
            valueB = b.metrics.f1_score;
            break;
          case 'auc_roc':
            valueA = a.metrics.auc_roc;
            valueB = b.metrics.auc_roc;
            break;
          default:
            return 0;
        }
        
        return sortDirection === 'asc' 
          ? valueA - valueB 
          : valueB - valueA;
      });
    }
    
    return result;
  }, [filteredModels, sortField, sortDirection]);

  const totalModels = sortedAndFilteredModels.length;
  const totalPages = Math.ceil(totalModels / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentModels = sortedAndFilteredModels.slice(startIndex, endIndex);

  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
    
    if (totalPages <= maxVisiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    pages.push(1);
    
    if (currentPage > 3) {
      pages.push('ellipsis');
    }
    
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(currentPage + 1, totalPages - 1); i++) {
      pages.push(i);
    }
    
    if (currentPage < totalPages - 2) {
      pages.push('ellipsis');
    }
    
    pages.push(totalPages);
    
    return pages;
  };

  const toggleRow = (id: number) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const SortableHeader = ({ field, label }: { field: string, label: string }) => (
    <div
      className="flex items-center gap-1 cursor-pointer hover:text-gray-200"
      onClick={() => handleSort(field)}
    >
      {label}
      <ArrowUpDown 
        className={`h-4 w-4 ${
          sortField === field 
            ? 'text-blue-400' 
            : 'text-gray-400 opacity-50'
        }`} 
      />
    </div>
  );

  return (
    <div className="flex min-h-screen flex-col">
      <Header />
      <main className="flex-1 container py-4">
        <Filters 
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          cellType={cellType}
          setCellType={setCellType}
          metric={metric}
          setMetric={setMetric}
          architecture={architecture}
          setArchitecture={setArchitecture}
          organism={organism}
          setOrganism={setOrganism}
          onReset={resetFilters}
        />
        <div className="mt-4">
          {sortedAndFilteredModels.length > 0 ? (
            <div className="space-y-4">
              <div className="text-sm text-muted-foreground">
                Showing {startIndex + 1}-{Math.min(endIndex, totalModels)} of {totalModels} models
              </div>
              <div className="rounded-md border">
                <Table>
                  <TableHeader>
                    <TableRow className="border-b-0 bg-black hover:bg-black">
                      <TableHead className="w-[30px] bg-black"></TableHead>
                      <TableHead className="text-white">Model</TableHead>
                      <TableHead className="text-white">Description</TableHead>
                      <TableHead className="text-white">Architecture</TableHead>
                      <TableHead className="text-white">Organism</TableHead>
                      <TableHead className="text-white">Framework</TableHead>
                      <TableHead className="text-white">Training Data</TableHead>
                      <TableHead className="text-white">
                        <SortableHeader field="accuracy" label="Accuracy" />
                      </TableHead>
                      <TableHead className="text-white">
                        <SortableHeader field="f1_score" label="F1 Score" />
                      </TableHead>
                      <TableHead className="text-white">
                        <SortableHeader field="auc_roc" label="AUC-ROC" />
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody className="divide-y divide-gray-200">
                    {currentModels.map((model) => (
                      <>
                        <TableRow 
                          key={model.id}
                          className={`cursor-pointer transition-colors py-1 ${
                            expandedRows[model.id] 
                              ? 'bg-gray-50 hover:bg-gray-50' 
                              : 'hover:bg-gray-100'
                          }`}
                          onClick={() => toggleRow(model.id)}
                        >
                          <TableCell className="w-[30px] py-2">
                            {expandedRows[model.id] ? 
                              <ChevronDown className="h-4 w-4 text-blue-600" /> : 
                              <ChevronRight className="h-4 w-4" />
                            }
                          </TableCell>
                          <TableCell className="font-medium">
                            <div className="flex items-center gap-2">
                              {model.model_name}
                              <Badge variant={model.publicly_accessible ? "secondary" : "outline"} className="text-xs">
                                {model.publicly_accessible ? "Public" : "Private"}
                              </Badge>
                            </div>
                            <div className="text-xs text-muted-foreground">
                              v{model.version}
                            </div>
                          </TableCell>
                          <TableCell className="max-w-[200px]">
                            <div className="text-sm text-muted-foreground line-clamp-2">
                              {model.description}
                            </div>
                          </TableCell>
                          <TableCell>{model.model_architecture}</TableCell>
                          <TableCell>{model.organism}</TableCell>
                          <TableCell>{model.programming_framework || "N/A"}</TableCell>
                          <TableCell>{model.training_data_size || "N/A"}</TableCell>
                          <TableCell>
                            <Badge className={getPerformanceColor(model.metrics.accuracy)}>
                              {(model.metrics.accuracy * 100).toFixed(1)}%
                            </Badge>
                          </TableCell>
                          <TableCell>
                            <Badge variant="outline">
                              {(model.metrics.f1_score * 100).toFixed(1)}%
                            </Badge>
                          </TableCell>
                          <TableCell>
                            <Badge variant="outline">
                              {model.metrics.auc_roc.toFixed(2)}
                            </Badge>
                          </TableCell>
                        </TableRow>
                        {expandedRows[model.id] && (
                          <TableRow>
                            <TableCell colSpan={10} className="p-0">
                              <div className="bg-gray-50 border-l-4 border-blue-600 p-6">
                                <div className="space-y-6">
                                  {/* Full Description */}
                                  <div className="bg-white rounded-lg p-4 shadow-sm">
                                    <h4 className="font-medium mb-2 text-blue-600">Description</h4>
                                    <p className="text-sm text-muted-foreground">
                                      {model.description}
                                    </p>
                                  </div>

                                  <div className="grid grid-cols-3 gap-4">
                                    {/* Model Details Column */}
                                    <div className="bg-white rounded-lg p-4 shadow-sm">
                                      <h4 className="font-medium mb-3 text-blue-600">Model Details</h4>
                                      <div className="text-sm space-y-2">
                                        <p><span className="text-muted-foreground font-medium">Input Format:</span> {model.input_format || "N/A"}</p>
                                        <p><span className="text-muted-foreground font-medium">Output Format:</span> {model.output_format || "N/A"}</p>
                                        {model.limitations && (
                                          <div className="mt-3">
                                            <span className="text-muted-foreground font-medium block mb-1">Limitations:</span>
                                            <p className="text-sm bg-red-50 text-red-700 p-2 rounded">{model.limitations}</p>
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    {/* System Requirements Column */}
                                    <div className="bg-white rounded-lg p-4 shadow-sm">
                                      <h4 className="font-medium mb-3 text-blue-600">System Requirements</h4>
                                      <div className="text-sm space-y-2">
                                        {model.system_requirements && Object.entries(model.system_requirements).length > 0 ? (
                                          Object.entries(model.system_requirements).map(([key, value]) => (
                                            <p key={key}>
                                              <span className="text-muted-foreground font-medium capitalize">{key}:</span> {value}
                                            </p>
                                          ))
                                        ) : (
                                          <p className="text-muted-foreground italic">No system requirements specified</p>
                                        )}
                                      </div>
                                    </div>

                                    {/* Resources Column */}
                                    <div className="bg-white rounded-lg p-4 shadow-sm">
                                      <h4 className="font-medium mb-3 text-blue-600">Resources</h4>
                                      <div className="text-sm space-y-2">
                                        {model.repository_url && (
                                          <a href={model.repository_url} className="flex items-center gap-1 text-blue-600 hover:underline">
                                            Repository <ExternalLink className="h-3 w-3" />
                                          </a>
                                        )}
                                        {model.paper_url && (
                                          <a href={model.paper_url} className="flex items-center gap-1 text-blue-600 hover:underline">
                                            Paper <ExternalLink className="h-3 w-3" />
                                          </a>
                                        )}
                                        {model.documentation_url && (
                                          <a href={model.documentation_url} className="flex items-center gap-1 text-blue-600 hover:underline">
                                            Documentation <ExternalLink className="h-3 w-3" />
                                          </a>
                                        )}
                                        {model.paper_doi && (
                                          <p><span className="text-muted-foreground font-medium">DOI:</span> {model.paper_doi}</p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {/* Tags Section */}
                                  <div className="bg-white rounded-lg p-4 shadow-sm space-y-4">
                                    <div className="grid grid-cols-2 gap-4">
                                      {/* Keywords */}
                                      {model.keywords && model.keywords.length > 0 && (
                                        <div>
                                          <h4 className="font-medium mb-2 text-blue-600">Keywords</h4>
                                          <div className="flex flex-wrap gap-1">
                                            {model.keywords.map(keyword => (
                                              <Badge key={keyword} variant="outline">{keyword}</Badge>
                                            ))}
                                          </div>
                                        </div>
                                      )}

                                      {/* Application Domains */}
                                      {model.application_domains && model.application_domains.length > 0 && (
                                        <div>
                                          <h4 className="font-medium mb-2 text-blue-600">Application Domains</h4>
                                          <div className="flex flex-wrap gap-1">
                                            {model.application_domains.map(domain => (
                                              <Badge key={domain} variant="secondary">{domain}</Badge>
                                            ))}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </div>
              
              {/* Pagination */}
              <div className="flex justify-center mt-4">
                <Pagination>
                  <PaginationContent>
                    <PaginationItem>
                      <PaginationPrevious 
                        onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                        className={currentPage === 1 ? "pointer-events-none opacity-50" : "cursor-pointer"}
                      />
                    </PaginationItem>
                    
                    {getPageNumbers().map((page, idx) => (
                      <PaginationItem key={idx}>
                        {page === 'ellipsis' ? (
                          <PaginationEllipsis />
                        ) : (
                          <PaginationLink
                            onClick={() => setCurrentPage(page as number)}
                            isActive={currentPage === page}
                            className="cursor-pointer"
                          >
                            {page}
                          </PaginationLink>
                        )}
                      </PaginationItem>
                    ))}
                    
                    <PaginationItem>
                      <PaginationNext 
                        onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                        className={currentPage === totalPages ? "pointer-events-none opacity-50" : "cursor-pointer"}
                      />
                    </PaginationItem>
                  </PaginationContent>
                </Pagination>
              </div>
            </div>
          ) : (
            <div className="text-center py-12 border rounded-lg bg-muted/10">
              <h3 className="text-lg font-semibold mb-2">No models available</h3>
              <p className="text-sm text-muted-foreground mb-4">
                Be the first to share your AI model with the community
              </p>
              <Link to="/models/new">
                <Button>
                  Upload a Model
                </Button>
              </Link>
            </div>
          )}
        </div>
      </main>
    </div>
  )
}
