interface TagProps {
  children: any;
  onClick?: () => void;
  className?: string;
}

export default function Tag({ onClick, children, className }: TagProps) {
  return (
    <div
      className={`text-sm rounded-sm border px-2 flex items-center ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
