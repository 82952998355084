import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { ArrowUpIcon, HelpCircle } from "lucide-react"
import { Line, LineChart, ResponsiveContainer } from "recharts"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "@/api/apiClient";
import { Skeleton } from "@/components/ui/skeleton";

interface OverallScore {
  current_score: number;
  percentile: number;
}

export function OverallScoreCard() {
  const { data, isLoading } = useQuery({
    queryKey: ['bdsi', 'overall-score'],
    queryFn: async () => {
      const { data } = await apiClient.get<OverallScore>('/bdsi/overall-score');
      return data;
    }
  });

  if (isLoading) {
    return (
      <Card className="bg-gradient-to-br from-background to-secondary/10">
        <CardHeader>
          <CardTitle>Overall Index Score</CardTitle>
        </CardHeader>
        <CardContent>
          <Skeleton className="h-[150px] w-full" />
        </CardContent>
      </Card>
    );
  }

  const score = data?.current_score ?? 0;
  const percentile = data?.percentile ?? 0;
  const hasData = score > 0;
  
  const level = score > 80 ? 'Gold' : score > 60 ? 'Silver' : 'Bronze';
  
  return (
    <Card className="bg-gradient-to-br from-background to-secondary/10">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          Overall Index Score
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle className="h-4 w-4 text-muted-foreground" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Your overall score based on documentation, quality, and impact.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </CardTitle>
      </CardHeader>
      <CardContent>
        {!hasData ? (
          <div className="flex flex-col items-center justify-center py-8 text-center">
            <p className="text-lg font-medium">Welcome to BDSI!</p>
            <p className="mt-2 text-sm text-muted-foreground">
              Share your first dataset to start building your index score.
            </p>
            <Badge variant="outline" className="mt-4 bg-primary/10">
              Bronze Level
            </Badge>
          </div>
        ) : (
          <>
            <div className="flex items-baseline space-x-3">
              <h2 className="text-4xl font-bold">{Math.round(score)}</h2>
              <span className="text-green-500 flex items-center">
                <ArrowUpIcon className="h-4 w-4 mr-1" />5
              </span>
            </div>
            <p className="text-sm text-muted-foreground mt-2">
              You're in the top {Math.round(percentile)}th percentile of your field
            </p>
            <div className="mt-4 flex items-center justify-between">
              <span className="text-sm font-medium">Achievement Level</span>
              <Badge variant="outline" className="bg-primary/10">
                {level}
              </Badge>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
}