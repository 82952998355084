import { useNavigate, useParams } from "react-router-dom";
import { useExperimentById, useUpdateExperiment } from "@/api/project/projects";
import BackArrow from "@/components/shared/back-arrow";
import { Calendar, Loader2, Lock, Pencil, Users } from "lucide-react";
import { statusColors } from "@/components/projects/ProjectsList";
import { Procedure } from "@/types/study-model";
import { Card } from "@/components/ui/card";
import { useMemo } from "react";
import ExperimentDatasetTable from "@/components/projects/experiment/experiment-dataset-table";
import { Button } from "@/components/ui/button";
import { useAllDatasets } from "@/api/dataset/datasets";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

function getSortedProcedure(procedures: Procedure[]): Procedure[] {
  return procedures.sort((a, b) => a.position - b.position);
}

const Experiment = () => {
  const { experimentId, studyId, projectId } = useParams();
  const navigate = useNavigate();

  const updateExperiment = useUpdateExperiment();
  const { data: datasets } = []; // useAllDatasets()
  const {
    data: experiment,
    isLoading,
    isError,
  } = useExperimentById(Number(experimentId));

  const datasetOptions = useMemo(() => {
    return (datasets || []).filter(
      (dataset) =>
        !(experiment?.datasets || []).find((el) => el.id === dataset.id)
    );
  }, [experiment, datasets]);

  const addNewDataSetHandler = () => {
    navigate("/datasets/upload");
  };

  const addFromExistedHandler = (datasetId: number) => {
    updateExperiment.mutate({
      ...experiment,
      datasets: [...experiment.datasets, { id: datasetId }],
    });
  };

  const navigateToEdit = () => {
    navigate(
      `/projects/${projectId}/study/${studyId}/experiment/edit/${experiment.id}`
    );
  };

  if (isLoading)
    return (
      <Loader2
        className="relative top-36 m-auto h-20 w-20 animate-spin"
        color="black"
      />
    );
  if (isError) return <>Something went wrong</>;

  return (
    <div className="container mx-auto p-4">
      <BackArrow text={`projects/${projectId}/study/${studyId}`} />
      <div className="flex justify-between gap-6">
        <div className={"flex gap-3"}>
          <h1 className="text-3xl font-bold">{experiment.title}</h1>
          <div className="flex items-center space-x-2 px-3 py-2 rounded-md text-xs bg-black text-white">
            <Lock size={14} />
            <span>Private</span>
          </div>
        </div>
        <Button onClick={navigateToEdit}>Edit</Button>
      </div>
      <h3 className="text-gray-500 mt-6">{experiment.description}</h3>

      <Card className="p-6">
        <div className="flex flex-col gap-3 items-start">
          <div
            className={`px-4 py-2 text-gray-500 font-light flex items-center gap-1 rounded-full text-xs ${
              statusColors[experiment.status]
            }`}
          >
            <div className={"w-2 h-2 rounded-full bg-gray-500"}></div>
            <span>{experiment.status}</span>
          </div>
          <div className="flex items-center space-x-2">
            <Users className="w-4 h-4 mr-2" />
            <p>{experiment.collaborators.length} contributors</p>
          </div>
          <div className="flex  items-center space-x-2">
            <Calendar className="w-4 h-4 mr-2" />
            {new Date().toLocaleDateString("en-US").replace(/\//g, ".")}
          </div>
        </div>

        <div className="flex flex-col gap-6 mt-12">
          <div className="flex flex-col gap-3 [&_h3]:font-bold">
            <h3>Hypothesis</h3>
            <p>{experiment.hypothesis}</p>
          </div>

          <div className="flex flex-col gap-3 [&_h3]:font-bold">
            <h3>Categories</h3>
            <div className={"flex flex-wrap gap-3"}>
              {experiment.categories?.length > 0 ? (
                experiment.categories.map((category) => {
                  return (
                    <p
                      className={"p-2 text-gray-500 border rounded"}
                      key={category.id}
                    >
                      {category.name}
                    </p>
                  );
                })
              ) : (
                <p>This experiment has no categories yet</p>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-3 [&_h3]:font-bold">
            <h3>Procedure:</h3>
            {experiment.procedures.length > 0 ? (
              <div className="flex flex-col">
                {getSortedProcedure(experiment.procedures).map((procedure) => {
                  return (
                    <p className={"text-gray-500"} key={procedure.position}>
                      {`${procedure.position}. ${procedure.content}`}
                    </p>
                  );
                })}
              </div>
            ) : (
              <p>This experiment has no procedures yet</p>
            )}
          </div>
          <div className="flex flex-col gap-3 [&_h3]:font-bold">
            <div className="flex justify-between">
              <h3>Datasets:</h3>

              <div className={"flex gap-3"}>
                <Select
                  onValueChange={(datasetId) => {
                    addFromExistedHandler(Number(datasetId));
                  }}
                >
                  <SelectTrigger className="w-40 bg-black text-white">
                    <SelectValue placeholder="Add from existing" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {datasetOptions?.map((dataset) => (
                        <SelectItem
                          key={dataset.id}
                          value={dataset.id.toString()}
                        >
                          {dataset.title}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <Button onClick={addNewDataSetHandler}>Add new</Button>
              </div>
            </div>
            <ExperimentDatasetTable datasets={experiment.datasets} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Experiment;
