import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import Cookies from "universal-cookie";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  FileClock,
  AlertCircle,
  ArrowRight,
  Search,
  Sparkles,
  SearchX,
} from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Alert, AlertDescription } from "@/components/ui/alert";
import SubmitButton from "@/components/shared/SubmitButton";

import SearchFilter from "@/components/search/SearchFilter";
import SearchResults from "@/components/search/SearchResults";
import SearchHistory from "@/components/search/SearchHistory";
import SearchSuggestions from "@/components/search/SearchSuggestions";
import SearchSidebar from "@/components/search/SearchSidebar";
import TrendingItems from "@/components/search/TrendingItems";
import SearchTitle from "@/components/search/SearchTitle";
import SearchCopilot from "@/components/copilot/SearchCopilot";

import { useAction } from "@/api/apiClient";
import Loader from "../Loader";

interface FilterProps {
  access: "public" | "private" | null;
  // author: string[];
  // topic: string[];
  // keyword: string[];
  // type: string[];
  // dataType: string[];
  // species: string[];
}

const EMPTY_FILTER: FilterProps = {
  access: null,
  // author: [],
  // topic: [],
  // keyword: [],
  // type: [],
  // dataType: [],
  // species: [],
};

export default function SearchAssistant({ closeSidebar }) {
  const { dataType, species, institutions, trendingDatasets } = useLoaderData();
  const cookies = new Cookies(null, { path: "/" });
  const { data, action, isLoading, error } = useAction({ url: "/search" });

  const formSchema = z.object({
    message: z.string().min(3, "Your search must be at least 3 letters long"),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
    },
  });
  const [opened, setOpened] = useState<string | null>(null);
  const [showResults, setShowResults] = useState(false);
  const [showCopilot, setShowCopilot] = useState(false);
  const [activeDatasetId, setActiveDatasetId] = useState<number | null>(null);
  const [filters, setFilters] = useState<FilterProps>(EMPTY_FILTER);

  function onSubmit(values) {
    action({ ...filters, query: values.message });
    const history = cookies.get("searchHistory") || [];
    if (!history.includes(values.message)) history.push(values.message);
    cookies.set("searchHistory", history);
  }

  const handleCommentClick = (dataset) => {
    if (activeDatasetId === dataset.id) {
      setActiveDatasetId(null);
      setOpened(null);
    } else {
      setActiveDatasetId(dataset.id);
      setOpened("Comments");
    }
  };

  const handleRankingClick = (dataset) => {
    if (activeDatasetId === dataset.id) {
      setActiveDatasetId(null);
      setOpened(null);
    } else {
      setActiveDatasetId(dataset.id);
      setOpened("Metrics");
    }
  };

  useEffect(() => {
    if (data?.relevant_datasets?.length) {
      setShowResults(true);
      setShowCopilot(true);
    }
    if (data?.relevant_datasets?.length === 0) {
      setShowCopilot(false);
    }
  }, [data]);

  return (
    <div className="flex w-full h-full">
      <div
        className={cn(
          "overflow-auto w-full h-full md:flex relative py-5",
          !showResults && "flex-col"
        )}
      >
        <div
          className={cn(
            "h-full w-full",
            !showResults 
              ? "flex flex-col justify-center max-w-[862px] mx-auto" 
              : "px-6 max-w-[1200px] mx-auto"
          )}
        >
          <SearchTitle showResults={showResults} />
          <Card className="mx-auto flex flex-col justify-between w-full ">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex w-full p-2 items-center"
              >
                <Search className="ml-1" size="20px" />
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Input
                          placeholder="Type any biology question to find datasets"
                          className="border-0 text-xl focus-visible:ring-0"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <SubmitButton isLoading={isLoading}>
                  Search
                  <ArrowRight />
                </SubmitButton>
              </form>
            </Form>
          </Card>
          <SearchFilter
            dataType={dataType}
            species={species}
            institutions={institutions}
            filter={filters}
            setFilter={setFilters}
            resetFilter={() => setFilters(EMPTY_FILTER)}
          />
          {/* TODO uncomment when proper end-point available */}
          {/* <SearchSuggestions
            suggestions={suggestions}
            onSelect={(item) => form.setValue("message", item)}
          /> */}
          {data &&
            (data?.relevant_datasets?.length ? (
              <div className="mt-5">
                {data.relevant_datasets.length} relevant datasets found
              </div>
            ) : (
              <div className="flex flex-col items-center py-12">
                <SearchX size="40px" className="text-blue-600 mb-4" />
                <div className="font-semibold text-xl mb-2">
                  No datasets match your search
                </div>
                <div className="text-sm">
                  Try adjusting your keywords, removing filters, or searching
                  something else.
                </div>
              </div>
            ))}
          {error ? (
            <Alert variant="destructive" className="mt-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                Something went wrong with your search. Our support team has been
                notified and will investigate the issue.
              </AlertDescription>
            </Alert>
          ) : (
            <SearchResults
              relevantDatasets={data?.relevant_datasets}
              onCommentClick={handleCommentClick}
              onRankingClick={handleRankingClick}
            />
          )}
          {showResults && isLoading && <Loader />}
        </div>
        <SearchSidebar
          onChange={closeSidebar}
          opened={opened}
          setOpened={setOpened}
          activeDatasetId={activeDatasetId}
          items={[
            data?.relevant_datasets?.length
              ? {
                  icon: <Sparkles />,
                  title: "Alchemy copilot",
                  callBack: () => {
                    if (!showCopilot) closeSidebar();
                    setShowCopilot(!showCopilot);
                  },
                }
              : null,
            {
              icon: <FileClock />,
              title: "Search history",
              element: (
                <SearchHistory
                  setHistoryValue={(item) => form.setValue("message", item)}
                />
              ),
            },

            // TODO uncomment when proper functionality available
            // showResults
            //   ? {
            //       icon: <MessageSquare />,
            //       title: "Comments",
            //       element: null,
            //     }
            //   : null,
            // showResults
            //   ? {
            //       icon: <ChartBar />,
            //       title: "Metrics",
            //       element: null,
            //     }
            //   : null,
          ]}
        />
        {!showResults && !showCopilot && (
          <TrendingItems
            showResults={showResults}
            trendingDatasets={trendingDatasets}
          />
        )}
      </div>
      {showCopilot && (
        <SearchCopilot
          setShowCopilot={() => setShowCopilot(!showCopilot)}
          query={form.getValues("message")}
          datasetIds={data?.relevant_datasets?.map(({ id }) => id)}
        />
      )}
    </div>
  );
}
