import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { projectQueryKeys } from "./projectQueryKeys";
import { apiClient } from "@/api/apiClient";
import { ProjectModel } from "@/types/project-model";
import { Experiment, Study } from "@/types/study-model";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const fetchProjects = async () => {
  const response = await apiClient.get("/projects");
  return response.data;
};

const updateProjectFn = async (data) => {
  const response = await apiClient.put(`/projects/${data.id}`, data);
  return response.data;
};

export function useUpdateProject() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProjectFn,
    onSuccess: (data) => {
      if (data === undefined) return;

      toast.success("project successfully updated");

      void queryClient.invalidateQueries({
        queryKey: projectQueryKeys.experiment(data.id),
      });
      navigate("/projects");
    },
  });
}

export const fetchProjectById = async ({ params }) => {
  const response = await apiClient.get(`/projects/${params.id}`);
  return response.data;
};

const fetchExperimentById = async (id: number) => {
  const response = await apiClient.get(`/experiments/${id}`);
  return response.data;
};

const createStudyFn = async (data: Partial<Study>) => {
  const response = await apiClient.post("/studies", data);
  return response.data;
};

const updateExperiment = async (data) => {
  const response = await apiClient.put(`/experiments/${data.id}`, data);
  return response.data;
};

export function useUpdateExperiment() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateExperiment,
    onSuccess: (data) => {
      if (data === undefined) return;
      toast.success("Experiment updated");

      void queryClient.invalidateQueries({
        queryKey: projectQueryKeys.experiment(data.id),
      });
      navigate(-1);

      return data;
    },
  });
}

export function useCreateStudy() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: createStudyFn,
    onSuccess: (data) => {
      if (data === undefined) return;

      toast.success("Study created");
      navigate(`/projects/${data.project_id}`);
    },
  });
}

const createExperimentFn = async (data: Partial<Experiment>) => {
  const response = await apiClient.post("/experiments", data);
  return response.data;
};

export function useCreateExperiment() {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: createExperimentFn,
    onSuccess: (data) => {
      if (data === undefined) return;

      toast.success("Experiment created");
      navigate(-1);
    },
  });
}

export function useProjectById(id: number) {
  return useQuery<ProjectModel>({
    queryKey: projectQueryKeys.detail(id),
    queryFn: () => fetchProjectById(id),
    enabled: !!id,
  });
}

export function useExperimentById(id: number) {
  return useQuery<Experiment>({
    queryKey: projectQueryKeys.experiments(),
    queryFn: () => fetchExperimentById(id),
    enabled: !!id,
  });
}
