import { Link } from "react-router-dom";
import { FolderClosed, ChevronLeft, ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import Tag from "@/components/shared/Tag";
import { useRef, useState, useEffect } from "react";

const TrendingListItem = ({ item, style }) => (
  <Link
    to={item.link}
    className="block border p-3 rounded-md bg-primary-foreground hover:opacity-70 min-w-[280px] max-w-[280px] flex-shrink-0"
    style={style}
  >
    <div className="text-black font-medium w-full truncate">{item.text}</div>
    <div className="text-sm text-gray-600 mt-1 line-clamp-2">{item.description}</div>
    <div className="flex mt-4 space-x-2 flex-wrap">
      <Tag className="bg-blue-50 text-primary border-primary">
        {item.source}
      </Tag>
    </div>
  </Link>
);

export default function TrendingItems({ showResults }) {
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [visibleItems, setVisibleItems] = useState({ start: 0, end: 3 });
  const [displayItems, setDisplayItems] = useState([]);

  const trendingDatasets = [
    {
      text: "Tahoe-100M Single-Cell Drug Perturbation Atlas",
      description: "~100 million single-cell gene expression profiles across 50 cancer cell lines, under 1,100 small-molecule drug perturbations.",
      source: "Arc Institute & Vevo",
      link: "https://arcinstitute.org/news/news/arc-vevo"
    },
    {
      text: "AlphaFold Protein Structure Database",
      description: "Over 200 million protein structures covering virtually every protein known to science.",
      source: "DeepMind & EMBL-EBI",
      link: "https://deepmind.google/discover/blog/alphafold-reveals-the-structure-of-the-protein-universe/#:~:text=Today%2C%20I%E2%80%99m%20incredibly%20excited%20to,increase%20our%20understanding%20of%20biology"
    },
    {
      text: "Human Pangenome Reference",
      description: "A collection of 94 distinct human genome sequences from diverse ancestries, adding over 100 million bases of novel DNA.",
      source: "HPRC",
      link: "https://www.nature.com/articles/s41586-022-04601-8"
    },
    {
      text: "Telomere-to-Telomere Complete Genome",
      description: "First complete human genome sequence with no gaps, resolving the final ~8% of human DNA sequence.",
      source: "T2T Consortium",
      link: "https://www.genome.gov/about-genomics/telomere-to-telomere"
    },
    {
      text: "UK Biobank Whole-Genome Sequencing",
      description: "500,000 whole human genomes paired with rich phenotype data from UK Biobank participants.",
      source: "UK Biobank",
      link: "https://www.ukbiobank.ac.uk/learn-more-about-uk-biobank/news/whole-genome-sequencing-data-on-200-000-uk-biobank-participants-available-now"
    },
    {
      text: "All of Us Precision Medicine Dataset",
      description: "Nearly 100,000 whole-genome sequences with linked health records from diverse populations.",
      source: "NIH",
      link: "https://www.researchallofus.org/"
    },
    {
      text: "Tabula Sapiens Multi-Organ Cell Atlas",
      description: "Atlas of nearly 500,000 cells profiled from 24 tissues and organs of adult humans.",
      source: "CZI/HCA",
      link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC9812260/#S16"
    },
    {
      text: "CPTAC Pan-Cancer Proteogenomic Atlas",
      description: "1,000+ tumor samples from 10 cancer types analyzed with deep proteomics, transcriptomics, and genomics.",
      source: "NCI",
      link: "https://proteomic.datacommons.cancer.gov/pdc/cptac-pancancer"
    },
    {
      text: "MICrONS Brain Connectomics Dataset",
      description: "Petabyte-scale dataset mapping a cubic millimeter of mouse brain cortex with EM and functional imaging.",
      source: "IARPA",
      link: "https://alleninstitute.github.io/microns_tutorial/em_01_background.html"
    },
    {
      text: "JUMP-Cell Painting Morphology Dataset",
      description: "~136,000 perturbations applied to human cells, with 3+ million high-resolution images of cellular phenotypes.",
      source: "Broad/Industry",
      link: "https://jump-cellpainting.broadinstitute.org/"
    }
  ];

  useEffect(() => {
    // Only show 3 items at a time
    setDisplayItems(trendingDatasets.slice(visibleItems.start, visibleItems.end));
    
    // Update scroll buttons
    setCanScrollLeft(visibleItems.start > 0);
    setCanScrollRight(visibleItems.end < trendingDatasets.length);
  }, [visibleItems]);

  const scroll = (direction) => {
    if (direction === 'left') {
      const newStart = Math.max(0, visibleItems.start - 3);
      setVisibleItems({ 
        start: newStart, 
        end: Math.min(newStart + 3, trendingDatasets.length) 
      });
    } else {
      const newStart = Math.min(trendingDatasets.length - 3, visibleItems.start + 3);
      setVisibleItems({ 
        start: newStart, 
        end: Math.min(newStart + 3, trendingDatasets.length) 
      });
    }
  };

  // Initialize on component mount
  useEffect(() => {
    setDisplayItems(trendingDatasets.slice(0, 3));
    setCanScrollLeft(false);
    setCanScrollRight(trendingDatasets.length > 3);
  }, []);

  return (
    <Card className={showResults ? "mt-24 ml-5 max-w-[900px]" : "mt-5 mx-auto max-w-[900px]"}>
      <CardHeader className="flex-row items-center justify-between p-3">
        <div className="flex items-center space-x-2">
          <FolderClosed size="20px" />
          <CardTitle className="text-sm">Trending Datasets</CardTitle>
        </div>
        <div className="flex space-x-1">
          <button 
            onClick={() => scroll('left')} 
            disabled={!canScrollLeft}
            className={`p-1 rounded-full ${canScrollLeft ? 'hover:bg-gray-200' : 'opacity-50 cursor-not-allowed'}`}
          >
            <ChevronLeft size={16} />
          </button>
          <button 
            onClick={() => scroll('right')} 
            disabled={!canScrollRight}
            className={`p-1 rounded-full ${canScrollRight ? 'hover:bg-gray-200' : 'opacity-50 cursor-not-allowed'}`}
          >
            <ChevronRight size={16} />
          </button>
        </div>
      </CardHeader>
      <CardContent className="p-3 pt-0">
        <div className="flex justify-between space-x-4">
          {displayItems.map((item, i) => (
            <TrendingListItem 
              key={i} 
              item={item}
              style={{}} 
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
