import { X } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import CopilotSummary from "@/components/copilot/CopilotSummary";
import CopilotAnalysis from "@/components/copilot/CopilotAnalysis";
import CopilotChat from "@/components/copilot/CopilotChat";

export enum StreamStepsEnum {
  summary = "SUMMARY",
  analyze = "ANALYZE",
  suggestions = "SUGGESTIONS",
  chat = "CHAT",
}

export default function SearchCopilot({ setShowCopilot, query, datasetIds }) {
  const sessionId = uuidv4();

  return (
    <div className="w-full h-full p-5 bg-primary-foreground ml-5 border-l flex flex-col  overflow-auto relative">
      <div className="flex items justify-between py-3">
        <span className="font-medium">Alchemy copilot</span>
        <X
          className="cursor-pointer hover:opacity-70"
          onClick={setShowCopilot}
        />
      </div>
      <div className="flex-1 overflow-auto space-y-2">
        <div className="text-sm font-medium">Results summary</div>
        <CopilotSummary
          sessionId={sessionId}
          query={query}
          datasetIds={datasetIds}
        />
        <div className="text-sm font-medium">Alchemy analysis</div>
        <CopilotAnalysis
          sessionId={sessionId}
          query={query}
          datasetIds={datasetIds}
        />
        <CopilotChat
          sessionId={sessionId}
          query={query}
          datasetIds={datasetIds}
        />
      </div>
    </div>
  );
}
