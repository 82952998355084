import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";
import { useNavigate } from "react-router-dom";
import PlaceholderComponent from "@/components/PlaceholderComponent";

const TABLE_HEADERS = [
  { key: "title", label: "Title", className: "w-[250px]" },
  { key: "organism", label: "Organism", className: "" },
  { key: "source", label: "Source", className: "" },
  { key: "alchemyScore", label: "Alchemy Score", className: "" },
  { key: "category", label: "Category", className: "" },
  { key: "technique", label: "Technique", className: "" },
  { key: "fileType", label: "File Type", className: "" },
  { key: "peerReviewed", label: "Peer-reviewed", className: "" },
  { key: "publicationDate", label: "Publication Date", className: "" },
];

export const tagColors = {
  genome: "bg-blue-100 text-blue-800",
  human: "bg-green-100 text-green-800",
  dna: "bg-yellow-100 text-yellow-800",
  drosophila: "bg-purple-100 text-purple-800",
  insect: "bg-pink-100 text-pink-800",
  "covid-19": "bg-red-100 text-red-800",
  "clinical-trials": "bg-indigo-100 text-indigo-800",
  virus: "bg-orange-100 text-orange-800",
  microbiome: "bg-teal-100 text-teal-800",
  ocean: "bg-cyan-100 text-cyan-800",
  ecology: "bg-lime-100 text-lime-800",
  cancer: "bg-rose-100 text-rose-800",
  genomics: "bg-sky-100 text-sky-800",
  oncology: "bg-amber-100 text-amber-800",
};

// Helper functions
function getScoreColor(score: number | null | undefined) {
  if (score === null || score === undefined) return "bg-gray-100 text-gray-600";
  if (score >= 90) return "bg-green-100 text-green-800";
  if (score >= 70) return "bg-yellow-100 text-yellow-800";
  return "bg-red-100 text-red-800";
}

function getAlchemyScoreColor(score: number | null | undefined) {
  if (score === null || score === undefined) return "bg-gray-100 text-gray-600";
  return "bg-purple-100 text-purple-800";
}

function getFileTypeFromS3Key(s3Key: string | undefined): string {
  if (!s3Key) return "-";
  const extension = s3Key.split(".").pop();
  return extension || "-";
}

function safeValue(value: any, defaultValue: any = "-", isAlchemyScore: boolean = false) {
  if (value === null || value === undefined) return defaultValue;
  if (isAlchemyScore && typeof value === "number") {
    return Math.round(value);
  }
  return value;
}

function formatNumber(value: number | null | undefined) {
  return typeof value === "number" ? value.toLocaleString() : "-";
}

export function DataTable({ datasets }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="rounded-lg border border-gray-200 bg-white overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow className="bg-black hover:bg-black">
              {TABLE_HEADERS.map((header) => (
                <TableHead 
                  key={header.key} 
                  className="text-white font-medium py-2.5 text-sm"
                >
                  {header.label}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {datasets.map((dataset, index) => (
              <TableRow
                key={dataset?.id || index}
                onClick={() => dataset?.id && navigate(`/datasets/${dataset.id}`)}
                className="cursor-pointer hover:bg-gray-50 transition-colors"
              >
                <TableCell className="py-4 text-sm">
                  {safeValue(dataset?.title, "Untitled Dataset")}
                </TableCell>
                <TableCell className="py-4 text-sm">
                  {safeValue(dataset?.organism)}
                </TableCell>
                <TableCell className="py-4 text-sm">
                  {safeValue(dataset?.source)}
                </TableCell>
                <TableCell className="py-4 text-sm">
                  {dataset?.alchemyScore ? (
                    <span className={`px-3 py-1 rounded-full ${getAlchemyScoreColor(dataset?.alchemyScore)}`}>
                      {Math.round(dataset.alchemyScore)}
                    </span>
                  ) : (
                    <span className="text-gray-500">N/A</span>
                  )}
                </TableCell>
                <TableCell className="py-4 text-sm">
                  {safeValue(dataset?.category)}
                </TableCell>
                <TableCell className="py-4 text-sm">
                  {safeValue(dataset?.technique)}
                </TableCell>
                <TableCell className="py-4 text-sm">
                  {getFileTypeFromS3Key(dataset?.s3_key)}
                </TableCell>
                <TableCell className="py-4 text-sm">
                  {dataset?.peerReviewed ? (
                    <span className="px-2 py-1 rounded-full text-xs bg-green-100 text-green-800">
                      Yes
                    </span>
                  ) : (
                    <span className="px-2 py-1 rounded-full text-xs bg-red-100 text-red-800">
                      No
                    </span>
                  )}
                </TableCell>
                <TableCell className="py-4 text-sm">
                  {safeValue(dataset?.publicationDate)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {!datasets.length && (
        <PlaceholderComponent
          title="No datasets"
          subTitle="Click the button to upload your first dataset"
          buttonText="Upload New Dataset"
          link="/datasets/upload"
        />
      )}
    </>
  );
}
