import Balancer from "react-wrap-balancer";
import AnimatedSearchBar from "./animated-search-bar";


const Hero = ({
  title = "AI-first exploration for biological datasets",
  subtitle = "Search, analyze, and collaborate on curated biological data, faster than ever!",
  showSearch = true,
}) => {
  return (
    <section className="w-screen min-h-[80vh] mx-[-50vw] ml-[calc(-50vw+50%)] mr-[calc(-50vw+50%)] text-center relative overflow-hidden flex items-center">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-white"></div>
      <div className="container w-full max-w-[1200px] mx-auto px-[clamp(1.25rem,3vw,3rem)] py-[clamp(4rem,10vh,6rem)] relative z-10">

        <Balancer>
          <h1 className="text-[clamp(2.5rem,5vw,3.5rem)] leading-tight mb-6 max-w-[800px] mx-auto bg-gradient-to-r from-[#569EFD] to-[#254cf6] bg-clip-text text-transparent font-semibold pt-20 tracking-tight">
            {title}
          </h1>
        </Balancer>
        <Balancer>
          <p className="text-[clamp(1.1rem,2.5vw,1.35rem)] text-gray-600 mb-12 max-w-[600px] mx-auto leading-relaxed">
            {subtitle}
          </p>
        </Balancer>
        {showSearch && <AnimatedSearchBar />}
        <div className="pt-5">
          <a href="/register">
            <button className="bg-gradient-to-r from-[#569EFD] to-[#254cf6] text-white py-4 px-8 rounded-lg border-none font-medium cursor-pointer transition-all duration-200 relative overflow-hidden hover:transform hover:-translate-y-0.5 hover:shadow-lg">
              Try Alchemy Now
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
