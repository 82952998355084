import { useState } from "react";
import { StatusEnum } from "@/types/project-model";
import { GeneralInfo } from "@/types/study-model";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import SubmitButton from "@/components/shared/SubmitButton";
import { CollaboratorSelect } from "@/components/projects/collaborator-select";
import { useAction } from "@/api/apiClient";
import { formatProjectToRequest } from "@/lib/utils";

interface ProjectProps {
  children: JSX.Element;
  project?: any;
}

const formSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().min(1, "Description is required"),
  status: z.nativeEnum(StatusEnum),
  isPublic: z.boolean(),
  collaborators: z.number().array(),
});

export default function ProjectForm({ project, children }: ProjectProps) {
  const [open, setOpen] = useState(false);
  // Check if we're editing an existing project or creating a new one
  const isEditMode = Boolean(project); // `project` exists if we're in edit mode

  const { action, isLoading, error } = useAction({
    // Define the API endpoint based on the mode (edit or create)
    url: isEditMode ? `/projects/${project.id}` : "/projects",
    // Define the HTTP method based on the mode
    method: isEditMode ? "put" : "post",
    // Define success and error messages dynamically based on whether it's edit or create
    success: `Project successfully ${isEditMode ? "updated" : "created"}`,
    error: `Failed to ${isEditMode ? "update" : "create"} project`,
    callback: () => {
      setOpen(false);
    },
    refreshPage: true,
  });

  const initialValues: GeneralInfo = {
    status: project?.status || StatusEnum.NotStarted,
    description: project?.description || "",
    title: project?.title || "",
    isPublic: project?.isPublic || false,
    collaborators: project?.collaborators || [],
  };
  const form = useForm<GeneralInfo>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    reValidateMode: "onChange",
  });

  const onSubmit = (data: GeneralInfo) => action(formatProjectToRequest(data));

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{project ? "Edit" : "New"} Project</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Project Title</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="w-full"
                      placeholder="Enter project title"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Project Description</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      className="w-full resize-none"
                      placeholder="Enter project description"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <FormField
                control={form.control}
                name="status"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Project Status</FormLabel>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select status" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.values(StatusEnum).map((status) => (
                          <SelectItem key={status} value={status}>
                            {status.replace(/([A-Z])/g, " $1").trim()}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="isPublic"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Visibility</FormLabel>
                    <Select
                      value={field.value ? "Public" : "Private"}
                      onValueChange={(value) =>
                        field.onChange(value === "Public")
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select visibility">
                          {field.value ? "Public" : "Private"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Private">Private</SelectItem>
                        <SelectItem value="Public">Public</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="w-full">
              <CollaboratorSelect control={form.control} />
            </div>
            <DialogFooter>
              <SubmitButton isLoading={isLoading}>Save</SubmitButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
