import { useState } from "react";

const defaultFeatureData = [
  {
    id: 1,
    text: "Standardized datasets",
    image: "/icons/lightning.svg",
    description: "Clean, analysis-ready biological data in one place",
    comingSoon: false,
  },
  {
    id: 2,
    text: "Context-rich metadata",
    image: "/icons/cycle.svg",
    description: "Protocols, conditions, and study details included",
    comingSoon: false,
  },
  {
    id: 3,
    text: "Ask and explore",
    image: "/icons/bulb.svg",
    description: "Natural language search across diverse datasets",
    comingSoon: false,
  },
  {
    id: 4,
    text: "Cite and trace",
    image: "/icons/books.svg",
    description: "Inline citations with every insight",
    comingSoon: false,
  },
  {
    id: 5,
    text: "Collaborate easily",
    image: "/icons/team.svg",
    description: "Shared views and synced analysis for teams",
    comingSoon: true,
  },
  {
    id: 6,
    text: "Visualize deeply",
    image: "/icons/chart.svg",
    description: "Transform complex data into clear, actionable charts",
    comingSoon: false,
  },
];

const Features = ({
  title = "Everything in one place!",
  subtitle,
  data = defaultFeatureData,
  showComingSoon = true,
}) => {
  const [activeFeature, setActiveFeature] = useState(1);

  return (
    <section className="w-screen ml-[calc(-50vw+50%)] mr-[calc(-50vw+50%)] bg-white relative py-16">
      <div className="w-full max-w-[1200px] mx-auto px-[clamp(1.25rem,3vw,3rem)] flex flex-col items-center">
        <h2 className="text-[clamp(1.5rem,4vw,2.5rem)] leading-tight mb-8 text-center font-semibold">{title}</h2>
        {subtitle && <p className="text-[clamp(1.1rem,2vw,1.25rem)] text-[#6B7280] text-center mb-12">{subtitle}</p>}
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 w-full">
          {data.map((feature) => (
            <div
              key={feature.id}
              className={`bg-white p-8 rounded-2xl shadow-sm border border-[#E5E7EB] transition-all duration-300 cursor-pointer relative ${
                activeFeature === feature.id ? "border-[#569EFD] shadow-lg" : ""
              } hover:transform hover:-translate-y-1 hover:shadow-lg hover:border-[#569EFD]`}
              onClick={() => setActiveFeature(feature.id)}
            >
              <div className="w-12 h-12 flex items-center justify-center rounded-xl mb-4 transition-all duration-300">
                <img
                  src={feature.image}
                  alt={feature.text}
                  className="w-6 h-6 object-contain transition-all duration-300 hover:scale-110"
                />
              </div>
              <h4 className="text-xl mb-4 text-[#1F2937] font-semibold tracking-tight">{feature.text}</h4>
              <p className="text-[#6B7280] leading-relaxed">{feature.description}</p>
              {showComingSoon && feature.comingSoon && (
                <div className="absolute top-4 right-4 bg-[rgba(86,158,253,0.1)] border border-[rgba(86,158,253,0.2)] rounded-full py-1 px-3 flex items-center gap-2 overflow-hidden z-10">
                  <span className="text-xs font-medium text-[#569EFD] relative z-20">Coming Soon</span>
                  <div className="absolute top-0 left-[-100%] w-full h-full bg-gradient-to-r from-transparent via-[rgba(86,158,253,0.2)] to-transparent animate-[badgeGlow_2s_infinite]"></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
