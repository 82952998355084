import { Bell } from "lucide-react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { useQuery } from "@tanstack/react-query"
import { apiClient } from "@/api/apiClient"
import { Skeleton } from "@/components/ui/skeleton"

interface Notification {
  type: string;
  message: string;
  timestamp: string;
}

export function NotificationsArea() {
  const { data, isLoading } = useQuery({
    queryKey: ['bdsi', 'notifications'],
    queryFn: async () => {
      const { data } = await apiClient.get<Notification[]>('/bdsi/notifications');
      return data.map(n => ({
        ...n,
        timestamp: new Date(n.timestamp)
      }));
    },
    // Disable the query until the API endpoint is implemented
    enabled: false
  });

  if (isLoading) {
    return (
      <Card className="bg-gradient-to-br from-background to-secondary/10">
        <CardHeader>
          <CardTitle className="flex items-center">
            <Bell className="mr-2 h-5 w-5 text-primary" />
            Notifications
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Skeleton className="h-[150px] w-full" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="bg-gradient-to-br from-background to-secondary/10">
      <CardHeader>
        <CardTitle className="flex items-center">
          <Bell className="mr-2 h-5 w-5 text-primary" />
          Notifications
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col items-center justify-center py-8 text-center">
          <p className="text-sm text-muted-foreground">
            No notifications yet. You'll see updates here when people interact with your datasets.
          </p>
        </div>
      </CardContent>
    </Card>
  );
}