import { SquareChevronLeft, SearchX } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";

export default function NotFound() {
  return (
    <div className="w-full py-5 h-full flex flex-col justify-center items-center space-y-2 text-xl">
      <SearchX size="120px" className="text-blue-600" />
      404 Page not found
      <Link to="/">
        <Button className="bg-blue-600 rounded-full">
          <SquareChevronLeft />
          Back home
        </Button>
      </Link>
    </div>
  );
}
