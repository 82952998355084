import { Skeleton } from "@/components/ui/skeleton";
import { Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
interface LoaderProps {
  size?: string;
  variant?: "spin" | "pulse" | "fullScreen";
  color?: string;
}

export default function Loader(props: LoaderProps) {
  const { size = "sm", variant = "pulse", color } = props;

  const config = {
    sm: {
      size: "h-20 w-20",
      color: "black",
    },
    md: {
      size: "h-20 w-20",
      color: "black",
    },
    lg: {
      size: "h-20 w-20",
      color: "gray",
    },
  };

  if (variant === "pulse")
    return (
      <div className="space-y-2 p-2">
        <Skeleton
          className={cn("w-full h-4 rounded-sm", color && `bg-${color}`)}
        />
        <Skeleton
          className={cn("w-3/4 h-4 rounded-sm", color && `bg-${color}`)}
        />
        <Skeleton
          className={cn("w-full h-4 rounded-sm", color && `bg-${color}`)}
        />
      </div>
    );
  if (variant === "fullScreen")
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center ">
        <img src="/assets/logo.svg" alt="Alchemy" className="w-80" />
        <div className="mt-6">
          <Loader2 className="mr-2 h-10 w-10 animate-spin" color="#126DE7" />
        </div>
      </div>
    );

  return (
    <Loader2
      className={cn(
        "mr-2 h-8 w-8 animate-spin text-primary",
        color && `text-${color}`
      )}
    />
  );
}
