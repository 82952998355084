import  {FC} from 'react';
import {Dataset} from "@/types/study-model";
import {useNavigate} from "react-router-dom";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/table";

interface IProps {
    datasets: Dataset[]
}

const DATASET_HEADERS = [
    {key: 'title', label: 'Title', className: 'w-[200px] font-bold'},
    {key: 'description', label: 'Description', className: 'font-bold'},
];
const ExperimentDatasetTable: FC<IProps> = ({datasets}) => {
    const navigate = useNavigate();

    return (
        <div className="rounded-md border shadow-sm overflow-hidden">
            <Table>
                <TableHeader>
                    <TableRow className="bg-muted/50">
                        {DATASET_HEADERS.map((header) => (
                            <TableHead key={header.key} className={header.className}>
                                {header.label}
                            </TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {datasets.map((dataset, index) => (
                        <TableRow
                            key={dataset.id}
                            onClick={() => navigate(`/datasets/${dataset.id}`)}
                            className={`cursor-pointer hover:bg-muted/50 transition-colors ${index % 2 === 0 ? 'bg-muted/20' : ''}`}
                        >
                            <TableCell className="font-medium">{dataset.title}</TableCell>
                            <TableCell>
                                <p>{dataset.attributes?.description || 'No description available'}</p>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default ExperimentDatasetTable;