import { useQuery } from "@tanstack/react-query";
import { apiClient } from "@/api/apiClient";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { HelpCircle } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Progress } from "@/components/ui/progress";

interface ScoreDetail {
  name: string;
  value: number;
  max: number;
}

interface CategoryBreakdownProps {
  title: string;
  score: number;
  details: ScoreDetail[];
}

interface ScoreBreakdownData {
  documentation_score: number;
  quality_score: number;
  impact_score: number;
  documentation_details: ScoreDetail[];
  quality_details: ScoreDetail[];
  impact_details: ScoreDetail[];
}

function CategoryBreakdown({ title, score, details }: CategoryBreakdownProps) {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium">{title} ({Math.round(score)}%)</h3>
      </div>
      <div className="grid gap-1">
        {details.map((detail) => (
          <div key={detail.name} className="flex items-center justify-between text-sm">
            <span className="text-muted-foreground">{detail.name}</span>
            <span>{Math.round(detail.value)}/{detail.max}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export function ScoreBreakdown() {
  const { data, isLoading } = useQuery({
    queryKey: ['bdsi', 'score-breakdown'],
    queryFn: async () => {
      const { data } = await apiClient.get<ScoreBreakdownData>('/bdsi/score-breakdown');
      return data;
    }
  });

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Score Breakdown</CardTitle>
        </CardHeader>
        <CardContent>
          <Skeleton className="h-[300px] w-full" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Score Breakdown</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-6 md:grid-cols-3">
        <CategoryBreakdown 
          title="Documentation" 
          score={data?.documentation_score ?? 0} 
          details={data?.documentation_details ?? []} 
        />
        <CategoryBreakdown 
          title="Quality" 
          score={data?.quality_score ?? 0} 
          details={data?.quality_details ?? []} 
        />
        <CategoryBreakdown 
          title="Impact" 
          score={data?.impact_score ?? 0} 
          details={data?.impact_details ?? []} 
        />
      </CardContent>
    </Card>
  );
}