import {useQuery} from "@tanstack/react-query";
import { apiClient } from "@/api/apiClient";
import {labelsQueryKeys} from "@/api/labels/labelsQueryKeys";
import {Label} from "@/types/study-model";

const fetchAllLabels = async () => {
    const response = await apiClient.get('/labels')
    return response.data
}


export function useAllLabels() {
    return useQuery<Label[]>({
        queryFn: fetchAllLabels,
        networkMode: "online",
        queryKey: labelsQueryKeys.all
    })
}