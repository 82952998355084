import { Card, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import SubmitButton from "@/components/shared/SubmitButton";

export const Confirmation = ({
  formData,
  setShowConfirmation,
  submitForm,
  isLoading,
}) => {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-10 text-center">
        Confirm Submission
      </h1>
      <div className="space-y-2">
        <Card>
          <CardHeader className="[&>p]:flex [&>p]:flex-wrap [&>p]:justify-between [&>p>span]:text-gray-500">
            <span className="font-medium">Dataset Information</span>
            <p>
              <span>Dataset Name:</span> {formData.datasetName}
            </p>
            <p>
              <span>Principal Investigator:</span> {formData.investigator}
            </p>
            <p>
              <span>Lab/Institution:</span> {formData.institution}
            </p>
            <p>
              <span>Dataset Description:</span> {formData.description}
            </p>
            <p>
              <span>Contact Email:</span> {formData.email}
            </p>
          </CardHeader>
        </Card>
        <Card>
          <CardHeader className="[&>p]:flex [&>p]:flex-wrap [&>p]:justify-between [&>p>span]:text-gray-500">
            <span className="font-medium">Data Classification</span>
            <p>
              <span>Category:</span> {formData.category}
            </p>
            <p>
              <span>Subcategory:</span> {formData.subcategory}
            </p>
            <p>
              <span>Technique:</span> {formData.technique}
            </p>
          </CardHeader>
        </Card>
        <Card>
          <CardHeader className="[&>p]:flex [&>p]:flex-wrap [&>p]:justify-between [&>p>span]:text-gray-500">
            <span className="font-medium">Organism & Sample Information</span>

            <p>
              <span>Organism:</span>{" "}
              {formData.organism === "Other"
                ? formData.otherOrganism
                : formData.organism}
            </p>
            <p>
              <span>Sample Details:</span> {formData.sampleDetails}
            </p>
          </CardHeader>
        </Card>
        <Card>
          <CardHeader className="[&>p]:flex [&>p]:flex-wrap [&>p]:justify-between [&>p>span]:text-gray-500">
            <span className="font-medium">Uploaded Files</span>
            <p>
              <span>Data Files:</span>{" "}
              {formData.dataFiles ? (
                <span className="text-primary font-medium">File uploaded ✓</span>
              ) : (
                "No file uploaded"
              )}
            </p>
            {/* TODO: add methods file upload */}
            {/* <p>
              <span>Methods File:</span>{" "}
              {formData.methodsFile ? (
                <span className="text-primary font-medium">File uploaded ✓</span>
              ) : (
                "No file uploaded"
              )}
            </p> */}
            <p>
              <span>Create DOI:</span> {formData.createDOI ? "Yes" : "No"}
            </p>{" "}
          </CardHeader>
        </Card>
      </div>
      <div className="mt-8 flex justify-between">
        <Button onClick={() => setShowConfirmation(false)} variant="outline">
          Edit Submission
        </Button>
        <SubmitButton onClick={submitForm} isLoading={isLoading}>
          Submit
        </SubmitButton>
      </div>
    </div>
  );
};
