import { useState } from "react";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import SlackJoin from "./SlackJoin";
import { apiClient, API_METHODS } from "@/api/apiClient";

interface AccountModalProps {
  user: any;
  isOpen: boolean;
  onClose: () => void;
}

export default function AccountModal({ user, isOpen, onClose }: AccountModalProps) {
  const [activeTab, setActiveTab] = useState<"profile" | "password">("profile");
  const [formData, setFormData] = useState({
    fullName: user?.firstName + " " + user?.lastName || "",
    username: user?.username || "",
    email: user?.email || "",
    role: user?.role || "Researcher",
  });
  
  // Add state to track password fields
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  
  // Add state for loading and error
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  
  // Handler for password fields
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({ ...prev, [name]: value }));
  };

  // Check if password fields have values
  const hasPasswordChanged = () => {
    return passwordData.currentPassword.length > 0 && 
           passwordData.newPassword.length > 0 && 
           passwordData.confirmPassword.length > 0;
  };

  const handleSave = async () => {
    // Validate passwords
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError("New passwords don't match");
      setSuccessMessage("");
      return;
    }
    
    if (passwordData.newPassword.length < 8) {
      setError("New password must be at least 8 characters");
      setSuccessMessage("");
      return;
    }
    
    setIsLoading(true);
    setError("");
    setSuccessMessage("");
    
    try {
      // Use apiClient instead of fetch
      const response = await apiClient.post("/auth/change-password", {
        current_password: passwordData.currentPassword,
        new_password: passwordData.newPassword,
      });
      
      // Reset form
      setPasswordData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      
      // Set success message
      setSuccessMessage("Password changed successfully");
      
    } catch (err) {
      // Improved error handling
      console.error("Password change error:", err);
      
      // Handle different types of errors more gracefully
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(err.response.data?.detail || "Server error: " + err.response.status);
      } else if (err.request) {
        // The request was made but no response was received
        setError("No response from server. Please check your connection.");
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("An error occurred: " + (err.message || "Unknown error"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-xl relative overflow-hidden flex flex-col">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-medium">Account</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <div className="flex flex-1">
          <div className="w-2/5 border-r flex flex-col justify-between">
            <div>
              <div 
                className={`py-5 px-6 flex items-center gap-3 cursor-pointer ${
                  activeTab === "profile" 
                    ? "bg-blue-50 text-blue-600 font-medium" 
                    : "hover:bg-gray-50"
                }`}
                onClick={() => setActiveTab("profile")}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke={activeTab === "profile" ? "#2563EB" : "#000000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22" stroke={activeTab === "profile" ? "#2563EB" : "#000000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className="text-sm font-medium">Profile</span>
              </div>
              <div 
                className={`py-5 px-6 flex items-center gap-3 cursor-pointer ${
                  activeTab === "password" 
                    ? "bg-blue-50 text-blue-600 font-medium" 
                    : "hover:bg-gray-50"
                }`}
                onClick={() => setActiveTab("password")}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke={activeTab === "password" ? "#2563EB" : "#000000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke={activeTab === "password" ? "#2563EB" : "#000000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className="text-sm font-medium">Password</span>
              </div>
            </div>
            
            <div className="p-6">
              <div className="bg-blue-50 py-2 px-4 rounded-md flex items-center gap-2">
                <SlackJoin text="Slack Community" />
              </div>
            </div>
          </div>

          <div className="w-3/5 flex flex-col">
            <div className="p-6 flex-1">
              {activeTab === "profile" && (
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium mb-2">Full name</label>
                    <Input
                      name="fullName"
                      value={formData.fullName}
                      placeholder="Full name"
                      className="w-full bg-gray-100"
                      readOnly
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">Username</label>
                    <Input
                      name="username"
                      value={formData.username}
                      placeholder="Username"
                      className="w-full bg-gray-100"
                      readOnly
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">Email</label>
                    <Input
                      name="email"
                      value={formData.email}
                      placeholder="Email"
                      type="email"
                      className="w-full bg-gray-100"
                      readOnly
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">Role</label>
                    <Input
                      name="role"
                      value={formData.role}
                      placeholder="Role"
                      className="w-full bg-gray-100"
                      readOnly
                    />
                  </div>
                </div>
              )}

              {activeTab === "password" && (
                <div className="space-y-6">
                  {error && (
                    <div className="bg-red-50 text-red-600 p-3 rounded-md text-sm">
                      {error}
                    </div>
                  )}
                  {successMessage && (
                    <div className="bg-green-50 text-green-600 p-3 rounded-md text-sm">
                      {successMessage}
                    </div>
                  )}
                  <div>
                    <label className="block text-sm font-medium mb-2">Current Password</label>
                    <Input
                      name="currentPassword"
                      type="password"
                      placeholder="Current password"
                      className="w-full"
                      value={passwordData.currentPassword}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">New Password</label>
                    <Input
                      name="newPassword"
                      type="password"
                      placeholder="New password"
                      className="w-full"
                      value={passwordData.newPassword}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">Confirm New Password</label>
                    <Input
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm new password"
                      className="w-full"
                      value={passwordData.confirmPassword}
                      onChange={handlePasswordChange}
                    />
                  </div>
                </div>
              )}
            </div>
            
            <div className="p-6 flex justify-end">
              {activeTab === "password" && (
                <Button 
                  onClick={handleSave} 
                  className="px-8"
                  disabled={!hasPasswordChanged() || isLoading}
                >
                  {isLoading ? "Changing..." : "Change Password"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}