import { useState } from "react";
import { ThumbsUp, ThumbsDown, X } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/api/apiClient";

interface CopilotFeedbackProps {
  messageId: string;
  sessionId: string;
  query: string;
  response: string;
}

export default function CopilotFeedback({ messageId, sessionId, query, response }: CopilotFeedbackProps) {
  const [feedback, setFeedback] = useState<"positive" | "negative" | null>(null);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [reason, setReason] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState(false);

  const handleFeedback = async (type: "positive" | "negative") => {
    setFeedback(type);
    
    if (type === "negative") {
      setShowFeedbackForm(true);
    } else {
      // Submit positive feedback immediately
      await submitFeedback(type);
      setSubmitted(true);
    }
  };

  const submitFeedback = async (type: "positive" | "negative", feedbackReason?: string) => {
    try {
      await apiClient.post("/copilot/feedback", {
        message_id: messageId,
        session_id: sessionId,
        query,
        response,
        feedback_type: type,
        feedback_reason: feedbackReason || null
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleReasonSubmit = async (selectedReason: string) => {
    setReason(selectedReason);
    await submitFeedback("negative", selectedReason);
    setShowFeedbackForm(false);
    setSubmitted(true);
  };

  const handleClose = () => {
    setShowFeedbackForm(false);
  };

  if (submitted) {
    return (
      <div className="flex items-center text-xs text-gray-500 mt-1">
        Thank you for your feedback
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {!showFeedbackForm ? (
        <div className="flex items-center gap-2 mt-1">
          <button
            onClick={() => handleFeedback("positive")}
            className={cn(
              "p-1 rounded hover:bg-gray-100",
              feedback === "positive" && "text-green-500"
            )}
            aria-label="Thumbs up"
          >
            <ThumbsUp size={16} />
          </button>
          <button
            onClick={() => handleFeedback("negative")}
            className={cn(
              "p-1 rounded hover:bg-gray-100",
              feedback === "negative" && "text-red-500"
            )}
            aria-label="Thumbs down"
          >
            <ThumbsDown size={16} />
          </button>
        </div>
      ) : (
        <div className="mt-2 p-3 border rounded-md bg-gray-50">
          <div className="flex justify-between items-center mb-2">
            <h4 className="text-sm font-medium">What didn't you like about this response (optional)?</h4>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <X size={16} />
            </button>
          </div>
          <div className="flex gap-2">
            <Button
              variant="outline"
              size="sm"
              className="text-xs"
              onClick={() => handleReasonSubmit("Inaccurate / Misleading answer")}
            >
              Inaccurate / Misleading answer
            </Button>
            <Button
              variant="outline"
              size="sm"
              className="text-xs"
              onClick={() => handleReasonSubmit("Not helpful answer")}
            >
              Not helpful answer
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}