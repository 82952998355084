import Footer from "@/components/landing/footer";

const PrivacyPolicy = () => {
  return (
    <div className="app">
      <main className="legal-content">
        <div className="container">
          <h1>Privacy Policy</h1>
          <div className="content-wrapper">
            <div className="legal-content-inner">
              <p className="last-updated">Last updated: [Date]</p>

              <div className="legal-section">
                <h2>1. Introduction</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>

              <div className="legal-section">
                <h2>2. Information We Collect</h2>
                <p>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>

              <div className="legal-section">
                <h2>3. How We Use Your Information</h2>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur.
                </p>
              </div>

              <div className="legal-section">
                <h2>4. Data Security</h2>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </div>

              <div className="legal-section">
                <h2>5. Contact Us</h2>
                <p>
                  For any questions about this Privacy Policy, please contact us
                  at privacy@alchemybio.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
