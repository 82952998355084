import { memo } from "react";
import { Outlet, useLoaderData } from "react-router-dom";
import { Header } from "@/components/datasets/header";
import { MetricCards } from "@/components/datasets/metric-cards";
import { TableFilters } from "@/components/datasets/table-filters";
import { DataTable } from "@/components/datasets/datatable";

// Memoize the header component
const MemoizedHeader = memo(Header);

export default function DatasetDashboard() {
  return (
    <div className="min-h-screen bg-white">
      <MemoizedHeader />
      <main className="max-w-screen-xl mx-auto px-4">
        <Outlet />
      </main>
    </div>
  );
}

export function DatasetOverview() {
  const { datasets, metrics } = useLoaderData() as any;
  return (
    <>
      <h1 className="text-2xl font-bold mb-6">My Datasets</h1>
      <MetricCards metrics={metrics} />
      <div className="flex justify-between items-center mb-4">
        <TableFilters />
        <span className="text-sm text-gray-500">
          Showing 1-{datasets.length} of {datasets.length} datasets
        </span>
      </div>
      <DataTable datasets={datasets} />
    </>
  );
}
