import {FC, useEffect, useState} from 'react';
import {Card} from "@/components/ui/card";
import {Input} from "@/components/ui/input";
import {UseFormReturn} from "react-hook-form";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {StatusEnum} from "@/types/project-model";
import {GeneralInfo} from "@/types/study-model";
import {FormControl, FormField, FormItem, FormLabel} from "@/components/ui/form";
import {MultiSelect} from "@/components/ui/multi-select";
import {useAllUsers} from "@/api/user/users";
import {Textarea} from "@/components/ui/textarea";

interface IProps {
    form: UseFormReturn<GeneralInfo & any, any, undefined>;
    prefix: string,
}

const GeneralForm: FC<IProps> = ({prefix, form}) => {

    const [collaborators, setCollaborators] = useState<string[]>([]);
    const {data: users, isLoading, isError} = useAllUsers()

    const formCollaborators = form.watch('collaborators');

    const status = form.watch('status')
    const is_public = form.watch('is_public')

    const userList = (isLoading || isError) ? [] : users.map((user) => {
        return {
            value: user.id.toString(),
            label: user.username
        }
    });

    useEffect(() => {
        if (users) {
            const mappedCollaborators = formCollaborators?.map((el) => el.toString());
            setCollaborators(mappedCollaborators || []);
        }
    }, [users, formCollaborators]);

    return (
        <Card className={'p-6 shadow-sm bg-gray-50'}>
            <h3 className={'font-semibold text-xl'}>General</h3>

            <div className={'flex gap-6 mt-12'}>

                <div className={'flex flex-col [&_label]:text-gray-500 gap-3 w-1/2'}>
                    <FormField control={form.control}
                        name={'title'}
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>
                                    {prefix} Title
                                </FormLabel>
                                <FormControl>
                                    <Input {...field} placeholder={'Enter'}/>
                                </FormControl>
                            </FormItem>
                        )}
                    />
                    <FormField control={form.control}
                        name={'description'}
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>
                                    {prefix} Description
                                </FormLabel>
                                <FormControl>
                                    <Textarea {...field} placeholder={'Enter'}/>
                                </FormControl>
                            </FormItem>
                        )}
                    />
                </div>

                <div className={'flex flex-col gap-3 [&_p]:text-gray-500 w-1/2'}>
                    <div className={'flex gap-3'}>
                        <div className={'flex flex-col space-y-2 w-1/2'}>
                            <p>{prefix} Status</p>
                            <Select
                                value={status}
                                onValueChange={(e) => {
                                    form.setValue('status', e as StatusEnum)
                                }}
                            >
                                <SelectTrigger className="w-40">
                                    <SelectValue placeholder="Select status"/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem value={StatusEnum.NotStarted}>Not Started</SelectItem>
                                        <SelectItem value={StatusEnum.InProgress}>In Progress</SelectItem>
                                        <SelectItem value={StatusEnum.Done}>Done</SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className={'flex flex-col space-y-2'}>
                            <p>{prefix} Type</p>
                            <Select
                                value={is_public ? "Public" : "Private"}
                                onValueChange={(value) => {
                                    form.setValue('is_public', value === "Public");
                                }}
                            >
                                <SelectTrigger className="w-40">
                                    <SelectValue placeholder="Select status"/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem value={'Private'}>Private</SelectItem>
                                        <SelectItem value={'Public'}>Public</SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>

                    <div className={'space-y-2 flex flex-col'}>
                        <p>
                            Invite Collaborators
                        </p>
                        <MultiSelect
                            options={userList}
                            onValueChange={(data) => {
                                const newCollaboratorsId = data
                                form.setValue('collaborators', newCollaboratorsId.map(Number))
                                setCollaborators(newCollaboratorsId)
                            }}
                            value={collaborators}
                            placeholder="Enter username or Email"
                            variant={'secondary'}
                            animation={3}
                        />
                    </div>
                </div>

            </div>
        </Card>
    );
};

export default GeneralForm;