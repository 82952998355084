import {FC, useState} from 'react';
import {Card} from "@/components/ui/card";
import {Input} from "@/components/ui/input";
import {UseFormReturn} from "react-hook-form";
import {Button} from "@/components/ui/button";
import {X} from "lucide-react";
import {Category, ExperimentDetailsUpdateDTO, Label, Procedure} from "@/types/study-model";
import {FormControl, FormField, FormItem, FormLabel} from "@/components/ui/form";
import {Textarea} from "@/components/ui/textarea";
import {MultiSelect} from "@/components/ui/multi-select";
import {useAllCategories} from "@/api/categories/categories";
import {useAllLabels} from "@/api/labels/labels";

interface IProps {
    form: UseFormReturn<ExperimentDetailsUpdateDTO & any, any, undefined>;
}

const DetailsForm: FC<IProps> = ({form}) => {

    const [temporaryProcedure, setTemporaryProcedure] = useState('')

    const {data: categories, isError: isCategoriesError, isLoading: isCategoriesLoading} = useAllCategories()
    const {data: labels, isError: isLabelsError, isLoading: isLabelsLoading} = useAllLabels()

    form.watch('procedures')
    const proceduresArray = form.getValues('procedures') as Procedure[]


    const labelsOptions = (isLabelsError || isLabelsLoading) ? [] : getOptions(labels)

    const categoriesOptions = (isCategoriesError || isCategoriesLoading) ? [] : getOptions(categories)

    const addProceduresHandler = () => {
        if (temporaryProcedure === '') return

        form.setValue('procedures', [
            ...proceduresArray, {
                content: temporaryProcedure,
                position: ++proceduresArray.length
            }])
        setTemporaryProcedure('')
    }

    const deleteProcedures = (position: number) => {
        form.setValue('procedures', proceduresArray.filter(el => el.position !== position))
    }

    return (
        <Card className={'p-6 shadow-sm bg-gray-50'}>
            <h3 className={'font-semibold text-xl'}>Details</h3>
            <div className={'flex gap-6 mt-12 items-baseline [&_p]:text-gray-500 [&_label]:text-gray-500'}>
                <div className={'w-1/2 flex flex-col gap-3'}>
                    <FormField control={form.control}
                               name={'hypothesis'}
                               render={({field}) => (
                                   <FormItem>
                                       <FormLabel>
                                           Hypothesis
                                       </FormLabel>
                                       <FormControl>
                                           <Textarea {...field} placeholder={'Enter'}/>
                                       </FormControl>
                                   </FormItem>
                               )}
                    />
                    <div className={'flex flex-col space-y-2 w-full'}>
                        <p>Categories</p>
                        <MultiSelect options={categoriesOptions}
                                     variant={'secondary'}
                                     placeholder={'Enter'}
                                     value={form.getValues('categories').map(String)}
                                     onValueChange={(data => {
                                         form.setValue('categories', data.map(Number))
                                         console.log(data)
                                     })}/>
                    </div>
                    <div className={'flex flex-col space-y-2 w-full'}>
                        <p>Labels</p>
                        <MultiSelect options={labelsOptions}
                                     placeholder={'Enter'}
                                     value={form.getValues('labels').map(String)}
                                     onValueChange={(data => {
                                         form.setValue('labels', data.map(Number))
                                     })}
                        />
                    </div>
                </div>
                <div className={'flex flex-col w-1/2 space-y-2'}>
                    <p>Procedure</p>
                    <div className={'flex gap-3'}>
                        <Input placeholder={'Enter'} value={temporaryProcedure} onChange={(e) => {
                            setTemporaryProcedure(e.target.value)
                        }}/>
                        <Button type={'button'} onClick={addProceduresHandler}>
                            Add
                        </Button>
                    </div>
                    <div className={'flex flex-col gap-2 mt-2 '}>
                        {proceduresArray.length > 0 &&
                            proceduresArray.map(({content, position}) => {
                                return <div key={position}
                                            className={'flex items-center justify-between rounded-3xl bg-gray-100 px-3 py-2'}>
                                    <p>{content}</p>
                                    <X className={'cursor-pointer'} size={16} color={'gray'} onClick={() => {
                                        deleteProcedures(position)
                                    }}/>
                                </div>
                            })}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default DetailsForm;


function getOptions(data: Label[] | Category[]) {
    return data.map(value => ({
        value: value.id.toString(),
        label: value.name
    }))

}