import { useQuery } from "@tanstack/react-query";
import { apiClient } from "@/api/apiClient";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

interface DatasetPerformance {
  dataset_name: string;
  upload_date: string;
  index_score: number;
  downloads: number;
  citations: number;
  user_interactions: number;
  trending: boolean;
}

export function DatasetPerformanceTable() {
  const { data, isLoading } = useQuery({
    queryKey: ['bdsi', 'dataset-performance'],
    queryFn: async () => {
      const { data } = await apiClient.get<DatasetPerformance[]>('/bdsi/dataset-performance');
      return data;
    }
  });

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Dataset Performance</CardTitle>
        </CardHeader>
        <CardContent>
          <Skeleton className="h-[400px] w-full" />
        </CardContent>
      </Card>
    );
  }

  const hasData = data && data.length > 0;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Dataset Performance</CardTitle>
      </CardHeader>
      <CardContent>
        {hasData ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Dataset</TableHead>
                <TableHead>Upload Date</TableHead>
                <TableHead>Index Score</TableHead>
                <TableHead>Downloads</TableHead>
                <TableHead>Citations</TableHead>
                <TableHead>Interactions</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((dataset) => (
                <TableRow key={dataset.dataset_name}>
                  <TableCell>{dataset.dataset_name}</TableCell>
                  <TableCell>{new Date(dataset.upload_date).toLocaleDateString()}</TableCell>
                  <TableCell>{dataset.index_score.toFixed(2)}</TableCell>
                  <TableCell>{dataset.downloads}</TableCell>
                  <TableCell>{dataset.citations}</TableCell>
                  <TableCell>{dataset.user_interactions}</TableCell>
                  <TableCell>
                    {dataset.trending && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Trending
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="text-center text-muted-foreground py-8">
            No dataset performance data available
          </div>
        )}
      </CardContent>
    </Card>
  );
}