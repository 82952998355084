import { useEffect, useState } from "react";
import Loader from "@/components/Loader";
import { useStream } from "@/api/apiClient";
import { StreamStepsEnum } from "@/components/copilot/SearchCopilot";

export default function CopilotSummary({ sessionId, query, datasetIds }) {
  const [summary, setSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const streamData = useStream(); // Get the streamData function from the hook
  
  useEffect(() => {
    // Only fetch if summary is empty and datasetIds is available
    if (summary.length === 0 && datasetIds && datasetIds.length > 0) {
      setIsLoading(true);
      streamData({
        url: "/copilot/send",
        data: {
          session_id: sessionId,
          query: query,
          step: StreamStepsEnum.summary,
          dataset_ids: datasetIds,
        },
        callBack: (message) => {
          setSummary((messages) => [...messages, message]);
        },
        onFinish: () => setIsLoading(false),
      });
    }
  }, [sessionId, query, datasetIds, summary.length, streamData]);

  return (
    <div className="text-sm space-y-1">
      {summary.map((item, i) => (
        <p key={i} className="leading-6">
          {item}
        </p>
      ))}
      {isLoading && <Loader color="gray-300" />}
    </div>
  );
}
