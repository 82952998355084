import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import SearchComments from "@/components/search/SearchComments";
import SearchMetrics from "@/components/search/SearchMetrics";
import { UserCredits } from "@/components/user/Credits";

const itemClassName =
  "hover:opacity-70 cursor-pointer rounded-full p-2 bg-white text-primary";

export default function SearchSidebar({
  items,
  onChange,
  opened,
  setOpened,
  activeDatasetId,
}) {
  const sidebarItems = items.map((item) => {
    if (item) {
      if (item.title === "Comments" && activeDatasetId) {
        return {
          ...item,
          element: <SearchComments datasetId={activeDatasetId} />,
        };
      }
      if (item.title === "Metrics" && activeDatasetId) {
        return {
          ...item,
          element: <SearchMetrics datasetId={activeDatasetId} />,
        };
      }
      return item;
    }
  });

  return (
    <div className="absolute top-7 right-7 z-20 flex space-x-2">
      <UserCredits />
      {sidebarItems.map(
        (item, i) =>
          item &&
          (item.element ? (
            <TooltipProvider key={i}>
              <Tooltip>
                <TooltipTrigger>
                  <Sheet>
                    <SheetTrigger asChild>
                      <div className={itemClassName}>{item.icon}</div>
                    </SheetTrigger>
                    <SheetContent>
                      <SheetHeader>
                        <SheetTitle>{item.title}</SheetTitle>
                      </SheetHeader>
                      {item.element}
                    </SheetContent>
                  </Sheet>
                </TooltipTrigger>
                <TooltipContent side="left">{item.title}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <TooltipProvider key={i}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div
                    className={itemClassName}
                    key={i}
                    onClick={() => item.callBack()}
                  >
                    {item.icon}
                  </div>
                </TooltipTrigger>
                <TooltipContent side="left">{item.title}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))
      )}
    </div>
  );
}
