import { useEffect } from "react";

export default function MailchimpForm() {
  useEffect(() => {
    // Add Mailchimp validation script
    const script = document.createElement("script");
    script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // @ts-ignore - Mailchimp adds these to window
      window.fnames = new Array();
      window.ftypes = new Array();
      window.fnames[0] = 'EMAIL';
      window.ftypes[0] = 'email';
      // @ts-ignore
      var $mcj = jQuery.noConflict(true);
    };

    return () => {
      // Cleanup script on component unmount
      const scriptElement = document.querySelector(`script[src="${script.src}"]`);
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
    };
  }, []);

  return (
    <div className="w-full max-w-md">
      <form 
        action="https://bio.us9.list-manage.com/subscribe/post?u=4a15fcc41152619e583a84578&amp;id=ccb411b90a&amp;f_id=0088c2e1f0" 
        method="post" 
        id="mc-embedded-subscribe-form" 
        name="mc-embedded-subscribe-form" 
        className="validate flex flex-col gap-4" 
        target="_blank"
      >
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <input 
              type="email" 
              name="EMAIL" 
              className="flex-1 h-12 px-4 rounded-md border border-input bg-background outline-none focus:ring-2 focus:ring-primary/50" 
              id="mce-EMAIL" 
              required 
              placeholder="Enter your email"
            />
            <input 
              type="submit" 
              name="subscribe" 
              id="mc-embedded-subscribe" 
              className="h-12 px-6 font-semibold rounded-md bg-primary text-primary-foreground hover:bg-primary/90 cursor-pointer whitespace-nowrap"
              value="Subscribe for updates"
            />
          </div>
          
          {/* These are required for Mailchimp functionality */}
          <div id="mce-responses" className="clear">
            <div className="response text-sm" id="mce-error-response" style={{ display: 'none' }}></div>
            <div className="response text-sm text-green-600" id="mce-success-response" style={{ display: 'none' }}></div>
          </div>
          
          {/* Bot protection - required */}
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input type="text" name="b_4a15fcc41152619e583a84578_ccb411b90a" tabIndex={-1} />
          </div>
        </div>
      </form>
    </div>
  );
}
