import { ReactNode } from "react";

interface Message {
  author: string;
  date: string;
  text: ReactNode;
}

export const messages: Message[] = [
  {
    author: "ai",
    date: "10:15 AM",
    text: "Iʼm your dataset search assistant. What datasets do you want to find today?",
  },
  {
    author: "user",
    date: "10:15 AM",
    text: "I performed an RNA sequencing experiment using the Smartseq-2 protocol on C57BL/6 male and female mice aged 1, 3, 6, 9, 12, 15, 18, 21, 24, and 27mos old, on organs including brain, heart, lung, liver, spleen, kidney, intestine, muscle, bone, bone marrow, skin, brown adipose tissue, gonadal adipose tissue, subcutaneous adipose tissue, and mesenteric adipose tissue. Find me the datasets that most closely match this experiment, but exclude all datasets that only contain a single organ type.",
  },
];

export const historyItems = [
  { id: 131313, text: "Original query," },
  {
    id: 342424,
    text: "Species:",
  },
  { id: 867987, text: "Year" },
  { id: 867987, text: "Data type" },
];

export const dataType = [
  {
    name: "RNA-seq",
    quantity: 344,
  },
  {
    name: "scRNA-seq",
    quantity: 582,
  },
  {
    name: "Proteomics",
  },
];
export const species = [
  {
    name: "C57BL/6J",
  },
  {
    name: "C57BL6",
  },
  {
    name: "Fischer 344 rats",
  },
  {
    name: "Human",
    quantity: 220,
  },
  {
    name: "Mus musculus",
    quantity: 956,
  },
];
export const suggestions = [
  "I performed an RNA sequencing experiment...",
  "Mouse aging RNA-seq atlas",
  "ChIP-seq data H3K27me3 embryonic stem cells",
  "Proteomics data breast cancer cell lines",
];
export const searchResults = {
  total: 3601,
  results: [
    {
      id: "adkajd",
      title:
        "Single-Cell Transcriptional Profiling Reveals Sex and Age Diversity of Gene Expression in Mouse Endothelial Cells",
      description:
        "This paper analyzes sex and age differences in gene expression of endothelial cells across multiple mouse organs, but does not contain the full dataset described in the query.",
      authors: ["author1", "au2", "au3"],
      // for collections
      dataTypes: "type1, type2",
      datasetsAmount: 4,
      updated: "20.03.2024",
      downloadLink: "/eqeq/qeqe",
      link: "/sffds/fsfs/sfsf",
    },
  ],
};

export const institutions = [
  {
    id: "i1",
    name: "United States Department of Health and Human Services",
    quantity: 29,
  },
  {
    id: "i2",
    name: "National Institutes of Health",
    quantity: 28,
  },
  {
    id: "i3",
    name: "Inserm",
    quantity: 28,
  },
  {
    id: "i4",
    name: "Centre National de la Racherche Scientifique",
    quantity: 18,
  },
  {
    id: "i5",
    name: "Harvard University",
    quantity: 17,
  },
  {
    id: "i6",
    name: "University of California, San Francisco",
    quantity: 17,
  },
  {
    id: "i7",
    name: "University of Oxford",
    quantity: 17,
  },
  {
    id: "i8",
    name: "Institut des Sciences Biologiques",
    quantity: 17,
  },
];

export const trendingDatasets = [
  {
    text: "Proteomics data breast cancer cell lines",
    link: "",
    source: "geo",
    rankingScore: "73",
  },
  {
    text: "ChIP-seq data H3K27me3 embryonic stem cells",
    link: "",
    source: "geo",
    rankingScore: "73",
  },
  {
    text: "Mouse aging RNA-seq atlas",
    link: "",
    source: "geo",
    rankingScore: "73",
  },
];
