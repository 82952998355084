import Header from "@/components/landing/header";
import Footer from "@/components/landing/footer";

const TermsAndConditions = () => {
  return (
    <div className="app">
      <Header />

      <main className="legal-content">
        <div className="container">
          <h1>Terms and Conditions</h1>
          <div className="content-wrapper">
            <div className="legal-content-inner">
              <p className="last-updated">Last updated: [Date]</p>

              <div className="legal-section">
                <h2>1. Agreement to Terms</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>

              <div className="legal-section">
                <h2>2. Use License</h2>
                <p>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>

              <div className="legal-section">
                <h2>3. User Responsibilities</h2>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur.
                </p>
              </div>

              <div className="legal-section">
                <h2>4. Intellectual Property</h2>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </div>

              <div className="legal-section">
                <h2>5. Limitation of Liability</h2>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium.
                </p>
              </div>

              <div className="legal-section">
                <h2>6. Contact Information</h2>
                <p>
                  For any questions about these Terms and Conditions, please
                  contact us at legal@alchemybio.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;
