import { createRef, useEffect, useState } from "react";
import { SendHorizontal } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import SubmitButton from "@/components/shared/SubmitButton";
import { cn } from "@/lib/utils";
import { useStream } from "@/api/apiClient";
import Loader from "@/components/Loader";
import { RenderChart } from "@/components/copilot/CopilotAnalysis";
import { StreamStepsEnum } from "@/components/copilot/SearchCopilot";
import { v4 as uuidv4 } from "uuid";
import CopilotFeedback from "@/components/copilot/CopilotFeedback";

export default function CopilotChat({
  sessionId,
  query,
  datasetIds,
  hideFeedback = false,
}) {
  const toScroll = createRef();
  const [suggestions, setSuggestions] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageId, setMessageId] = useState(uuidv4());
  const streamData = useStream();

  useEffect(() => {
    if (toScroll.current) {
      toScroll.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    // Create a flag to track if the component is mounted
    let isMounted = true;

    // Only make the API call if datasetIds is available and not empty
    if (datasetIds && datasetIds.length > 0) {
      // Call streamData with the appropriate parameters
      streamData({
        url: "/copilot/send",
        data: {
          session_id: sessionId,
          query: query,
          step: StreamStepsEnum.suggestions,
          dataset_ids: datasetIds,
        },
        callBack: (message) => {
          // Only update state if the component is still mounted
          if (isMounted) {
            setSuggestions(JSON.parse(message)?.suggestions);
          }
        },
      });

      // Return cleanup function
      return () => {
        isMounted = false;
      };
    }
  }, [sessionId, query, datasetIds, streamData]);

  const formSchema = z.object({
    message: z.string(),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
    },
  });

  function onSubmit({ message }) {
    const messageId = uuidv4();
    setMessages((current) => [
      ...current,
      { author: "user", message, id: messageId },
    ]);
    setIsLoading(true);
    form.setValue("message", "");

    let isCancelled = false;

    // Make sure datasetIds is available before making the API call
    if (datasetIds && datasetIds.length > 0) {
      streamData({
        url: "/copilot/send",
        data: {
          session_id: sessionId,
          query: query,
          dataset_ids: datasetIds,
          step: "CHAT",
          message,
        },
        callBack: (res) => {
          if (isCancelled) return;

          try {
            const chart = JSON.parse(res);
            setMessages((current) => [
              ...current.filter(
                (msg) => msg.author !== "copilot" || msg.userQuery !== message
              ),
              {
                author: "copilot",
                message: <RenderChart plotData={chart?.plot_specs} />,
                id: uuidv4(),
                originalMessage: res,
                userQuery: message,
              },
            ]);
          } catch (error) {
            setMessages((current) => [
              ...current.filter(
                (msg) => msg.author !== "copilot" || msg.userQuery !== message
              ),
              {
                author: "copilot",
                message: res,
                id: uuidv4(),
                originalMessage: res,
                userQuery: message,
              },
            ]);
          }
        },
        onFinish: () => {
          if (!isCancelled) {
            setIsLoading(false);
          }
        },
      });
    } else {
      // If datasetIds is not available, show an error message
      setMessages((current) => [
        ...current,
        {
          author: "copilot",
          message:
            "Sorry, I couldn't process your request because no datasets were selected.",
          id: uuidv4(),
          originalMessage: "Error: No datasets selected",
          userQuery: message,
        },
      ]);
      setIsLoading(false);
    }

    // Return a cleanup function that can be called if needed
    return () => {
      isCancelled = true;
    };
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-2">
        {messages.map((item, i) => (
          <div
            className={cn(
              "border p-2 bg-white rounded-lg w-fit text-sm",
              item.author === "user" &&
                "self-end bg-blue-100 text-blue-800 border-blue-200"
            )}
            key={i}
          >
            {item.message}
          </div>
        ))}
      </div>
      {isLoading && (
        <div className="w-3/4 border p-2 bg-white rounded-lg">
          <Loader />
        </div>
      )}
      <div className="space-y-2 pb-28" ref={toScroll}>
        {suggestions.map((item, i) => (
          <div
            className="border border-primary px-3 py-2 text-xs text-primary bg-blue-50 rounded-lg cursor-pointer hover:opacity-70 flex justify-between"
            onClick={() => onSubmit({ message: item })}
            key={i}
          >
            <p className="w-11/12">{item}</p>
            <SendHorizontal size="14px" />
          </div>
        ))}
      </div>
      <div className="absolute left-5 right-5 bottom-5 bg-primary-foreground pt-3">
        {/* Global feedback above the input */}
        <div className="mb-3">
          <CopilotFeedback
            messageId={messageId}
            sessionId={sessionId}
            query={query}
            response="Complete copilot session"
          />
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full items-center bg-white border rounded-lg"
          >
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input
                      placeholder="Search something..."
                      className="border-0 focus-visible:ring-0"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <SubmitButton
              isLoading={isLoading}
              className="bg-transparent text-primary hover:bg-transparent hover:opacity-70 hover:border-transparent"
            >
              {!isLoading && <SendHorizontal />}
            </SubmitButton>
          </form>
        </Form>
      </div>
    </div>
  );
}
