import { useState, memo } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import SubmitButton from "@/components/shared/SubmitButton";
import { useAction } from "@/api/apiClient";
import { cn } from "@/lib/utils";
import { CheckCircle2, Coins } from "lucide-react";

const creditsList = [
  {
    number: 500,
    value: 20,
    productId: "prod_RsBVE2Ii1TtXaw",
    name: "Standard",
    description: "Perfect for occasional use",
  },
  {
    number: 1000,
    value: 37,
    productId: "prod_RsBWsqLP8FMYNb",
    name: "Professional",
    description: "Best value for regular users",
    popular: true,
  },
];

function CreditPlan({ plan, selected, onSelect }) {
  return (
    <div
      className={cn(
        "border rounded-lg p-5 hover:border-primary hover:bg-blue-50/30 cursor-pointer transition-all relative",
        selected && "border-primary border-2 bg-blue-50/40 shadow-sm"
      )}
      onClick={() => onSelect(plan)}
    >
      {plan.popular && (
        <div className="absolute -top-3 -right-2 bg-primary text-white text-xs px-3 py-1 rounded-full font-medium">
          Best Value
        </div>
      )}
      <div className="mb-2 font-semibold text-lg">{plan.name}</div>
      <div className="text-3xl font-bold mb-1">${plan.value}</div>
      <div className="bg-gray-100 text-center py-2 rounded-md mb-3 font-medium">
        {plan.number} credits
      </div>
      <div className="text-sm text-muted-foreground">{plan.description}</div>
      {selected && (
        <div className="absolute top-3 right-3 text-primary">
          <CheckCircle2 size={20} />
        </div>
      )}
    </div>
  );
}

function Credits({ credits, children }) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const { action, isLoading } = useAction({
    url: `/stripe/create-checkout-session`,
    error: "Failed to get payment link",
    callback: (data) => {
      if (data.checkout_url) {
        window.location.href = data.checkout_url;
      }
    },
  });

  const formSchema = z.object({
    product_id: z.string().min(1),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(data) {
    action({
      method: "POST",
      body: data,
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">
            Purchase Copilot Credits
          </DialogTitle>
          <DialogDescription>
            Add more credits to continue using Copilot's AI assistance
          </DialogDescription>
        </DialogHeader>

        <div className="flex items-center gap-2 bg-blue-50 p-3 rounded-lg mb-4">
          <div className="text-sm">Your balance:</div>
          <div className="font-bold text-lg">{credits} credits</div>
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="product_id"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {creditsList.map((plan, i) => (
                        <CreditPlan
                          key={i}
                          plan={plan}
                          selected={selected?.number === plan.number}
                          onSelect={(plan) => {
                            field.onChange(plan.productId);
                            setSelected(plan);
                          }}
                        />
                      ))}
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="flex flex-col gap-2 bg-gray-50 p-4 rounded-lg">
              <div className="flex justify-between items-center">
                <div className="text-sm font-medium">Credit conversion</div>
                <div className="font-bold">1 credit = 1 message</div>
              </div>
              <div className="text-xs text-muted-foreground">
                Credits are used each time you send a message to Copilot
              </div>
            </div>

            <div className="flex justify-end">
              <SubmitButton
                isLoading={isLoading}
                disabled={!selected}
                className="px-6 py-2 text-base"
              >
                Continue to checkout
              </SubmitButton>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const UserCredits = memo(function UserCredits() {
  return null
  //TODO: turn this back on
  return (
    <Credits credits={10}>
      <div className="flex items-center gap-1 bg-blue-50 text-primary px-4 py-2 rounded-full cursor-pointer hover:opacity-70">
        <Coins size="20px" />
        <span className="font-medium text-lg">{credits} credits</span>
      </div>
    </Credits>
  );
});
