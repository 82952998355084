const benefitsData = [
  {
    id: 1,
    title: "Structured data",
    description: "Say goodbye to scattered files",
    icon: "/icons/lightning.svg",
    comingSoon: false,
  },
  {
    id: 2,
    title: "AI-driven insights",
    description: "Ask complex questions, get research-grade answers.",
    icon: "/icons/bulb.svg",
    comingSoon: false,
  },
  {
    id: 3,
    title: "Open and Fair",
    description: "Built on open standards and reproducible science",
    icon: "/icons/cycle.svg",
    comingSoon: false,
  },
  {
    id: 4,
    title: "Time-saving workflows",
    description: "Prep and explore data in a fraction of the time.",
    icon: "/icons/lightning-speed.svg",
    comingSoon: false,
  },
  {
    id: 5,
    title: "Citable and Transparent",
    description: "Inline references with every output",
    icon: "/icons/books.svg",
    comingSoon: false,
  },
  {
    id: 6,
    title: "Collaboration ready",
    description: "Share datasets and work together seamlessly",
    icon: "/icons/team.svg",
    comingSoon: true,
  },
];

const Benefits = ({ showComingSoon = true }) => {
  return (
    <section className="w-screen ml-[calc(-50vw+50%)] mr-[calc(-50vw+50%)] bg-white relative">
      <div className="w-full max-w-[1200px] mx-auto px-[clamp(1.25rem,3vw,3rem)]">
        <h2 className="text-[clamp(1.5rem,4vw,2.5rem)] leading-tight mb-8 text-center font-semibold">
          Why researchers choose Alchemy
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefitsData.map((benefit) => (
            <div 
              key={benefit.id} 
              className="bg-white p-8 rounded-2xl shadow-sm border border-[#E5E7EB] transition-all duration-300 hover:transform hover:-translate-y-1 hover:shadow-lg hover:border-[#569EFD] h-full relative overflow-hidden"
            >
              <div className="mb-4 text-4xl">
                <img
                  src={benefit.icon}
                  alt={benefit.title}
                  className="w-6 h-6 object-contain transition-all duration-300 hover:scale-110"
                />
              </div>
              <h3 className="text-xl text-[#1F2937] mb-4 font-semibold tracking-tight">
                {benefit.title}
              </h3>
              <p className="text-[#6B7280] leading-relaxed">
                {benefit.description}
              </p>
              {showComingSoon && benefit.comingSoon && (
                <div className="absolute top-4 right-4 bg-[rgba(86,158,253,0.1)] border border-[rgba(86,158,253,0.2)] rounded-full py-1 px-3 flex items-center gap-2 overflow-hidden z-10">
                  <span className="text-xs font-medium text-[#569EFD] relative z-20">
                    Coming Soon
                  </span>
                  <div className="absolute top-0 left-[-100%] w-full h-full bg-gradient-to-r from-transparent via-[rgba(86,158,253,0.2)] to-transparent animate-[badgeGlow_2s_infinite]"></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
