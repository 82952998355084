import { createBrowserRouter, Navigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

// layouts
import LandingLayout from "@/pages/layouts/landing-layout";
import AuthenticatedLayout from "@/pages/layouts/authenticated";
import FormLayout from "@/pages/layouts/form-layout";

import Home from "@/pages/home";

import ConfirmEmail from "@/pages/confirm-email";
import ResetPassword from "@/pages/reset-password";

// auth
import SignUp from "@/pages/auth/sign-up";
import Login from "@/pages/auth/login";

// data
import DatasetDashboard, { DatasetOverview } from "@/pages/data";
import DatasetPage from "@/pages/data/[id]";
import DataUploadPage from "@/pages/data/upload";

// models
import ModelsPage from "@/pages/models";
import ModelsUploadPage from "@/pages/models/new";

// project pages
import ProjectsPage from "@/pages/projects";
import ProjectGet from "@/pages/projects/[projectId]";
import ProjectEdit from "@/pages/projects/[projectId]/edit.tsx";

// study pages
import StudyCreate from "@/pages/projects/[projectId]/study/create.tsx";
import StudyEdit from "@/pages/projects/[projectId]/study/[studyId]/edit.tsx";
import StudyGet from "@/pages/projects/[projectId]/study/[studyId]/get.tsx";

// experiment pages
import ExperimentCreate from "@/pages/projects/[projectId]/study/[studyId]/experiments/create.tsx";
import ExperimentEdit from "@/pages/projects/[projectId]/study/[studyId]/experiments/[experimentId]/edit.tsx";
import Experiment from "@/pages/projects/[projectId]/study/[studyId]/experiments/[experimentId]/get.tsx";

// BDSI pages
import BDSIPage from "@/pages/bdsi";

// Import landing pages
import LandingPage from "@/pages/landing";
import AboutUs from "@/pages/landing/about-us";
import HowItWorks from "@/pages/landing/how-it-works";
import PrivacyPolicy from "@/pages/landing/privacy-policy";
import TermsOfService from "@/pages/landing/terms-and-conditions";

// 404 error
import NotFound from "@/components/errors/NotFound";
import LoadingError from "@/components/errors/LoadingError";

import {
  fetchDatasets,
  fetchDatasetMetrics,
  fetchDataset,
} from "@/api/dataset/datasets";
import { fetchProjects, fetchProjectById } from "@/api/project/projects";
import { fetchAllModels } from "@/api/models/models";
import { getSearchData } from "@/api/search";

// Import the new discover page
import DiscoverPage from "@/pages/discover";

// Create a protected route component that uses useUser
const ProtectedRoute = ({ children }) => {
  const { isSignedIn, isLoaded } = useUser();
  
  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  
  if (!isSignedIn) {
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

function route(props) {
  return {
    ...props,
    errorElement: <LoadingError />,
  };
}

export const router = createBrowserRouter([
  // Landing pages
  {
    element: <LandingLayout />,
    children: [
      { path: "/", element: <LandingPage /> },
      { path: "/about", element: <AboutUs /> },
      { path: "/how-it-works", element: <HowItWorks /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "/terms-of-service", element: <TermsOfService /> },
      { path: "*", element: <NotFound /> },
    ],
  },

  // Auth routes - let Clerk handle these
  {
    children: [
      // Use a single component for all register paths and let Clerk handle internal routing
      { 
        path: "/register", 
        element: <SignUp />,
        // This prevents React Router from handling navigation to these paths
        children: [
          { path: "*", element: <SignUp /> }
        ]
      },
      { path: "/login",
        element: <Login />,
        children: [
          { path: "*", element: <Login /> }
        ]
      },
      { path: "/confirm-email", element: <ConfirmEmail /> },
      { path: "/reset-password/:key", element: <ResetPassword /> },
    ],
  },

  // Authenticated Routes - now using ProtectedRoute component instead of loader
  route({
    element: <ProtectedRoute><AuthenticatedLayout /></ProtectedRoute>,
    id: "userData",
    children: [
      { path: "/home", element: <Home />, loader: getSearchData },

      // Data Pages
      {
        path: "/library",
        element: <DatasetDashboard />,
        children: [
          route({
            index: true,
            element: <DatasetOverview />,
            loader: async () => {
              const datasets = await fetchDatasets();
              const metrics = await fetchDatasetMetrics();
              return { datasets, metrics };
            },
          }),
        ],
      },

      // Add the new discover page route
      { path: "/discover", element: <DiscoverPage /> },

      route({
        path: "/datasets/:id",
        element: <DatasetPage />,
        loader: fetchDataset,
      }),
      { path: "/datasets/upload", element: <DataUploadPage /> },

      route({
        path: "/models",
        element: <ModelsPage />,
        loader: fetchAllModels,
      }),
      { path: "/models/new", element: <ModelsUploadPage /> },

      // BDSI Pages
      { path: "/bdsi", element: <BDSIPage /> },

      // Projects Pages
      route({
        path: "/projects",
        element: <ProjectsPage />,
        loader: fetchProjects,
      }),
      route({
        path: "/projects/:id",
        element: <ProjectGet />,
        loader: fetchProjectById,
      }),
      { path: "/projects/:id/edit", element: <ProjectEdit /> },

      // Studies Pages
      { path: "/projects/:projectId/study/create", element: <StudyCreate /> },
      { path: "/projects/:projectId/study/:studyId", element: <StudyGet /> },
      {
        path: "/projects/:projectId/study/:studyId/edit",
        element: <StudyEdit />,
      },

      // Experiments Pages
      {
        path: "/projects/:id/study/:studyId/experiment/create",
        element: <ExperimentCreate />,
      },
      {
        path: "/projects/:id/study/:studyId/experiment/:experimentId",
        element: <Experiment />,
      },
      {
        path: "/projects/:id/study/:studyId/experiment/:experimentId/edit",
        element: <ExperimentEdit />,
      },
      { path: "*", element: <NotFound /> },
    ],
  }),
]);
