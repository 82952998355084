import { useProjectById, useUpdateProject } from "@/api/project/projects";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { GeneralInfo } from "@/types/study-model";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form";
import GeneralForm from "@/components/projects/experiment/general-form";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { StatusEnum } from "@/types/project-model";
import { Loader2 } from "lucide-react";
import SubmitButton from "@/components/shared/SubmitButton";

const ProjectEdit = () => {
  const { id } = useParams();

  const { data: project, isLoading, isError } = useProjectById(Number(id));

  const updateProject = useUpdateProject();
  const formSchema = z.object({
    title: z.string().min(1),
    description: z.string().min(1),
    status: z.nativeEnum(StatusEnum),
    is_public: z.boolean(),
    collaborators: z.number().array(),
  });

  const form = useForm<GeneralInfo>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      status: StatusEnum.NotStarted,
      collaborators: [],
      description: "",
      title: "",
      is_public: false,
    },
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (project) {
      form.reset({
        status: project.status,
        collaborators: project.collaborators.map((el) => el.id),
        description: project.description,
        title: project.title,
        is_public: project.is_public,
      });
    }
  }, [project]);

  const saveHandler = (data) => {
    updateProject.mutate({
      ...data,
      id,
    });
  };

  if (isLoading)
    return (
      <Loader2
        className="relative top-36 m-auto h-20 w-20 animate-spin"
        color="black"
      />
    );
  return (
    <div className={"container mx-auto p-4"}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(saveHandler)}>
          <div className={"flex justify-between mb-12"}>
            <h1 className="text-3xl font-bold">Edit Project</h1>
            <SubmitButton type={"submit"} isLoading={updateProject.isPending}>
              Save
            </SubmitButton>
          </div>
          <GeneralForm form={form} prefix={"Project"} />
        </form>
      </Form>
    </div>
  );
};

export default ProjectEdit;
