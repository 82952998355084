import { useState } from "react";
import { Send, X, MessageSquare, CheckCircle, AlertCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useFeedbackVisibility } from "@/context/FeedbackContext";
import { useSubmitFeedback } from "@/api/feedback";

export default function FeedbackButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState<{
    type: "success" | "error" | null;
    message: string;
  }>({ type: null, message: "" });
  
  const { submitFeedback, isLoading, isSuccess, error } = useSubmitFeedback();
  const { isVisible } = useFeedbackVisibility();

  const handleSubmit = () => {
    if (!message.trim()) return;
    
    setIsSubmitting(true);
    
    // Get current page URL
    const pageUrl = window.location.href;
    
    submitFeedback(
      { message, page_url: pageUrl },
      {
        onSuccess: () => {
          setMessage("");
          setNotification({
            type: "success",
            message: "Thank you for your feedback!",
          });
          setTimeout(() => {
            setIsOpen(false);
            setNotification({ type: null, message: "" });
          }, 3000);
        },
        onError: (error: any) => {
          setNotification({
            type: "error",
            message: error?.message || "Failed to send feedback. Please try again.",
          });
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      }
    );
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isOpen ? (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 w-80">
          <div className="flex justify-between items-center mb-3">
            <h3 className="font-medium">Send Feedback</h3>
            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  setIsOpen(false);
                  setMessage("");
                  setNotification({ type: null, message: "" });
                }}
                aria-label="Close feedback form"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          </div>

          {notification.type && (
            <Alert
              className={`mb-3 ${
                notification.type === "success"
                  ? "bg-green-50 text-green-800 dark:bg-green-900 dark:text-green-100"
                  : "bg-red-50 text-red-800 dark:bg-red-900 dark:text-red-100"
              }`}
            >
              {notification.type === "success" ? (
                <CheckCircle className="h-4 w-4 mr-2" />
              ) : (
                <AlertCircle className="h-4 w-4 mr-2" />
              )}
              <AlertDescription>{notification.message}</AlertDescription>
            </Alert>
          )}

          <Textarea
            placeholder="What's on your mind?"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mb-3 min-h-[100px]"
          />

          <Button
            onClick={handleSubmit}
            disabled={!message.trim() || isSubmitting}
            className="w-full"
          >
            {isSubmitting ? (
              "Sending..."
            ) : (
              <>
                <Send className="h-4 w-4 mr-2" /> Send Feedback
              </>
            )}
          </Button>
        </div>
      ) : (
        <Button
          onClick={() => setIsOpen(true)}
          variant="default"
          size="icon"
          className="rounded-full h-12 w-12 flex items-center justify-center shadow-lg"
        >
          <MessageSquare className="h-6 w-6" />
        </Button>
      )}
    </div>
  );
}