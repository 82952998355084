import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function trimText(text, length) {
  const textLength = text.length;
  return textLength > length ? text.slice(0, length) + "..." : text;
}

// Helper function to format bytes
export const formatBytes = (bytes: number) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export function getInitialsBackgroundColor(initials) {
  const hash = initials.reduce((acc, char) => acc + char.charCodeAt(0), 0); // Generate a value from initials
  const hue = hash % 360; // Convert the value to a number between 0 and 360 for HSL
  return `hsl(${hue}, 50%, 75%)`; // Light pastel colors
}

export function formatProjectToRender(project) {
  return {
    ...project,
    ownerId: project.owner_id,
    isPublic: project.is_public,
    createdAt: project.created_at,
  };
}

export function formatProjectToRequest(project) {
  return {
    ...project,
    owner_id: project.ownerId,
    is_public: project.isPublic,
  };
}
