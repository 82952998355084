import { useState, useEffect } from "react";

const queries = [
  "What genes are most studied in rare disorders?",
  "Show datasets using CRISPR in neurological disease.",
  "Summarize recent clinical trial findings in pediatric genomics",
];

export default function AnimatedSearchBar() {
  const [text, setText] = useState("");
  const [queryIndex, setQueryIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(100);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isDeleting) {
        // Typing
        if (text.length < queries[queryIndex].length) {
          setText(queries[queryIndex].slice(0, text.length + 1));
          setTypingSpeed(100);
        } else {
          // Pause at the end of typing
          setTypingSpeed(2000);
          setIsDeleting(true);
        }
      } else {
        // Deleting
        if (text.length > 0) {
          setText(text.slice(0, text.length - 1));
          setTypingSpeed(50);
        } else {
          setIsDeleting(false);
          setQueryIndex((prev) => (prev + 1) % queries.length);
          setTypingSpeed(500);
        }
      }
    }, typingSpeed);

    return () => clearTimeout(timer);
  }, [text, queryIndex, isDeleting, typingSpeed]);

  return (
    <div className="max-w-[600px] w-[90%] mx-auto relative">
      <div className="relative flex items-center bg-white rounded-xl border border-primary-200/20 shadow-[0_4px_20px_rgba(86,158,253,0.05),0_2px_8px_rgba(86,158,253,0.03),inset_0_0_0_1px_rgba(86,158,253,0.08)] transition-all duration-200 hover:border-primary-200/40 hover:shadow-[0_4px_20px_rgba(86,158,253,0.08),0_2px_8px_rgba(86,158,253,0.04),inset_0_0_0_1px_rgba(86,158,253,0.12)] focus-within:border-primary focus-within:shadow-[0_4px_20px_rgba(86,158,253,0.1),0_2px_8px_rgba(86,158,253,0.06),inset_0_0_0_1px_rgba(86,158,253,0.2)]">
        <span className="absolute left-5 text-primary/60 transition-opacity duration-200 flex items-center group-hover:opacity-80 group-focus-within:opacity-100">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M9 17A8 8 0 1 0 9 1a8 8 0 0 0 0 16zm0 0v3M15 15l3 3"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </span>
        <div className="absolute left-[3.25rem] right-5 flex items-center whitespace-nowrap overflow-hidden pointer-events-none text-left h-full">
          <span className="text-text-primary text-lg text-left">{text}</span>
          <span className="inline-block text-primary ml-[1px] font-extralight animate-blink">|</span>
        </div>
        <input
          type="text"
          placeholder="Ask anything about biological data..."
          readOnly
          className="w-full py-5 pl-[3.25rem] pr-5 border-none rounded-xl text-lg bg-transparent text-transparent caret-transparent text-left placeholder:text-transparent"
        />
      </div>
    </div>
  );
}
