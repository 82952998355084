import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useConfirmEmail } from "../api/user/createUser";

export default function ConfirmEmail() {
  const action = useConfirmEmail();
  const formSchema = z.object({
    code: z.string(),
  });
  const form = useForm({
    defaultValues: {
      code: "",
    },
    resolver: zodResolver(formSchema),
  });
  function onSubmit(data) {
    action.mutate(data);
  }
  return (
    <div className="h-[100vh] grid justify-center content-center">
      <Card className="w-full max-w-[350px]">
        <CardHeader>
          <CardTitle>Confirm email</CardTitle>
          <CardDescription>
            please, enter code received in email
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem className="w-full mb-5">
                    <FormLabel>Code</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-end">
                <Button type="submit">Submit</Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}
