import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { DatasetCard, DatasetDetails } from "./search-results/DatasetCards";

export default function SearchResults({
  relevantDatasets,
  onCommentClick,
  onRankingClick,
}) {
  return (
    <div className="space-y-5">
      {relevantDatasets?.map((item, i) => (
        <Sheet key={i}>
          <SheetTrigger className="w-full">
            <DatasetCard dataset={item} />
          </SheetTrigger>
          <SheetContent className="sm:max-w-[480px]">
            <SheetHeader>
              <SheetTitle>Datasets details</SheetTitle>
            </SheetHeader>
            <DatasetDetails
              dataset={item}
              onCommentClick={() => onCommentClick(item)}
              onRankingClick={() => onRankingClick(item)}
            />
          </SheetContent>
        </Sheet>
      ))}
    </div>
  );
}
