import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "@/api/apiClient";
import { userQueryKeys } from "./userQueryKeys";


const registerUser = async (newUser) => {
  const response = await apiClient.post("/auth/register", newUser);
  return response.data;
};

export function useRegisterUser() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: registerUser,
    onSuccess: (data) => {
      toast.success("User registered successfully. Now login");
      navigate("/login");
    }
  })
}

const confirmEmailFn = async (data) => {
  const response = await apiClient.post("/auth/confirm-email", data);
  return response.data;
};

export function useConfirmEmail() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: confirmEmailFn,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: userQueryKeys.all });
    },
    onSuccess: (data) => {
      navigate("/");
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(userQueryKeys.all, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.all });
    },
  });
}
